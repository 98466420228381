.wpcf7 {
	form {
		background-color: rgba($light-gray, 0.2);
		padding: 2rem;
		border-radius: 5px;
	}

	[type=color], [type=date], [type=datetime-local], [type=datetime], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], textarea {
		border: 1px solid $light-gray;
		box-shadow: none;
		border-radius: 5px;
	}

	textarea {
		resize: none;
		height: 150px;
	}

	p {
		margin-bottom: 0;
	}

	.design-button {
		width: 100%;
		padding: 0.75rem 1.5rem;
	}

	.wpcf7-submit {
		margin-bottom: 0;
	}

	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: $black;
	}
	::-moz-placeholder { /* Firefox 19+ */
		color: $black;
	}
	:-ms-input-placeholder { /* IE 10+ */
		color: $black;
	}
	:-moz-placeholder { /* Firefox 18- */
		color: $black;
	}
}

.showrooms {
	.showroom {
		iframe {
			height: 400px;
		}
	}
}