footer.footer {
	margin-top: 0;
	
	.upper-footer {
		background-color: $black;
		padding-top: 3rem;
		padding-bottom: 3rem;

		h3 {
			color: $white;
			border-bottom: 1px solid rgba($light-gray, 0.5);
			display: inline-block;
			margin: 0 auto 4rem;
			padding-bottom: 1rem;
		}

		h5, a, p {
			color: $light-gray;
		}

		p, a {
			font-weight: 300;
		}

		a {
			strong {
				text-transform: uppercase;
				margin-right: 0.25rem;
			}
		}

		.about-coffee-1652, .contact-coffee-1652 {
			p {
				&:first-of-type {
					padding-top: .4rem;
				}
			}
		}

		.menu {
			li {
				&.active {
					a {
						background-color: transparent;
						font-weight: 500;
					}
				}
			}
		}

		.social-menu {
			padding-left: 0.5rem;
			padding-right: 0.5rem;

			li {
				a {
					color: $white;
					padding: .7rem 0.5rem;
				}
			}
		}
	}

	.lower-footer {
		padding-top: .7rem;

		.menu {
			li {
				a {
					color: $dark-gray;
					font-weight: 500;
					font-size: 15px;
				}

				&.active {
					a {
						background-color: transparent;
						color: $dark-gray;
						font-weight: bold;
					}
				}
			}
		}

		.copyright {
			padding-top: .4rem;
			color: $dark-gray;
			font-weight: 500;
			font-size: 15px;
		}
	}
}