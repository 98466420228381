.content {
	margin-top: -70px;
	z-index: 0;
	position: relative;

	.article-header {
		background-color: $light-gray;
		padding-top: calc(6rem + 70px);
		padding-bottom: 6rem;	
	}
}

.narrow-row {
	max-width: 87.5rem;
}

.inner-page {
	.article-header {
		background-image: url(../images/about/about-bg.png);

		h1 {
			color: white;
		}
	}
}

.type-page {
	.article-header {
		background-size: cover;
		background-repeat: no-repeat;
		
		h1, a, .breadcrumbs {
			color: white;
		}
	}
}

.content-section {
	padding-top: 3rem;
	padding-bottom: 3rem;
}