@include breakpoint(small only) {
	#homepage-slider {
		max-height: 400px;
		
		.slick-list {
			max-height: 400px;
		}

		.slick-list {
			min-height: 400px;
			height: 100%;
			
			.slick-track {
				min-height: 400px;
				height: 100%;
				
				.slick-slide {
					min-height: 400px;
					height: 100%;

					> div {
						min-height: 400px;
						height: 100%;
						
						.slick-slide-content-container {
							min-height: 400px;
							height: 100%;
						}
					}
				}
			}
		}
	}

	.ubermenu.ubermenu-responsive {
		.ubermenu-column, .ubermenu-column-auto {
			width: 100%;
		}
	}

	.featured-brand>a span:after {
		display: none;
	}

	.ubermenu {
		.ubermenu-search {
			.ubermenu-searchform {
				margin: 0;
				width: 100%;
			}
		}
	}

	.ubermenu-responsive {
		.ubermenu-nav {
			.ubermenu-item {
				.ubermenu-submenu.ubermenu-submenu-drop {
					background-color: white;

					li {
						a {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	
	.ubermenu .ubermenu-autocolumn, .ubermenu .ubermenu-submenu-type-stack {
		margin-top: 0;
		margin-bottom: 0;
	}

	.ubermenu .ubermenu-item-type-column>.ubermenu-submenu-type-stack>.ubermenu-item-normal:first-child {
		margin-top: 0;
	}
}