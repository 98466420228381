header.header {
	position: relative;
	z-index: 10;
}

.top-bar {
	.top-bar-left {
		ul {
			background-color: transparent;
		}
	}

	.top-bar-right {
		width: 100%;
		
		.ubermenu {
			.ubermenu-nav {
				> li {

					.ubermenu-item-header {
						> a {
    						font-weight: 500;
    						font-size: 17px;
    					}
					}
					
					.ubermenu-submenu {	
					 	> li {
							&.ubermenu-has-submenu-stack {
								> a {
									font-weight: 500;
								}
							}
						}
					}

					&.shopping-bag {
						background-color: $brown;
					}

					> a {
						height: 70px;
					    line-height: 40px;
					    color: white;
					}
				}
			}

			.ubermenu-submenu {
				background-color: white;
				
				a {
					color: $black;
				}
			}
		}
	}

	.ubermenu-submenu-type-stack {
		li {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.menu {
		li {
			a {
				&.logo {
					padding: 0 1rem;
					// background-color: white;
				}
			}
		}
	}
}

.contact {
	> a {
		position: relative;

		&:after {
			content: "";
			top: 10px;
			bottom: 10px;
			border-left: 1px solid rgba($brown, 0.5);
			position: absolute;
			right: 0;
		}
	}
}

.featured-brand {
	> a {
		span {
			position: relative;

			&:after {
				content: "";
				border-bottom: 1px solid white;
				width: 100%;
				position: absolute;
				bottom: 7px;
				left: 0;
			}
		}
	}
}

.searchbar {
	margin: 0;
	position: relative;

	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: $black;
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: $black;
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: $black;
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: $black;
	}
}


.input-group-button {
	float: right;

	.search-button {
		height: 70px;
		width: 70px;
		text-align: center;
	}
}

.menu-search {
	.input-group {
		margin-bottom: 0;

		.button {
			height: 70px;
			line-height: 40px;
		}

		.search-field {
			width: 0;
			height: 70px;
			padding: 0;
			border: none;
			transition: all 0.5s ease;
			position: absolute;
			margin-bottom: 0;
			border: 0;
			box-shadow: none;
			color: black;
			right: 60.66px;
			margin-right: 0 !important;
			outline: none;

			&.expand-search {
				width: 80%;
				max-width: calc(80% - 3rem);
				padding: .5rem;
				max-width: 250px;
				min-width: 200px;
				z-index: 100;
				padding: 0 1em;

				&:focus {
					border: 0;
					box-shadow: none;
				}
			}
		}
	}
}

.position-right {
	.ubermenu-responsive-default.ubermenu-responsive .ubermenu-nav .ubermenu-item .ubermenu-submenu.ubermenu-submenu-drop {
		background-color: white;
	}
}

.ubermenu .ubermenu-item-type-column>.ubermenu-submenu-type-stack>.ubermenu-item-normal:first-child {
	margin-top: 0;
}

.ubermenu .ubermenu-submenu-type-stack>.ubermenu-item-normal>.ubermenu-target {
	font-size: 15px;
}

.ubermenu .ubermenu-autocolumn, .ubermenu .ubermenu-submenu-type-stack {
	padding-top: 0;
}

.ubermenu-row-id-25 {
    max-width: 80% !important;
    margin: 0 auto !important;
    padding-top: 2rem !important;

    > li {
    	&:not(:first-of-type) {
			border-left: 1px solid $light-gray;
    	}
    }
}

.ubermenu-sub-indicators .ubermenu-has-submenu-drop>.ubermenu-target:after, .ubermenu .ubermenu-search .ubermenu-search-submit, .ubermenu .ubermenu-searchbar-drop .ubermenu-search-submit {
	font-family: $fontawesome !important;
}