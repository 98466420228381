.search-list {
	padding-top: 3rem;
	margin-bottom: 2rem;

	.search-item {
		position: relative;
		margin-bottom: 2rem;

		h2 {
		    font-size: 1.25rem;
		    line-height: 1.4;
		    margin-top: 0;
		    margin-bottom: .5rem;
		}

		.image-link {
			width: 100%;
			display: block;
			background-color: $light-gray;
			border: none;
			padding: 2rem;
		}
	}

	.search-item-container {
		display: table;
		width: 100%;

		.search-image {
			display: table-cell;
			vertical-align: middle;

			img {
				display: inline-block;
			}
		}
	}
}