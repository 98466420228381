@charset "UTF-8";
/******************************************************************
	
Stylesheet: Main Stylesheet

Here's where the magic happens. Here is where you import
all of your Sass files so they can compile into one
CSS file. 

******************************************************************/
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #110c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 105rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media screen and (min-width: 75em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media screen and (min-width: 90em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 4.16667%; }

.small-push-1 {
  position: relative;
  left: 4.16667%; }

.small-pull-1 {
  position: relative;
  left: -4.16667%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 8.33333%; }

.small-push-2 {
  position: relative;
  left: 8.33333%; }

.small-pull-2 {
  position: relative;
  left: -8.33333%; }

.small-offset-1 {
  margin-left: 4.16667%; }

.small-3 {
  width: 12.5%; }

.small-push-3 {
  position: relative;
  left: 12.5%; }

.small-pull-3 {
  position: relative;
  left: -12.5%; }

.small-offset-2 {
  margin-left: 8.33333%; }

.small-4 {
  width: 16.66667%; }

.small-push-4 {
  position: relative;
  left: 16.66667%; }

.small-pull-4 {
  position: relative;
  left: -16.66667%; }

.small-offset-3 {
  margin-left: 12.5%; }

.small-5 {
  width: 20.83333%; }

.small-push-5 {
  position: relative;
  left: 20.83333%; }

.small-pull-5 {
  position: relative;
  left: -20.83333%; }

.small-offset-4 {
  margin-left: 16.66667%; }

.small-6 {
  width: 25%; }

.small-push-6 {
  position: relative;
  left: 25%; }

.small-pull-6 {
  position: relative;
  left: -25%; }

.small-offset-5 {
  margin-left: 20.83333%; }

.small-7 {
  width: 29.16667%; }

.small-push-7 {
  position: relative;
  left: 29.16667%; }

.small-pull-7 {
  position: relative;
  left: -29.16667%; }

.small-offset-6 {
  margin-left: 25%; }

.small-8 {
  width: 33.33333%; }

.small-push-8 {
  position: relative;
  left: 33.33333%; }

.small-pull-8 {
  position: relative;
  left: -33.33333%; }

.small-offset-7 {
  margin-left: 29.16667%; }

.small-9 {
  width: 37.5%; }

.small-push-9 {
  position: relative;
  left: 37.5%; }

.small-pull-9 {
  position: relative;
  left: -37.5%; }

.small-offset-8 {
  margin-left: 33.33333%; }

.small-10 {
  width: 41.66667%; }

.small-push-10 {
  position: relative;
  left: 41.66667%; }

.small-pull-10 {
  position: relative;
  left: -41.66667%; }

.small-offset-9 {
  margin-left: 37.5%; }

.small-11 {
  width: 45.83333%; }

.small-push-11 {
  position: relative;
  left: 45.83333%; }

.small-pull-11 {
  position: relative;
  left: -45.83333%; }

.small-offset-10 {
  margin-left: 41.66667%; }

.small-12 {
  width: 50%; }

.small-push-12 {
  position: relative;
  left: 50%; }

.small-pull-12 {
  position: relative;
  left: -50%; }

.small-offset-11 {
  margin-left: 45.83333%; }

.small-13 {
  width: 54.16667%; }

.small-push-13 {
  position: relative;
  left: 54.16667%; }

.small-pull-13 {
  position: relative;
  left: -54.16667%; }

.small-offset-12 {
  margin-left: 50%; }

.small-14 {
  width: 58.33333%; }

.small-push-14 {
  position: relative;
  left: 58.33333%; }

.small-pull-14 {
  position: relative;
  left: -58.33333%; }

.small-offset-13 {
  margin-left: 54.16667%; }

.small-15 {
  width: 62.5%; }

.small-push-15 {
  position: relative;
  left: 62.5%; }

.small-pull-15 {
  position: relative;
  left: -62.5%; }

.small-offset-14 {
  margin-left: 58.33333%; }

.small-16 {
  width: 66.66667%; }

.small-push-16 {
  position: relative;
  left: 66.66667%; }

.small-pull-16 {
  position: relative;
  left: -66.66667%; }

.small-offset-15 {
  margin-left: 62.5%; }

.small-17 {
  width: 70.83333%; }

.small-push-17 {
  position: relative;
  left: 70.83333%; }

.small-pull-17 {
  position: relative;
  left: -70.83333%; }

.small-offset-16 {
  margin-left: 66.66667%; }

.small-18 {
  width: 75%; }

.small-push-18 {
  position: relative;
  left: 75%; }

.small-pull-18 {
  position: relative;
  left: -75%; }

.small-offset-17 {
  margin-left: 70.83333%; }

.small-19 {
  width: 79.16667%; }

.small-push-19 {
  position: relative;
  left: 79.16667%; }

.small-pull-19 {
  position: relative;
  left: -79.16667%; }

.small-offset-18 {
  margin-left: 75%; }

.small-20 {
  width: 83.33333%; }

.small-push-20 {
  position: relative;
  left: 83.33333%; }

.small-pull-20 {
  position: relative;
  left: -83.33333%; }

.small-offset-19 {
  margin-left: 79.16667%; }

.small-21 {
  width: 87.5%; }

.small-push-21 {
  position: relative;
  left: 87.5%; }

.small-pull-21 {
  position: relative;
  left: -87.5%; }

.small-offset-20 {
  margin-left: 83.33333%; }

.small-22 {
  width: 91.66667%; }

.small-push-22 {
  position: relative;
  left: 91.66667%; }

.small-pull-22 {
  position: relative;
  left: -91.66667%; }

.small-offset-21 {
  margin-left: 87.5%; }

.small-23 {
  width: 95.83333%; }

.small-push-23 {
  position: relative;
  left: 95.83333%; }

.small-pull-23 {
  position: relative;
  left: -95.83333%; }

.small-offset-22 {
  margin-left: 91.66667%; }

.small-24 {
  width: 100%; }

.small-offset-23 {
  margin-left: 95.83333%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 4.16667%; }
  .medium-push-1 {
    position: relative;
    left: 4.16667%; }
  .medium-pull-1 {
    position: relative;
    left: -4.16667%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 8.33333%; }
  .medium-push-2 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-2 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-1 {
    margin-left: 4.16667%; }
  .medium-3 {
    width: 12.5%; }
  .medium-push-3 {
    position: relative;
    left: 12.5%; }
  .medium-pull-3 {
    position: relative;
    left: -12.5%; }
  .medium-offset-2 {
    margin-left: 8.33333%; }
  .medium-4 {
    width: 16.66667%; }
  .medium-push-4 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-4 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-3 {
    margin-left: 12.5%; }
  .medium-5 {
    width: 20.83333%; }
  .medium-push-5 {
    position: relative;
    left: 20.83333%; }
  .medium-pull-5 {
    position: relative;
    left: -20.83333%; }
  .medium-offset-4 {
    margin-left: 16.66667%; }
  .medium-6 {
    width: 25%; }
  .medium-push-6 {
    position: relative;
    left: 25%; }
  .medium-pull-6 {
    position: relative;
    left: -25%; }
  .medium-offset-5 {
    margin-left: 20.83333%; }
  .medium-7 {
    width: 29.16667%; }
  .medium-push-7 {
    position: relative;
    left: 29.16667%; }
  .medium-pull-7 {
    position: relative;
    left: -29.16667%; }
  .medium-offset-6 {
    margin-left: 25%; }
  .medium-8 {
    width: 33.33333%; }
  .medium-push-8 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-8 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-7 {
    margin-left: 29.16667%; }
  .medium-9 {
    width: 37.5%; }
  .medium-push-9 {
    position: relative;
    left: 37.5%; }
  .medium-pull-9 {
    position: relative;
    left: -37.5%; }
  .medium-offset-8 {
    margin-left: 33.33333%; }
  .medium-10 {
    width: 41.66667%; }
  .medium-push-10 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-10 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-9 {
    margin-left: 37.5%; }
  .medium-11 {
    width: 45.83333%; }
  .medium-push-11 {
    position: relative;
    left: 45.83333%; }
  .medium-pull-11 {
    position: relative;
    left: -45.83333%; }
  .medium-offset-10 {
    margin-left: 41.66667%; }
  .medium-12 {
    width: 50%; }
  .medium-push-12 {
    position: relative;
    left: 50%; }
  .medium-pull-12 {
    position: relative;
    left: -50%; }
  .medium-offset-11 {
    margin-left: 45.83333%; }
  .medium-13 {
    width: 54.16667%; }
  .medium-push-13 {
    position: relative;
    left: 54.16667%; }
  .medium-pull-13 {
    position: relative;
    left: -54.16667%; }
  .medium-offset-12 {
    margin-left: 50%; }
  .medium-14 {
    width: 58.33333%; }
  .medium-push-14 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-14 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-13 {
    margin-left: 54.16667%; }
  .medium-15 {
    width: 62.5%; }
  .medium-push-15 {
    position: relative;
    left: 62.5%; }
  .medium-pull-15 {
    position: relative;
    left: -62.5%; }
  .medium-offset-14 {
    margin-left: 58.33333%; }
  .medium-16 {
    width: 66.66667%; }
  .medium-push-16 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-16 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-15 {
    margin-left: 62.5%; }
  .medium-17 {
    width: 70.83333%; }
  .medium-push-17 {
    position: relative;
    left: 70.83333%; }
  .medium-pull-17 {
    position: relative;
    left: -70.83333%; }
  .medium-offset-16 {
    margin-left: 66.66667%; }
  .medium-18 {
    width: 75%; }
  .medium-push-18 {
    position: relative;
    left: 75%; }
  .medium-pull-18 {
    position: relative;
    left: -75%; }
  .medium-offset-17 {
    margin-left: 70.83333%; }
  .medium-19 {
    width: 79.16667%; }
  .medium-push-19 {
    position: relative;
    left: 79.16667%; }
  .medium-pull-19 {
    position: relative;
    left: -79.16667%; }
  .medium-offset-18 {
    margin-left: 75%; }
  .medium-20 {
    width: 83.33333%; }
  .medium-push-20 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-20 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-19 {
    margin-left: 79.16667%; }
  .medium-21 {
    width: 87.5%; }
  .medium-push-21 {
    position: relative;
    left: 87.5%; }
  .medium-pull-21 {
    position: relative;
    left: -87.5%; }
  .medium-offset-20 {
    margin-left: 83.33333%; }
  .medium-22 {
    width: 91.66667%; }
  .medium-push-22 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-22 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-21 {
    margin-left: 87.5%; }
  .medium-23 {
    width: 95.83333%; }
  .medium-push-23 {
    position: relative;
    left: 95.83333%; }
  .medium-pull-23 {
    position: relative;
    left: -95.83333%; }
  .medium-offset-22 {
    margin-left: 91.66667%; }
  .medium-24 {
    width: 100%; }
  .medium-offset-23 {
    margin-left: 95.83333%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 4.16667%; }
  .large-push-1 {
    position: relative;
    left: 4.16667%; }
  .large-pull-1 {
    position: relative;
    left: -4.16667%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 8.33333%; }
  .large-push-2 {
    position: relative;
    left: 8.33333%; }
  .large-pull-2 {
    position: relative;
    left: -8.33333%; }
  .large-offset-1 {
    margin-left: 4.16667%; }
  .large-3 {
    width: 12.5%; }
  .large-push-3 {
    position: relative;
    left: 12.5%; }
  .large-pull-3 {
    position: relative;
    left: -12.5%; }
  .large-offset-2 {
    margin-left: 8.33333%; }
  .large-4 {
    width: 16.66667%; }
  .large-push-4 {
    position: relative;
    left: 16.66667%; }
  .large-pull-4 {
    position: relative;
    left: -16.66667%; }
  .large-offset-3 {
    margin-left: 12.5%; }
  .large-5 {
    width: 20.83333%; }
  .large-push-5 {
    position: relative;
    left: 20.83333%; }
  .large-pull-5 {
    position: relative;
    left: -20.83333%; }
  .large-offset-4 {
    margin-left: 16.66667%; }
  .large-6 {
    width: 25%; }
  .large-push-6 {
    position: relative;
    left: 25%; }
  .large-pull-6 {
    position: relative;
    left: -25%; }
  .large-offset-5 {
    margin-left: 20.83333%; }
  .large-7 {
    width: 29.16667%; }
  .large-push-7 {
    position: relative;
    left: 29.16667%; }
  .large-pull-7 {
    position: relative;
    left: -29.16667%; }
  .large-offset-6 {
    margin-left: 25%; }
  .large-8 {
    width: 33.33333%; }
  .large-push-8 {
    position: relative;
    left: 33.33333%; }
  .large-pull-8 {
    position: relative;
    left: -33.33333%; }
  .large-offset-7 {
    margin-left: 29.16667%; }
  .large-9 {
    width: 37.5%; }
  .large-push-9 {
    position: relative;
    left: 37.5%; }
  .large-pull-9 {
    position: relative;
    left: -37.5%; }
  .large-offset-8 {
    margin-left: 33.33333%; }
  .large-10 {
    width: 41.66667%; }
  .large-push-10 {
    position: relative;
    left: 41.66667%; }
  .large-pull-10 {
    position: relative;
    left: -41.66667%; }
  .large-offset-9 {
    margin-left: 37.5%; }
  .large-11 {
    width: 45.83333%; }
  .large-push-11 {
    position: relative;
    left: 45.83333%; }
  .large-pull-11 {
    position: relative;
    left: -45.83333%; }
  .large-offset-10 {
    margin-left: 41.66667%; }
  .large-12 {
    width: 50%; }
  .large-push-12 {
    position: relative;
    left: 50%; }
  .large-pull-12 {
    position: relative;
    left: -50%; }
  .large-offset-11 {
    margin-left: 45.83333%; }
  .large-13 {
    width: 54.16667%; }
  .large-push-13 {
    position: relative;
    left: 54.16667%; }
  .large-pull-13 {
    position: relative;
    left: -54.16667%; }
  .large-offset-12 {
    margin-left: 50%; }
  .large-14 {
    width: 58.33333%; }
  .large-push-14 {
    position: relative;
    left: 58.33333%; }
  .large-pull-14 {
    position: relative;
    left: -58.33333%; }
  .large-offset-13 {
    margin-left: 54.16667%; }
  .large-15 {
    width: 62.5%; }
  .large-push-15 {
    position: relative;
    left: 62.5%; }
  .large-pull-15 {
    position: relative;
    left: -62.5%; }
  .large-offset-14 {
    margin-left: 58.33333%; }
  .large-16 {
    width: 66.66667%; }
  .large-push-16 {
    position: relative;
    left: 66.66667%; }
  .large-pull-16 {
    position: relative;
    left: -66.66667%; }
  .large-offset-15 {
    margin-left: 62.5%; }
  .large-17 {
    width: 70.83333%; }
  .large-push-17 {
    position: relative;
    left: 70.83333%; }
  .large-pull-17 {
    position: relative;
    left: -70.83333%; }
  .large-offset-16 {
    margin-left: 66.66667%; }
  .large-18 {
    width: 75%; }
  .large-push-18 {
    position: relative;
    left: 75%; }
  .large-pull-18 {
    position: relative;
    left: -75%; }
  .large-offset-17 {
    margin-left: 70.83333%; }
  .large-19 {
    width: 79.16667%; }
  .large-push-19 {
    position: relative;
    left: 79.16667%; }
  .large-pull-19 {
    position: relative;
    left: -79.16667%; }
  .large-offset-18 {
    margin-left: 75%; }
  .large-20 {
    width: 83.33333%; }
  .large-push-20 {
    position: relative;
    left: 83.33333%; }
  .large-pull-20 {
    position: relative;
    left: -83.33333%; }
  .large-offset-19 {
    margin-left: 79.16667%; }
  .large-21 {
    width: 87.5%; }
  .large-push-21 {
    position: relative;
    left: 87.5%; }
  .large-pull-21 {
    position: relative;
    left: -87.5%; }
  .large-offset-20 {
    margin-left: 83.33333%; }
  .large-22 {
    width: 91.66667%; }
  .large-push-22 {
    position: relative;
    left: 91.66667%; }
  .large-pull-22 {
    position: relative;
    left: -91.66667%; }
  .large-offset-21 {
    margin-left: 87.5%; }
  .large-23 {
    width: 95.83333%; }
  .large-push-23 {
    position: relative;
    left: 95.83333%; }
  .large-pull-23 {
    position: relative;
    left: -95.83333%; }
  .large-offset-22 {
    margin-left: 91.66667%; }
  .large-24 {
    width: 100%; }
  .large-offset-23 {
    margin-left: 95.83333%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 75em) {
  .xlarge-1 {
    width: 4.16667%; }
  .xlarge-push-1 {
    position: relative;
    left: 4.16667%; }
  .xlarge-pull-1 {
    position: relative;
    left: -4.16667%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    width: 8.33333%; }
  .xlarge-push-2 {
    position: relative;
    left: 8.33333%; }
  .xlarge-pull-2 {
    position: relative;
    left: -8.33333%; }
  .xlarge-offset-1 {
    margin-left: 4.16667%; }
  .xlarge-3 {
    width: 12.5%; }
  .xlarge-push-3 {
    position: relative;
    left: 12.5%; }
  .xlarge-pull-3 {
    position: relative;
    left: -12.5%; }
  .xlarge-offset-2 {
    margin-left: 8.33333%; }
  .xlarge-4 {
    width: 16.66667%; }
  .xlarge-push-4 {
    position: relative;
    left: 16.66667%; }
  .xlarge-pull-4 {
    position: relative;
    left: -16.66667%; }
  .xlarge-offset-3 {
    margin-left: 12.5%; }
  .xlarge-5 {
    width: 20.83333%; }
  .xlarge-push-5 {
    position: relative;
    left: 20.83333%; }
  .xlarge-pull-5 {
    position: relative;
    left: -20.83333%; }
  .xlarge-offset-4 {
    margin-left: 16.66667%; }
  .xlarge-6 {
    width: 25%; }
  .xlarge-push-6 {
    position: relative;
    left: 25%; }
  .xlarge-pull-6 {
    position: relative;
    left: -25%; }
  .xlarge-offset-5 {
    margin-left: 20.83333%; }
  .xlarge-7 {
    width: 29.16667%; }
  .xlarge-push-7 {
    position: relative;
    left: 29.16667%; }
  .xlarge-pull-7 {
    position: relative;
    left: -29.16667%; }
  .xlarge-offset-6 {
    margin-left: 25%; }
  .xlarge-8 {
    width: 33.33333%; }
  .xlarge-push-8 {
    position: relative;
    left: 33.33333%; }
  .xlarge-pull-8 {
    position: relative;
    left: -33.33333%; }
  .xlarge-offset-7 {
    margin-left: 29.16667%; }
  .xlarge-9 {
    width: 37.5%; }
  .xlarge-push-9 {
    position: relative;
    left: 37.5%; }
  .xlarge-pull-9 {
    position: relative;
    left: -37.5%; }
  .xlarge-offset-8 {
    margin-left: 33.33333%; }
  .xlarge-10 {
    width: 41.66667%; }
  .xlarge-push-10 {
    position: relative;
    left: 41.66667%; }
  .xlarge-pull-10 {
    position: relative;
    left: -41.66667%; }
  .xlarge-offset-9 {
    margin-left: 37.5%; }
  .xlarge-11 {
    width: 45.83333%; }
  .xlarge-push-11 {
    position: relative;
    left: 45.83333%; }
  .xlarge-pull-11 {
    position: relative;
    left: -45.83333%; }
  .xlarge-offset-10 {
    margin-left: 41.66667%; }
  .xlarge-12 {
    width: 50%; }
  .xlarge-push-12 {
    position: relative;
    left: 50%; }
  .xlarge-pull-12 {
    position: relative;
    left: -50%; }
  .xlarge-offset-11 {
    margin-left: 45.83333%; }
  .xlarge-13 {
    width: 54.16667%; }
  .xlarge-push-13 {
    position: relative;
    left: 54.16667%; }
  .xlarge-pull-13 {
    position: relative;
    left: -54.16667%; }
  .xlarge-offset-12 {
    margin-left: 50%; }
  .xlarge-14 {
    width: 58.33333%; }
  .xlarge-push-14 {
    position: relative;
    left: 58.33333%; }
  .xlarge-pull-14 {
    position: relative;
    left: -58.33333%; }
  .xlarge-offset-13 {
    margin-left: 54.16667%; }
  .xlarge-15 {
    width: 62.5%; }
  .xlarge-push-15 {
    position: relative;
    left: 62.5%; }
  .xlarge-pull-15 {
    position: relative;
    left: -62.5%; }
  .xlarge-offset-14 {
    margin-left: 58.33333%; }
  .xlarge-16 {
    width: 66.66667%; }
  .xlarge-push-16 {
    position: relative;
    left: 66.66667%; }
  .xlarge-pull-16 {
    position: relative;
    left: -66.66667%; }
  .xlarge-offset-15 {
    margin-left: 62.5%; }
  .xlarge-17 {
    width: 70.83333%; }
  .xlarge-push-17 {
    position: relative;
    left: 70.83333%; }
  .xlarge-pull-17 {
    position: relative;
    left: -70.83333%; }
  .xlarge-offset-16 {
    margin-left: 66.66667%; }
  .xlarge-18 {
    width: 75%; }
  .xlarge-push-18 {
    position: relative;
    left: 75%; }
  .xlarge-pull-18 {
    position: relative;
    left: -75%; }
  .xlarge-offset-17 {
    margin-left: 70.83333%; }
  .xlarge-19 {
    width: 79.16667%; }
  .xlarge-push-19 {
    position: relative;
    left: 79.16667%; }
  .xlarge-pull-19 {
    position: relative;
    left: -79.16667%; }
  .xlarge-offset-18 {
    margin-left: 75%; }
  .xlarge-20 {
    width: 83.33333%; }
  .xlarge-push-20 {
    position: relative;
    left: 83.33333%; }
  .xlarge-pull-20 {
    position: relative;
    left: -83.33333%; }
  .xlarge-offset-19 {
    margin-left: 79.16667%; }
  .xlarge-21 {
    width: 87.5%; }
  .xlarge-push-21 {
    position: relative;
    left: 87.5%; }
  .xlarge-pull-21 {
    position: relative;
    left: -87.5%; }
  .xlarge-offset-20 {
    margin-left: 83.33333%; }
  .xlarge-22 {
    width: 91.66667%; }
  .xlarge-push-22 {
    position: relative;
    left: 91.66667%; }
  .xlarge-pull-22 {
    position: relative;
    left: -91.66667%; }
  .xlarge-offset-21 {
    margin-left: 87.5%; }
  .xlarge-23 {
    width: 95.83333%; }
  .xlarge-push-23 {
    position: relative;
    left: 95.83333%; }
  .xlarge-pull-23 {
    position: relative;
    left: -95.83333%; }
  .xlarge-offset-22 {
    margin-left: 91.66667%; }
  .xlarge-24 {
    width: 100%; }
  .xlarge-offset-23 {
    margin-left: 95.83333%; }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xlarge-up-1 > .column:nth-of-type(1n), .xlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-1 > .column:nth-of-type(1n+1), .xlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xlarge-up-1 > .column:last-child, .xlarge-up-1 > .columns:last-child {
      float: left; }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xlarge-up-2 > .column:nth-of-type(1n), .xlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-2 > .column:nth-of-type(2n+1), .xlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xlarge-up-2 > .column:last-child, .xlarge-up-2 > .columns:last-child {
      float: left; }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xlarge-up-3 > .column:nth-of-type(1n), .xlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-3 > .column:nth-of-type(3n+1), .xlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xlarge-up-3 > .column:last-child, .xlarge-up-3 > .columns:last-child {
      float: left; }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xlarge-up-4 > .column:nth-of-type(1n), .xlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-4 > .column:nth-of-type(4n+1), .xlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xlarge-up-4 > .column:last-child, .xlarge-up-4 > .columns:last-child {
      float: left; }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xlarge-up-5 > .column:nth-of-type(1n), .xlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-5 > .column:nth-of-type(5n+1), .xlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xlarge-up-5 > .column:last-child, .xlarge-up-5 > .columns:last-child {
      float: left; }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xlarge-up-6 > .column:nth-of-type(1n), .xlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-6 > .column:nth-of-type(6n+1), .xlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xlarge-up-6 > .column:last-child, .xlarge-up-6 > .columns:last-child {
      float: left; }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xlarge-up-7 > .column:nth-of-type(1n), .xlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-7 > .column:nth-of-type(7n+1), .xlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xlarge-up-7 > .column:last-child, .xlarge-up-7 > .columns:last-child {
      float: left; }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xlarge-up-8 > .column:nth-of-type(1n), .xlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-8 > .column:nth-of-type(8n+1), .xlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xlarge-up-8 > .column:last-child, .xlarge-up-8 > .columns:last-child {
      float: left; }
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .xlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xlarge-centered, .xlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xlarge-uncentered,
  .xlarge-push-0,
  .xlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 90em) {
  .xxlarge-1 {
    width: 4.16667%; }
  .xxlarge-push-1 {
    position: relative;
    left: 4.16667%; }
  .xxlarge-pull-1 {
    position: relative;
    left: -4.16667%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    width: 8.33333%; }
  .xxlarge-push-2 {
    position: relative;
    left: 8.33333%; }
  .xxlarge-pull-2 {
    position: relative;
    left: -8.33333%; }
  .xxlarge-offset-1 {
    margin-left: 4.16667%; }
  .xxlarge-3 {
    width: 12.5%; }
  .xxlarge-push-3 {
    position: relative;
    left: 12.5%; }
  .xxlarge-pull-3 {
    position: relative;
    left: -12.5%; }
  .xxlarge-offset-2 {
    margin-left: 8.33333%; }
  .xxlarge-4 {
    width: 16.66667%; }
  .xxlarge-push-4 {
    position: relative;
    left: 16.66667%; }
  .xxlarge-pull-4 {
    position: relative;
    left: -16.66667%; }
  .xxlarge-offset-3 {
    margin-left: 12.5%; }
  .xxlarge-5 {
    width: 20.83333%; }
  .xxlarge-push-5 {
    position: relative;
    left: 20.83333%; }
  .xxlarge-pull-5 {
    position: relative;
    left: -20.83333%; }
  .xxlarge-offset-4 {
    margin-left: 16.66667%; }
  .xxlarge-6 {
    width: 25%; }
  .xxlarge-push-6 {
    position: relative;
    left: 25%; }
  .xxlarge-pull-6 {
    position: relative;
    left: -25%; }
  .xxlarge-offset-5 {
    margin-left: 20.83333%; }
  .xxlarge-7 {
    width: 29.16667%; }
  .xxlarge-push-7 {
    position: relative;
    left: 29.16667%; }
  .xxlarge-pull-7 {
    position: relative;
    left: -29.16667%; }
  .xxlarge-offset-6 {
    margin-left: 25%; }
  .xxlarge-8 {
    width: 33.33333%; }
  .xxlarge-push-8 {
    position: relative;
    left: 33.33333%; }
  .xxlarge-pull-8 {
    position: relative;
    left: -33.33333%; }
  .xxlarge-offset-7 {
    margin-left: 29.16667%; }
  .xxlarge-9 {
    width: 37.5%; }
  .xxlarge-push-9 {
    position: relative;
    left: 37.5%; }
  .xxlarge-pull-9 {
    position: relative;
    left: -37.5%; }
  .xxlarge-offset-8 {
    margin-left: 33.33333%; }
  .xxlarge-10 {
    width: 41.66667%; }
  .xxlarge-push-10 {
    position: relative;
    left: 41.66667%; }
  .xxlarge-pull-10 {
    position: relative;
    left: -41.66667%; }
  .xxlarge-offset-9 {
    margin-left: 37.5%; }
  .xxlarge-11 {
    width: 45.83333%; }
  .xxlarge-push-11 {
    position: relative;
    left: 45.83333%; }
  .xxlarge-pull-11 {
    position: relative;
    left: -45.83333%; }
  .xxlarge-offset-10 {
    margin-left: 41.66667%; }
  .xxlarge-12 {
    width: 50%; }
  .xxlarge-push-12 {
    position: relative;
    left: 50%; }
  .xxlarge-pull-12 {
    position: relative;
    left: -50%; }
  .xxlarge-offset-11 {
    margin-left: 45.83333%; }
  .xxlarge-13 {
    width: 54.16667%; }
  .xxlarge-push-13 {
    position: relative;
    left: 54.16667%; }
  .xxlarge-pull-13 {
    position: relative;
    left: -54.16667%; }
  .xxlarge-offset-12 {
    margin-left: 50%; }
  .xxlarge-14 {
    width: 58.33333%; }
  .xxlarge-push-14 {
    position: relative;
    left: 58.33333%; }
  .xxlarge-pull-14 {
    position: relative;
    left: -58.33333%; }
  .xxlarge-offset-13 {
    margin-left: 54.16667%; }
  .xxlarge-15 {
    width: 62.5%; }
  .xxlarge-push-15 {
    position: relative;
    left: 62.5%; }
  .xxlarge-pull-15 {
    position: relative;
    left: -62.5%; }
  .xxlarge-offset-14 {
    margin-left: 58.33333%; }
  .xxlarge-16 {
    width: 66.66667%; }
  .xxlarge-push-16 {
    position: relative;
    left: 66.66667%; }
  .xxlarge-pull-16 {
    position: relative;
    left: -66.66667%; }
  .xxlarge-offset-15 {
    margin-left: 62.5%; }
  .xxlarge-17 {
    width: 70.83333%; }
  .xxlarge-push-17 {
    position: relative;
    left: 70.83333%; }
  .xxlarge-pull-17 {
    position: relative;
    left: -70.83333%; }
  .xxlarge-offset-16 {
    margin-left: 66.66667%; }
  .xxlarge-18 {
    width: 75%; }
  .xxlarge-push-18 {
    position: relative;
    left: 75%; }
  .xxlarge-pull-18 {
    position: relative;
    left: -75%; }
  .xxlarge-offset-17 {
    margin-left: 70.83333%; }
  .xxlarge-19 {
    width: 79.16667%; }
  .xxlarge-push-19 {
    position: relative;
    left: 79.16667%; }
  .xxlarge-pull-19 {
    position: relative;
    left: -79.16667%; }
  .xxlarge-offset-18 {
    margin-left: 75%; }
  .xxlarge-20 {
    width: 83.33333%; }
  .xxlarge-push-20 {
    position: relative;
    left: 83.33333%; }
  .xxlarge-pull-20 {
    position: relative;
    left: -83.33333%; }
  .xxlarge-offset-19 {
    margin-left: 79.16667%; }
  .xxlarge-21 {
    width: 87.5%; }
  .xxlarge-push-21 {
    position: relative;
    left: 87.5%; }
  .xxlarge-pull-21 {
    position: relative;
    left: -87.5%; }
  .xxlarge-offset-20 {
    margin-left: 83.33333%; }
  .xxlarge-22 {
    width: 91.66667%; }
  .xxlarge-push-22 {
    position: relative;
    left: 91.66667%; }
  .xxlarge-pull-22 {
    position: relative;
    left: -91.66667%; }
  .xxlarge-offset-21 {
    margin-left: 87.5%; }
  .xxlarge-23 {
    width: 95.83333%; }
  .xxlarge-push-23 {
    position: relative;
    left: 95.83333%; }
  .xxlarge-pull-23 {
    position: relative;
    left: -95.83333%; }
  .xxlarge-offset-22 {
    margin-left: 91.66667%; }
  .xxlarge-24 {
    width: 100%; }
  .xxlarge-offset-23 {
    margin-left: 95.83333%; }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xxlarge-up-1 > .column:nth-of-type(1n), .xxlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-1 > .column:nth-of-type(1n+1), .xxlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xxlarge-up-1 > .column:last-child, .xxlarge-up-1 > .columns:last-child {
      float: left; }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xxlarge-up-2 > .column:nth-of-type(1n), .xxlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-2 > .column:nth-of-type(2n+1), .xxlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xxlarge-up-2 > .column:last-child, .xxlarge-up-2 > .columns:last-child {
      float: left; }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xxlarge-up-3 > .column:nth-of-type(1n), .xxlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-3 > .column:nth-of-type(3n+1), .xxlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xxlarge-up-3 > .column:last-child, .xxlarge-up-3 > .columns:last-child {
      float: left; }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xxlarge-up-4 > .column:nth-of-type(1n), .xxlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-4 > .column:nth-of-type(4n+1), .xxlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xxlarge-up-4 > .column:last-child, .xxlarge-up-4 > .columns:last-child {
      float: left; }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xxlarge-up-5 > .column:nth-of-type(1n), .xxlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-5 > .column:nth-of-type(5n+1), .xxlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xxlarge-up-5 > .column:last-child, .xxlarge-up-5 > .columns:last-child {
      float: left; }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xxlarge-up-6 > .column:nth-of-type(1n), .xxlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-6 > .column:nth-of-type(6n+1), .xxlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xxlarge-up-6 > .column:last-child, .xxlarge-up-6 > .columns:last-child {
      float: left; }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xxlarge-up-7 > .column:nth-of-type(1n), .xxlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-7 > .column:nth-of-type(7n+1), .xxlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xxlarge-up-7 > .column:last-child, .xxlarge-up-7 > .columns:last-child {
      float: left; }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xxlarge-up-8 > .column:nth-of-type(1n), .xxlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-8 > .column:nth-of-type(8n+1), .xxlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xxlarge-up-8 > .column:last-child, .xxlarge-up-8 > .columns:last-child {
      float: left; }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xxlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .xxlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xxlarge-centered, .xxlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xxlarge-uncentered,
  .xxlarge-push-0,
  .xxlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

@media screen and (min-width: 75em) {
  .xlarge-order-1 {
    order: 1; }
  .xlarge-order-2 {
    order: 2; }
  .xlarge-order-3 {
    order: 3; }
  .xlarge-order-4 {
    order: 4; }
  .xlarge-order-5 {
    order: 5; }
  .xlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 90em) {
  .xxlarge-order-1 {
    order: 1; }
  .xxlarge-order-2 {
    order: 2; }
  .xxlarge-order-3 {
    order: 3; }
  .xxlarge-order-4 {
    order: 4; }
  .xxlarge-order-5 {
    order: 5; }
  .xxlarge-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 75em) {
  .xlarge-flex-container {
    display: flex; }
  .xlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xlarge-flex-dir-row {
    flex-direction: row; }
  .xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xlarge-flex-dir-column {
    flex-direction: column; }
  .xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 90em) {
  .xxlarge-flex-container {
    display: flex; }
  .xxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xxlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xxlarge-flex-dir-row {
    flex-direction: row; }
  .xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xxlarge-flex-dir-column {
    flex-direction: column; }
  .xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #dedede; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #110c0c;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #0f0a0a; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 105rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #dedede;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #dedede; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #110c0c;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #dedede;
  background-color: #f3f3f3;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #110c0c; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #f3f3f3;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #110c0c; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 90em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #dedede;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: inset 0 1px 2px rgba(17, 12, 12, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #110c0c;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #ffffff;
    box-shadow: 0 0 5px #dedede;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #dedede; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #f3f3f3;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #110c0c; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #110c0c; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #dedede;
  background: #f3f3f3;
  color: #110c0c;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #dedede; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #dedede;
  border-radius: 0;
  background-color: #ffffff;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #110c0c;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #ffffff;
    box-shadow: 0 0 5px #dedede;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #f3f3f3;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #110c0c;
  color: #ffffff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #0e0a0a;
    color: #ffffff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #1779ba;
    color: #ffffff; }
    .button.primary:hover, .button.primary:focus {
      background-color: #126195;
      color: #ffffff; }
  .button.secondary {
    background-color: #767676;
    color: #ffffff; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #ffffff; }
  .button.success {
    background-color: #1abc9c;
    color: #110c0c; }
    .button.success:hover, .button.success:focus {
      background-color: #15967d;
      color: #110c0c; }
  .button.warning {
    background-color: #ffae00;
    color: #110c0c; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #110c0c; }
  .button.alert {
    background-color: #cc4b37;
    color: #ffffff; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #ffffff; }
  .button.white {
    background-color: #ffffff;
    color: #110c0c; }
    .button.white:hover, .button.white:focus {
      background-color: #cccccc;
      color: #110c0c; }
  .button.black {
    background-color: #110c0c;
    color: #ffffff; }
    .button.black:hover, .button.black:focus {
      background-color: #0e0a0a;
      color: #ffffff; }
  .button.brown {
    background-color: #6f5252;
    color: #ffffff; }
    .button.brown:hover, .button.brown:focus {
      background-color: #594242;
      color: #ffffff; }
  .button.light-gray {
    background-color: #f3f3f3;
    color: #110c0c; }
    .button.light-gray:hover, .button.light-gray:focus {
      background-color: #c2c2c2;
      color: #110c0c; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #110c0c;
      color: #ffffff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #1779ba;
        color: #ffffff; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #ffffff; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #1abc9c;
        color: #110c0c; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #110c0c; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #ffffff; }
    .button.disabled.white, .button[disabled].white {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.white, .button.disabled.white:hover, .button.disabled.white:focus, .button[disabled].white, .button[disabled].white:hover, .button[disabled].white:focus {
        background-color: #ffffff;
        color: #110c0c; }
    .button.disabled.black, .button[disabled].black {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.black, .button.disabled.black:hover, .button.disabled.black:focus, .button[disabled].black, .button[disabled].black:hover, .button[disabled].black:focus {
        background-color: #110c0c;
        color: #ffffff; }
    .button.disabled.brown, .button[disabled].brown {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.brown, .button.disabled.brown:hover, .button.disabled.brown:focus, .button[disabled].brown, .button[disabled].brown:hover, .button[disabled].brown:focus {
        background-color: #6f5252;
        color: #ffffff; }
    .button.disabled.light-gray, .button[disabled].light-gray {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.light-gray, .button.disabled.light-gray:hover, .button.disabled.light-gray:focus, .button[disabled].light-gray, .button[disabled].light-gray:hover, .button[disabled].light-gray:focus {
        background-color: #f3f3f3;
        color: #110c0c; }
  .button.hollow {
    border: 1px solid #110c0c;
    color: #110c0c; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #090606;
      color: #090606; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #110c0c;
        color: #110c0c; }
    .button.hollow.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
    .button.hollow.success {
      border: 1px solid #1abc9c;
      color: #1abc9c; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #0d5e4e;
        color: #0d5e4e; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #1abc9c;
          color: #1abc9c; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
    .button.hollow.white {
      border: 1px solid #ffffff;
      color: #ffffff; }
      .button.hollow.white:hover, .button.hollow.white:focus {
        border-color: gray;
        color: gray; }
        .button.hollow.white:hover.disabled, .button.hollow.white:hover[disabled], .button.hollow.white:focus.disabled, .button.hollow.white:focus[disabled] {
          border: 1px solid #ffffff;
          color: #ffffff; }
    .button.hollow.black {
      border: 1px solid #110c0c;
      color: #110c0c; }
      .button.hollow.black:hover, .button.hollow.black:focus {
        border-color: #090606;
        color: #090606; }
        .button.hollow.black:hover.disabled, .button.hollow.black:hover[disabled], .button.hollow.black:focus.disabled, .button.hollow.black:focus[disabled] {
          border: 1px solid #110c0c;
          color: #110c0c; }
    .button.hollow.brown {
      border: 1px solid #6f5252;
      color: #6f5252; }
      .button.hollow.brown:hover, .button.hollow.brown:focus {
        border-color: #382929;
        color: #382929; }
        .button.hollow.brown:hover.disabled, .button.hollow.brown:hover[disabled], .button.hollow.brown:focus.disabled, .button.hollow.brown:focus[disabled] {
          border: 1px solid #6f5252;
          color: #6f5252; }
    .button.hollow.light-gray {
      border: 1px solid #f3f3f3;
      color: #f3f3f3; }
      .button.hollow.light-gray:hover, .button.hollow.light-gray:focus {
        border-color: #7a7a7a;
        color: #7a7a7a; }
        .button.hollow.light-gray:hover.disabled, .button.hollow.light-gray:hover[disabled], .button.hollow.light-gray:focus.disabled, .button.hollow.light-gray:focus[disabled] {
          border: 1px solid #f3f3f3;
          color: #f3f3f3; }
  .button.clear {
    border: 1px solid #110c0c;
    color: #110c0c; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #090606;
      color: #090606; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #110c0c;
        color: #110c0c; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #1abc9c;
      color: #1abc9c; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #0d5e4e;
        color: #0d5e4e; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #1abc9c;
          color: #1abc9c; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
    .button.clear.white {
      border: 1px solid #ffffff;
      color: #ffffff; }
      .button.clear.white:hover, .button.clear.white:focus {
        border-color: gray;
        color: gray; }
        .button.clear.white:hover.disabled, .button.clear.white:hover[disabled], .button.clear.white:focus.disabled, .button.clear.white:focus[disabled] {
          border: 1px solid #ffffff;
          color: #ffffff; }
      .button.clear.white, .button.clear.white.disabled, .button.clear.white[disabled], .button.clear.white:hover, .button.clear.white:hover.disabled, .button.clear.white:hover[disabled], .button.clear.white:focus, .button.clear.white:focus.disabled, .button.clear.white:focus[disabled] {
        border-color: transparent; }
    .button.clear.black {
      border: 1px solid #110c0c;
      color: #110c0c; }
      .button.clear.black:hover, .button.clear.black:focus {
        border-color: #090606;
        color: #090606; }
        .button.clear.black:hover.disabled, .button.clear.black:hover[disabled], .button.clear.black:focus.disabled, .button.clear.black:focus[disabled] {
          border: 1px solid #110c0c;
          color: #110c0c; }
      .button.clear.black, .button.clear.black.disabled, .button.clear.black[disabled], .button.clear.black:hover, .button.clear.black:hover.disabled, .button.clear.black:hover[disabled], .button.clear.black:focus, .button.clear.black:focus.disabled, .button.clear.black:focus[disabled] {
        border-color: transparent; }
    .button.clear.brown {
      border: 1px solid #6f5252;
      color: #6f5252; }
      .button.clear.brown:hover, .button.clear.brown:focus {
        border-color: #382929;
        color: #382929; }
        .button.clear.brown:hover.disabled, .button.clear.brown:hover[disabled], .button.clear.brown:focus.disabled, .button.clear.brown:focus[disabled] {
          border: 1px solid #6f5252;
          color: #6f5252; }
      .button.clear.brown, .button.clear.brown.disabled, .button.clear.brown[disabled], .button.clear.brown:hover, .button.clear.brown:hover.disabled, .button.clear.brown:hover[disabled], .button.clear.brown:focus, .button.clear.brown:focus.disabled, .button.clear.brown:focus[disabled] {
        border-color: transparent; }
    .button.clear.light-gray {
      border: 1px solid #f3f3f3;
      color: #f3f3f3; }
      .button.clear.light-gray:hover, .button.clear.light-gray:focus {
        border-color: #7a7a7a;
        color: #7a7a7a; }
        .button.clear.light-gray:hover.disabled, .button.clear.light-gray:hover[disabled], .button.clear.light-gray:focus.disabled, .button.clear.light-gray:focus[disabled] {
          border: 1px solid #f3f3f3;
          color: #f3f3f3; }
      .button.clear.light-gray, .button.clear.light-gray.disabled, .button.clear.light-gray[disabled], .button.clear.light-gray:hover, .button.clear.light-gray:hover.disabled, .button.clear.light-gray:hover[disabled], .button.clear.light-gray:focus, .button.clear.light-gray:focus.disabled, .button.clear.light-gray:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #ffffff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #110c0c; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #1abc9c; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.dropdown.hollow.white::after {
    border-top-color: #ffffff; }
  .button.dropdown.hollow.black::after {
    border-top-color: #110c0c; }
  .button.dropdown.hollow.brown::after {
    border-top-color: #6f5252; }
  .button.dropdown.hollow.light-gray::after {
    border-top-color: #f3f3f3; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

.accordion {
  margin-left: 0;
  background: #ffffff;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #f3f3f3;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #f3f3f3; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #f3f3f3;
  border-bottom: 0;
  background-color: #ffffff;
  color: #110c0c; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #f3f3f3; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #1779ba;
  color: #ffffff; }
  .badge.primary {
    background: #1779ba;
    color: #ffffff; }
  .badge.secondary {
    background: #767676;
    color: #ffffff; }
  .badge.success {
    background: #1abc9c;
    color: #110c0c; }
  .badge.warning {
    background: #ffae00;
    color: #110c0c; }
  .badge.alert {
    background: #cc4b37;
    color: #ffffff; }
  .badge.white {
    background: #ffffff;
    color: #110c0c; }
  .badge.black {
    background: #110c0c;
    color: #ffffff; }
  .badge.brown {
    background: #6f5252;
    color: #ffffff; }
  .badge.light-gray {
    background: #f3f3f3;
    color: #110c0c; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #110c0c;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #dedede; }
  .breadcrumbs a {
    color: #1779ba; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #dedede;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #ffffff; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #ffffff; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #ffffff; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #ffffff; }
  .button-group.success .button {
    background-color: #1abc9c;
    color: #110c0c; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #15967d;
      color: #110c0c; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #110c0c; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #110c0c; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #ffffff; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #ffffff; }
  .button-group.white .button {
    background-color: #ffffff;
    color: #110c0c; }
    .button-group.white .button:hover, .button-group.white .button:focus {
      background-color: #cccccc;
      color: #110c0c; }
  .button-group.black .button {
    background-color: #110c0c;
    color: #ffffff; }
    .button-group.black .button:hover, .button-group.black .button:focus {
      background-color: #0e0a0a;
      color: #ffffff; }
  .button-group.brown .button {
    background-color: #6f5252;
    color: #ffffff; }
    .button-group.brown .button:hover, .button-group.brown .button:focus {
      background-color: #594242;
      color: #ffffff; }
  .button-group.light-gray .button {
    background-color: #f3f3f3;
    color: #110c0c; }
    .button-group.light-gray .button:hover, .button-group.light-gray .button:focus {
      background-color: #c2c2c2;
      color: #110c0c; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(17, 12, 12, 0.25);
  border-radius: 0;
  background-color: white;
  color: #110c0c; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #1779ba;
    color: #ffffff; }
  .callout.secondary {
    background-color: #767676;
    color: #ffffff; }
  .callout.success {
    background-color: #1abc9c;
    color: #110c0c; }
  .callout.warning {
    background-color: #ffae00;
    color: #110c0c; }
  .callout.alert {
    background-color: #cc4b37;
    color: #ffffff; }
  .callout.white {
    background-color: white;
    color: #110c0c; }
  .callout.black {
    background-color: #110c0c;
    color: #ffffff; }
  .callout.brown {
    background-color: #6f5252;
    color: #ffffff; }
  .callout.light-gray {
    background-color: #f3f3f3;
    color: #110c0c; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #f3f3f3;
  border-radius: 0;
  background: #ffffff;
  box-shadow: none;
  overflow: hidden;
  color: #110c0c; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #f3f3f3; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #110c0c; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  @media screen and (min-width: 75em) {
    .menu.xlarge-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.xlarge-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.xlarge-expanded li {
      flex: 1 1 0px; }
    .menu.xlarge-simple li {
      flex: 1 1 0px; } }
  @media screen and (min-width: 90em) {
    .menu.xxlarge-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.xxlarge-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.xxlarge-expanded li {
      flex: 1 1 0px; }
    .menu.xxlarge-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #1779ba;
    color: #ffffff; }
  .menu .active > a {
    background: #1779ba;
    color: #ffffff; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #ffffff;
    box-shadow: 0 7px 0 #ffffff, 0 14px 0 #ffffff;
    content: ''; }
  .menu-icon:hover::after {
    background: #dedede;
    box-shadow: 0 7px 0 #dedede, 0 14px 0 #dedede; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #110c0c;
    box-shadow: 0 7px 0 #110c0c, 0 14px 0 #110c0c;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #ffffff; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #ffffff;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #110c0c; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #110c0c transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #dedede;
  border-radius: 0;
  background-color: #ffffff;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #110c0c transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #1779ba; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #110c0c transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #110c0c; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #110c0c transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #110c0c transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #110c0c; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #110c0c transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #110c0c transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #110c0c; } }

@media screen and (min-width: 75em) {
  .dropdown.menu.xlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #110c0c transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.xlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xlarge-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #110c0c transparent transparent; }
  .dropdown.menu.xlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #110c0c; } }

@media screen and (min-width: 90em) {
  .dropdown.menu.xxlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xxlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #110c0c transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.xxlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xxlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #110c0c transparent transparent; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #110c0c; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #dedede;
  background: #ffffff; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #110c0c transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #110c0c; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #ffffff; }
  .label.primary {
    background: #1779ba;
    color: #ffffff; }
  .label.secondary {
    background: #767676;
    color: #ffffff; }
  .label.success {
    background: #1abc9c;
    color: #110c0c; }
  .label.warning {
    background: #ffae00;
    color: #110c0c; }
  .label.alert {
    background: #cc4b37;
    color: #ffffff; }
  .label.white {
    background: #ffffff;
    color: #110c0c; }
  .label.black {
    background: #110c0c;
    color: #ffffff; }
  .label.brown {
    background: #6f5252;
    color: #ffffff; }
  .label.light-gray {
    background: #f3f3f3;
    color: #110c0c; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #f3f3f3; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(17, 12, 12, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #f3f3f3; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(17, 12, 12, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(17, 12, 12, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(17, 12, 12, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(17, 12, 12, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(17, 12, 12, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media screen and (min-width: 75em) {
  .position-left.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-xlarge ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-xlarge ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-xlarge ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-xlarge ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media screen and (min-width: 90em) {
  .position-left.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-xxlarge ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-xxlarge ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-xxlarge ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-xxlarge ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

@media screen and (min-width: 75em) {
  .off-canvas.in-canvas-for-xlarge {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-xlarge.position-left, .off-canvas.in-canvas-for-xlarge.position-right, .off-canvas.in-canvas-for-xlarge.position-top, .off-canvas.in-canvas-for-xlarge.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-xlarge .close-button {
      display: none; } }

@media screen and (min-width: 90em) {
  .off-canvas.in-canvas-for-xxlarge {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-xxlarge.position-left, .off-canvas.in-canvas-for-xxlarge.position-right, .off-canvas.in-canvas-for-xxlarge.position-top, .off-canvas.in-canvas-for-xxlarge.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-xxlarge .close-button {
      display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(17, 12, 12, 0.5);
  color: #ffffff; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #ffffff; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(17, 12, 12, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #dedede; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #110c0c; }
    .pagination a:hover,
    .pagination button:hover {
      background: #f3f3f3; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #6f5252;
    color: #ffffff;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #dedede;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #110c0c; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #dedede; }
  .progress.primary .progress-meter {
    background-color: #1779ba; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #1abc9c; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }
  .progress.white .progress-meter {
    background-color: #ffffff; }
  .progress.black .progress-meter {
    background-color: #110c0c; }
  .progress.brown .progress-meter {
    background-color: #6f5252; }
  .progress.light-gray .progress-meter {
    background-color: #f3f3f3; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #f3f3f3;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #dedede;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #14679e; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(17, 12, 12, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #dedede;
  border-radius: 0;
  background-color: #ffffff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 105rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 105rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 105rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 105rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #dedede;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #ffffff;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #1779ba; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #f2f2f2;
    background-color: #ffffff; }
  caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  thead {
    background: #f9f9f9;
    color: #110c0c; }
  tfoot {
    background: #f2f2f2;
    color: #110c0c; }
  thead tr,
  tfoot tr {
    background: transparent; }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  tbody th,
  tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f2f2f2; }
  table.unstriped tbody {
    background-color: #ffffff; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f2f2f2;
      background-color: #ffffff; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f4f4f4; }

table.hover tfoot tr:hover {
  background-color: #ededed; }

table.hover tbody tr:hover {
  background-color: #fafafa; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid transparent;
  background: #ffffff;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #1779ba; }
  .tabs.primary > li > a {
    color: #ffffff; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #1673b1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #110c0c; }
    .tabs-title > a:hover {
      background: #ffffff;
      color: #0f0a0a; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #eeeeee;
      color: #110c0c; }

.tabs-content {
  border: 1px solid transparent;
  border-top: 0;
  background: #ffffff;
  color: #110c0c;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid transparent;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #ffffff;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(17, 12, 12, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #110c0c;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #110c0c;
  font-size: 80%;
  color: #ffffff; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #110c0c;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #110c0c transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #110c0c;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #110c0c transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: rgba(111, 82, 82, 0.5); }
  .top-bar ul ul {
    background-color: #ffffff; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 89.9375em) {
    .top-bar.stacked-for-xlarge {
      flex-wrap: wrap; }
      .top-bar.stacked-for-xlarge .top-bar-left,
      .top-bar.stacked-for-xlarge .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  .top-bar.stacked-for-xxlarge {
    flex-wrap: wrap; }
    .top-bar.stacked-for-xxlarge .top-bar-left,
    .top-bar.stacked-for-xxlarge .top-bar-right {
      flex: 0 0 100%;
      max-width: 100%; }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 89.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 90em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.clearfix::after {
  clear: both; }

/*!
 * Font Awesome Pro 5.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  animation: fa-spin 1s infinite steps(8); }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1); }

.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e"; }

.fa-accessible-icon:before {
  content: "\f368"; }

.fa-accusoft:before {
  content: "\f369"; }

.fa-address-book:before {
  content: "\f2b9"; }

.fa-address-card:before {
  content: "\f2bb"; }

.fa-adjust:before {
  content: "\f042"; }

.fa-adn:before {
  content: "\f170"; }

.fa-adversal:before {
  content: "\f36a"; }

.fa-affiliatetheme:before {
  content: "\f36b"; }

.fa-alarm-clock:before {
  content: "\f34e"; }

.fa-algolia:before {
  content: "\f36c"; }

.fa-align-center:before {
  content: "\f037"; }

.fa-align-justify:before {
  content: "\f039"; }

.fa-align-left:before {
  content: "\f036"; }

.fa-align-right:before {
  content: "\f038"; }

.fa-allergies:before {
  content: "\f461"; }

.fa-amazon:before {
  content: "\f270"; }

.fa-amazon-pay:before {
  content: "\f42c"; }

.fa-ambulance:before {
  content: "\f0f9"; }

.fa-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.fa-amilia:before {
  content: "\f36d"; }

.fa-anchor:before {
  content: "\f13d"; }

.fa-android:before {
  content: "\f17b"; }

.fa-angellist:before {
  content: "\f209"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-angry:before {
  content: "\f556"; }

.fa-angrycreative:before {
  content: "\f36e"; }

.fa-angular:before {
  content: "\f420"; }

.fa-app-store:before {
  content: "\f36f"; }

.fa-app-store-ios:before {
  content: "\f370"; }

.fa-apper:before {
  content: "\f371"; }

.fa-apple:before {
  content: "\f179"; }

.fa-apple-pay:before {
  content: "\f415"; }

.fa-archive:before {
  content: "\f187"; }

.fa-archway:before {
  content: "\f557"; }

.fa-arrow-alt-circle-down:before {
  content: "\f358"; }

.fa-arrow-alt-circle-left:before {
  content: "\f359"; }

.fa-arrow-alt-circle-right:before {
  content: "\f35a"; }

.fa-arrow-alt-circle-up:before {
  content: "\f35b"; }

.fa-arrow-alt-down:before {
  content: "\f354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\f346"; }

.fa-arrow-alt-from-left:before {
  content: "\f347"; }

.fa-arrow-alt-from-right:before {
  content: "\f348"; }

.fa-arrow-alt-from-top:before {
  content: "\f349"; }

.fa-arrow-alt-left:before {
  content: "\f355"; }

.fa-arrow-alt-right:before {
  content: "\f356"; }

.fa-arrow-alt-square-down:before {
  content: "\f350"; }

.fa-arrow-alt-square-left:before {
  content: "\f351"; }

.fa-arrow-alt-square-right:before {
  content: "\f352"; }

.fa-arrow-alt-square-up:before {
  content: "\f353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\f34a"; }

.fa-arrow-alt-to-left:before {
  content: "\f34b"; }

.fa-arrow-alt-to-right:before {
  content: "\f34c"; }

.fa-arrow-alt-to-top:before {
  content: "\f34d"; }

.fa-arrow-alt-up:before {
  content: "\f357"; }

.fa-arrow-circle-down:before {
  content: "\f0ab"; }

.fa-arrow-circle-left:before {
  content: "\f0a8"; }

.fa-arrow-circle-right:before {
  content: "\f0a9"; }

.fa-arrow-circle-up:before {
  content: "\f0aa"; }

.fa-arrow-down:before {
  content: "\f063"; }

.fa-arrow-from-bottom:before {
  content: "\f342"; }

.fa-arrow-from-left:before {
  content: "\f343"; }

.fa-arrow-from-right:before {
  content: "\f344"; }

.fa-arrow-from-top:before {
  content: "\f345"; }

.fa-arrow-left:before {
  content: "\f060"; }

.fa-arrow-right:before {
  content: "\f061"; }

.fa-arrow-square-down:before {
  content: "\f339"; }

.fa-arrow-square-left:before {
  content: "\f33a"; }

.fa-arrow-square-right:before {
  content: "\f33b"; }

.fa-arrow-square-up:before {
  content: "\f33c"; }

.fa-arrow-to-bottom:before {
  content: "\f33d"; }

.fa-arrow-to-left:before {
  content: "\f33e"; }

.fa-arrow-to-right:before {
  content: "\f340"; }

.fa-arrow-to-top:before {
  content: "\f341"; }

.fa-arrow-up:before {
  content: "\f062"; }

.fa-arrows:before {
  content: "\f047"; }

.fa-arrows-alt:before {
  content: "\f0b2"; }

.fa-arrows-alt-h:before {
  content: "\f337"; }

.fa-arrows-alt-v:before {
  content: "\f338"; }

.fa-arrows-h:before {
  content: "\f07e"; }

.fa-arrows-v:before {
  content: "\f07d"; }

.fa-assistive-listening-systems:before {
  content: "\f2a2"; }

.fa-asterisk:before {
  content: "\f069"; }

.fa-asymmetrik:before {
  content: "\f372"; }

.fa-at:before {
  content: "\f1fa"; }

.fa-atlas:before {
  content: "\f558"; }

.fa-audible:before {
  content: "\f373"; }

.fa-audio-description:before {
  content: "\f29e"; }

.fa-autoprefixer:before {
  content: "\f41c"; }

.fa-avianex:before {
  content: "\f374"; }

.fa-aviato:before {
  content: "\f421"; }

.fa-award:before {
  content: "\f559"; }

.fa-aws:before {
  content: "\f375"; }

.fa-backspace:before {
  content: "\f55a"; }

.fa-backward:before {
  content: "\f04a"; }

.fa-badge:before {
  content: "\f335"; }

.fa-badge-check:before {
  content: "\f336"; }

.fa-balance-scale:before {
  content: "\f24e"; }

.fa-balance-scale-left:before {
  content: "\f515"; }

.fa-balance-scale-right:before {
  content: "\f516"; }

.fa-ban:before {
  content: "\f05e"; }

.fa-band-aid:before {
  content: "\f462"; }

.fa-bandcamp:before {
  content: "\f2d5"; }

.fa-barcode:before {
  content: "\f02a"; }

.fa-barcode-alt:before {
  content: "\f463"; }

.fa-barcode-read:before {
  content: "\f464"; }

.fa-barcode-scan:before {
  content: "\f465"; }

.fa-bars:before {
  content: "\f0c9"; }

.fa-baseball:before {
  content: "\f432"; }

.fa-baseball-ball:before {
  content: "\f433"; }

.fa-basketball-ball:before {
  content: "\f434"; }

.fa-basketball-hoop:before {
  content: "\f435"; }

.fa-bath:before {
  content: "\f2cd"; }

.fa-battery-bolt:before {
  content: "\f376"; }

.fa-battery-empty:before {
  content: "\f244"; }

.fa-battery-full:before {
  content: "\f240"; }

.fa-battery-half:before {
  content: "\f242"; }

.fa-battery-quarter:before {
  content: "\f243"; }

.fa-battery-slash:before {
  content: "\f377"; }

.fa-battery-three-quarters:before {
  content: "\f241"; }

.fa-bed:before {
  content: "\f236"; }

.fa-beer:before {
  content: "\f0fc"; }

.fa-behance:before {
  content: "\f1b4"; }

.fa-behance-square:before {
  content: "\f1b5"; }

.fa-bell:before {
  content: "\f0f3"; }

.fa-bell-slash:before {
  content: "\f1f6"; }

.fa-bezier-curve:before {
  content: "\f55b"; }

.fa-bicycle:before {
  content: "\f206"; }

.fa-bimobject:before {
  content: "\f378"; }

.fa-binoculars:before {
  content: "\f1e5"; }

.fa-birthday-cake:before {
  content: "\f1fd"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitcoin:before {
  content: "\f379"; }

.fa-bity:before {
  content: "\f37a"; }

.fa-black-tie:before {
  content: "\f27e"; }

.fa-blackberry:before {
  content: "\f37b"; }

.fa-blanket:before {
  content: "\f498"; }

.fa-blender:before {
  content: "\f517"; }

.fa-blind:before {
  content: "\f29d"; }

.fa-blogger:before {
  content: "\f37c"; }

.fa-blogger-b:before {
  content: "\f37d"; }

.fa-bluetooth:before {
  content: "\f293"; }

.fa-bluetooth-b:before {
  content: "\f294"; }

.fa-bold:before {
  content: "\f032"; }

.fa-bolt:before {
  content: "\f0e7"; }

.fa-bomb:before {
  content: "\f1e2"; }

.fa-bong:before {
  content: "\f55c"; }

.fa-book:before {
  content: "\f02d"; }

.fa-book-heart:before {
  content: "\f499"; }

.fa-book-open:before {
  content: "\f518"; }

.fa-bookmark:before {
  content: "\f02e"; }

.fa-bowling-ball:before {
  content: "\f436"; }

.fa-bowling-pins:before {
  content: "\f437"; }

.fa-box:before {
  content: "\f466"; }

.fa-box-alt:before {
  content: "\f49a"; }

.fa-box-check:before {
  content: "\f467"; }

.fa-box-fragile:before {
  content: "\f49b"; }

.fa-box-full:before {
  content: "\f49c"; }

.fa-box-heart:before {
  content: "\f49d"; }

.fa-box-open:before {
  content: "\f49e"; }

.fa-box-up:before {
  content: "\f49f"; }

.fa-box-usd:before {
  content: "\f4a0"; }

.fa-boxes:before {
  content: "\f468"; }

.fa-boxes-alt:before {
  content: "\f4a1"; }

.fa-boxing-glove:before {
  content: "\f438"; }

.fa-braille:before {
  content: "\f2a1"; }

.fa-briefcase:before {
  content: "\f0b1"; }

.fa-briefcase-medical:before {
  content: "\f469"; }

.fa-broadcast-tower:before {
  content: "\f519"; }

.fa-broom:before {
  content: "\f51a"; }

.fa-browser:before {
  content: "\f37e"; }

.fa-brush:before {
  content: "\f55d"; }

.fa-btc:before {
  content: "\f15a"; }

.fa-bug:before {
  content: "\f188"; }

.fa-building:before {
  content: "\f1ad"; }

.fa-bullhorn:before {
  content: "\f0a1"; }

.fa-bullseye:before {
  content: "\f140"; }

.fa-burn:before {
  content: "\f46a"; }

.fa-buromobelexperte:before {
  content: "\f37f"; }

.fa-bus:before {
  content: "\f207"; }

.fa-bus-alt:before {
  content: "\f55e"; }

.fa-buysellads:before {
  content: "\f20d"; }

.fa-calculator:before {
  content: "\f1ec"; }

.fa-calendar:before {
  content: "\f133"; }

.fa-calendar-alt:before {
  content: "\f073"; }

.fa-calendar-check:before {
  content: "\f274"; }

.fa-calendar-edit:before {
  content: "\f333"; }

.fa-calendar-exclamation:before {
  content: "\f334"; }

.fa-calendar-minus:before {
  content: "\f272"; }

.fa-calendar-plus:before {
  content: "\f271"; }

.fa-calendar-times:before {
  content: "\f273"; }

.fa-camera:before {
  content: "\f030"; }

.fa-camera-alt:before {
  content: "\f332"; }

.fa-camera-retro:before {
  content: "\f083"; }

.fa-cannabis:before {
  content: "\f55f"; }

.fa-capsules:before {
  content: "\f46b"; }

.fa-car:before {
  content: "\f1b9"; }

.fa-caret-circle-down:before {
  content: "\f32d"; }

.fa-caret-circle-left:before {
  content: "\f32e"; }

.fa-caret-circle-right:before {
  content: "\f330"; }

.fa-caret-circle-up:before {
  content: "\f331"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-caret-square-down:before {
  content: "\f150"; }

.fa-caret-square-left:before {
  content: "\f191"; }

.fa-caret-square-right:before {
  content: "\f152"; }

.fa-caret-square-up:before {
  content: "\f151"; }

.fa-caret-up:before {
  content: "\f0d8"; }

.fa-cart-arrow-down:before {
  content: "\f218"; }

.fa-cart-plus:before {
  content: "\f217"; }

.fa-cc-amazon-pay:before {
  content: "\f42d"; }

.fa-cc-amex:before {
  content: "\f1f3"; }

.fa-cc-apple-pay:before {
  content: "\f416"; }

.fa-cc-diners-club:before {
  content: "\f24c"; }

.fa-cc-discover:before {
  content: "\f1f2"; }

.fa-cc-jcb:before {
  content: "\f24b"; }

.fa-cc-mastercard:before {
  content: "\f1f1"; }

.fa-cc-paypal:before {
  content: "\f1f4"; }

.fa-cc-stripe:before {
  content: "\f1f5"; }

.fa-cc-visa:before {
  content: "\f1f0"; }

.fa-centercode:before {
  content: "\f380"; }

.fa-certificate:before {
  content: "\f0a3"; }

.fa-chalkboard:before {
  content: "\f51b"; }

.fa-chalkboard-teacher:before {
  content: "\f51c"; }

.fa-chart-area:before {
  content: "\f1fe"; }

.fa-chart-bar:before {
  content: "\f080"; }

.fa-chart-line:before {
  content: "\f201"; }

.fa-chart-pie:before {
  content: "\f200"; }

.fa-check:before {
  content: "\f00c"; }

.fa-check-circle:before {
  content: "\f058"; }

.fa-check-double:before {
  content: "\f560"; }

.fa-check-square:before {
  content: "\f14a"; }

.fa-chess:before {
  content: "\f439"; }

.fa-chess-bishop:before {
  content: "\f43a"; }

.fa-chess-bishop-alt:before {
  content: "\f43b"; }

.fa-chess-board:before {
  content: "\f43c"; }

.fa-chess-clock:before {
  content: "\f43d"; }

.fa-chess-clock-alt:before {
  content: "\f43e"; }

.fa-chess-king:before {
  content: "\f43f"; }

.fa-chess-king-alt:before {
  content: "\f440"; }

.fa-chess-knight:before {
  content: "\f441"; }

.fa-chess-knight-alt:before {
  content: "\f442"; }

.fa-chess-pawn:before {
  content: "\f443"; }

.fa-chess-pawn-alt:before {
  content: "\f444"; }

.fa-chess-queen:before {
  content: "\f445"; }

.fa-chess-queen-alt:before {
  content: "\f446"; }

.fa-chess-rook:before {
  content: "\f447"; }

.fa-chess-rook-alt:before {
  content: "\f448"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-double-down:before {
  content: "\f322"; }

.fa-chevron-double-left:before {
  content: "\f323"; }

.fa-chevron-double-right:before {
  content: "\f324"; }

.fa-chevron-double-up:before {
  content: "\f325"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-square-down:before {
  content: "\f329"; }

.fa-chevron-square-left:before {
  content: "\f32a"; }

.fa-chevron-square-right:before {
  content: "\f32b"; }

.fa-chevron-square-up:before {
  content: "\f32c"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-child:before {
  content: "\f1ae"; }

.fa-chrome:before {
  content: "\f268"; }

.fa-church:before {
  content: "\f51d"; }

.fa-circle:before {
  content: "\f111"; }

.fa-circle-notch:before {
  content: "\f1ce"; }

.fa-clipboard:before {
  content: "\f328"; }

.fa-clipboard-check:before {
  content: "\f46c"; }

.fa-clipboard-list:before {
  content: "\f46d"; }

.fa-clock:before {
  content: "\f017"; }

.fa-clone:before {
  content: "\f24d"; }

.fa-closed-captioning:before {
  content: "\f20a"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-cloud-download:before {
  content: "\f0ed"; }

.fa-cloud-download-alt:before {
  content: "\f381"; }

.fa-cloud-upload:before {
  content: "\f0ee"; }

.fa-cloud-upload-alt:before {
  content: "\f382"; }

.fa-cloudscale:before {
  content: "\f383"; }

.fa-cloudsmith:before {
  content: "\f384"; }

.fa-cloudversify:before {
  content: "\f385"; }

.fa-club:before {
  content: "\f327"; }

.fa-cocktail:before {
  content: "\f561"; }

.fa-code:before {
  content: "\f121"; }

.fa-code-branch:before {
  content: "\f126"; }

.fa-code-commit:before {
  content: "\f386"; }

.fa-code-merge:before {
  content: "\f387"; }

.fa-codepen:before {
  content: "\f1cb"; }

.fa-codiepie:before {
  content: "\f284"; }

.fa-coffee:before {
  content: "\f0f4"; }

.fa-cog:before {
  content: "\f013"; }

.fa-cogs:before {
  content: "\f085"; }

.fa-coins:before {
  content: "\f51e"; }

.fa-columns:before {
  content: "\f0db"; }

.fa-comment:before {
  content: "\f075"; }

.fa-comment-alt:before {
  content: "\f27a"; }

.fa-comment-alt-check:before {
  content: "\f4a2"; }

.fa-comment-alt-dots:before {
  content: "\f4a3"; }

.fa-comment-alt-edit:before {
  content: "\f4a4"; }

.fa-comment-alt-exclamation:before {
  content: "\f4a5"; }

.fa-comment-alt-lines:before {
  content: "\f4a6"; }

.fa-comment-alt-minus:before {
  content: "\f4a7"; }

.fa-comment-alt-plus:before {
  content: "\f4a8"; }

.fa-comment-alt-slash:before {
  content: "\f4a9"; }

.fa-comment-alt-smile:before {
  content: "\f4aa"; }

.fa-comment-alt-times:before {
  content: "\f4ab"; }

.fa-comment-check:before {
  content: "\f4ac"; }

.fa-comment-dots:before {
  content: "\f4ad"; }

.fa-comment-edit:before {
  content: "\f4ae"; }

.fa-comment-exclamation:before {
  content: "\f4af"; }

.fa-comment-lines:before {
  content: "\f4b0"; }

.fa-comment-minus:before {
  content: "\f4b1"; }

.fa-comment-plus:before {
  content: "\f4b2"; }

.fa-comment-slash:before {
  content: "\f4b3"; }

.fa-comment-smile:before {
  content: "\f4b4"; }

.fa-comment-times:before {
  content: "\f4b5"; }

.fa-comments:before {
  content: "\f086"; }

.fa-comments-alt:before {
  content: "\f4b6"; }

.fa-compact-disc:before {
  content: "\f51f"; }

.fa-compass:before {
  content: "\f14e"; }

.fa-compress:before {
  content: "\f066"; }

.fa-compress-alt:before {
  content: "\f422"; }

.fa-compress-wide:before {
  content: "\f326"; }

.fa-concierge-bell:before {
  content: "\f562"; }

.fa-connectdevelop:before {
  content: "\f20e"; }

.fa-container-storage:before {
  content: "\f4b7"; }

.fa-contao:before {
  content: "\f26d"; }

.fa-conveyor-belt:before {
  content: "\f46e"; }

.fa-conveyor-belt-alt:before {
  content: "\f46f"; }

.fa-cookie:before {
  content: "\f563"; }

.fa-cookie-bite:before {
  content: "\f564"; }

.fa-copy:before {
  content: "\f0c5"; }

.fa-copyright:before {
  content: "\f1f9"; }

.fa-couch:before {
  content: "\f4b8"; }

.fa-cpanel:before {
  content: "\f388"; }

.fa-creative-commons:before {
  content: "\f25e"; }

.fa-creative-commons-by:before {
  content: "\f4e7"; }

.fa-creative-commons-nc:before {
  content: "\f4e8"; }

.fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.fa-creative-commons-nd:before {
  content: "\f4eb"; }

.fa-creative-commons-pd:before {
  content: "\f4ec"; }

.fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.fa-creative-commons-remix:before {
  content: "\f4ee"; }

.fa-creative-commons-sa:before {
  content: "\f4ef"; }

.fa-creative-commons-sampling:before {
  content: "\f4f0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.fa-creative-commons-share:before {
  content: "\f4f2"; }

.fa-creative-commons-zero:before {
  content: "\f4f3"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-credit-card-blank:before {
  content: "\f389"; }

.fa-credit-card-front:before {
  content: "\f38a"; }

.fa-cricket:before {
  content: "\f449"; }

.fa-crop:before {
  content: "\f125"; }

.fa-crop-alt:before {
  content: "\f565"; }

.fa-crosshairs:before {
  content: "\f05b"; }

.fa-crow:before {
  content: "\f520"; }

.fa-crown:before {
  content: "\f521"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-css3-alt:before {
  content: "\f38b"; }

.fa-cube:before {
  content: "\f1b2"; }

.fa-cubes:before {
  content: "\f1b3"; }

.fa-curling:before {
  content: "\f44a"; }

.fa-cut:before {
  content: "\f0c4"; }

.fa-cuttlefish:before {
  content: "\f38c"; }

.fa-d-and-d:before {
  content: "\f38d"; }

.fa-dashcube:before {
  content: "\f210"; }

.fa-database:before {
  content: "\f1c0"; }

.fa-deaf:before {
  content: "\f2a4"; }

.fa-delicious:before {
  content: "\f1a5"; }

.fa-deploydog:before {
  content: "\f38e"; }

.fa-deskpro:before {
  content: "\f38f"; }

.fa-desktop:before {
  content: "\f108"; }

.fa-desktop-alt:before {
  content: "\f390"; }

.fa-deviantart:before {
  content: "\f1bd"; }

.fa-diagnoses:before {
  content: "\f470"; }

.fa-diamond:before {
  content: "\f219"; }

.fa-dice:before {
  content: "\f522"; }

.fa-dice-five:before {
  content: "\f523"; }

.fa-dice-four:before {
  content: "\f524"; }

.fa-dice-one:before {
  content: "\f525"; }

.fa-dice-six:before {
  content: "\f526"; }

.fa-dice-three:before {
  content: "\f527"; }

.fa-dice-two:before {
  content: "\f528"; }

.fa-digg:before {
  content: "\f1a6"; }

.fa-digital-ocean:before {
  content: "\f391"; }

.fa-digital-tachograph:before {
  content: "\f566"; }

.fa-discord:before {
  content: "\f392"; }

.fa-discourse:before {
  content: "\f393"; }

.fa-divide:before {
  content: "\f529"; }

.fa-dizzy:before {
  content: "\f567"; }

.fa-dna:before {
  content: "\f471"; }

.fa-dochub:before {
  content: "\f394"; }

.fa-docker:before {
  content: "\f395"; }

.fa-dollar-sign:before {
  content: "\f155"; }

.fa-dolly:before {
  content: "\f472"; }

.fa-dolly-empty:before {
  content: "\f473"; }

.fa-dolly-flatbed:before {
  content: "\f474"; }

.fa-dolly-flatbed-alt:before {
  content: "\f475"; }

.fa-dolly-flatbed-empty:before {
  content: "\f476"; }

.fa-donate:before {
  content: "\f4b9"; }

.fa-door-closed:before {
  content: "\f52a"; }

.fa-door-open:before {
  content: "\f52b"; }

.fa-dot-circle:before {
  content: "\f192"; }

.fa-dove:before {
  content: "\f4ba"; }

.fa-download:before {
  content: "\f019"; }

.fa-draft2digital:before {
  content: "\f396"; }

.fa-drafting-compass:before {
  content: "\f568"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-dribbble-square:before {
  content: "\f397"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-drum:before {
  content: "\f569"; }

.fa-drum-steelpan:before {
  content: "\f56a"; }

.fa-drupal:before {
  content: "\f1a9"; }

.fa-dumbbell:before {
  content: "\f44b"; }

.fa-dyalog:before {
  content: "\f399"; }

.fa-earlybirds:before {
  content: "\f39a"; }

.fa-ebay:before {
  content: "\f4f4"; }

.fa-edge:before {
  content: "\f282"; }

.fa-edit:before {
  content: "\f044"; }

.fa-eject:before {
  content: "\f052"; }

.fa-elementor:before {
  content: "\f430"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-h-alt:before {
  content: "\f39b"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-ellipsis-v-alt:before {
  content: "\f39c"; }

.fa-ember:before {
  content: "\f423"; }

.fa-empire:before {
  content: "\f1d1"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-envelope-open:before {
  content: "\f2b6"; }

.fa-envelope-square:before {
  content: "\f199"; }

.fa-envira:before {
  content: "\f299"; }

.fa-equals:before {
  content: "\f52c"; }

.fa-eraser:before {
  content: "\f12d"; }

.fa-erlang:before {
  content: "\f39d"; }

.fa-ethereum:before {
  content: "\f42e"; }

.fa-etsy:before {
  content: "\f2d7"; }

.fa-euro-sign:before {
  content: "\f153"; }

.fa-exchange:before {
  content: "\f0ec"; }

.fa-exchange-alt:before {
  content: "\f362"; }

.fa-exclamation:before {
  content: "\f12a"; }

.fa-exclamation-circle:before {
  content: "\f06a"; }

.fa-exclamation-square:before {
  content: "\f321"; }

.fa-exclamation-triangle:before {
  content: "\f071"; }

.fa-expand:before {
  content: "\f065"; }

.fa-expand-alt:before {
  content: "\f424"; }

.fa-expand-arrows:before {
  content: "\f31d"; }

.fa-expand-arrows-alt:before {
  content: "\f31e"; }

.fa-expand-wide:before {
  content: "\f320"; }

.fa-expeditedssl:before {
  content: "\f23e"; }

.fa-external-link:before {
  content: "\f08e"; }

.fa-external-link-alt:before {
  content: "\f35d"; }

.fa-external-link-square:before {
  content: "\f14c"; }

.fa-external-link-square-alt:before {
  content: "\f360"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-eye-dropper:before {
  content: "\f1fb"; }

.fa-eye-slash:before {
  content: "\f070"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-facebook-messenger:before {
  content: "\f39f"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-fast-backward:before {
  content: "\f049"; }

.fa-fast-forward:before {
  content: "\f050"; }

.fa-fax:before {
  content: "\f1ac"; }

.fa-feather:before {
  content: "\f52d"; }

.fa-feather-alt:before {
  content: "\f56b"; }

.fa-female:before {
  content: "\f182"; }

.fa-field-hockey:before {
  content: "\f44c"; }

.fa-fighter-jet:before {
  content: "\f0fb"; }

.fa-file:before {
  content: "\f15b"; }

.fa-file-alt:before {
  content: "\f15c"; }

.fa-file-archive:before {
  content: "\f1c6"; }

.fa-file-audio:before {
  content: "\f1c7"; }

.fa-file-check:before {
  content: "\f316"; }

.fa-file-code:before {
  content: "\f1c9"; }

.fa-file-contract:before {
  content: "\f56c"; }

.fa-file-download:before {
  content: "\f56d"; }

.fa-file-edit:before {
  content: "\f31c"; }

.fa-file-excel:before {
  content: "\f1c3"; }

.fa-file-exclamation:before {
  content: "\f31a"; }

.fa-file-export:before {
  content: "\f56e"; }

.fa-file-image:before {
  content: "\f1c5"; }

.fa-file-import:before {
  content: "\f56f"; }

.fa-file-invoice:before {
  content: "\f570"; }

.fa-file-invoice-dollar:before {
  content: "\f571"; }

.fa-file-medical:before {
  content: "\f477"; }

.fa-file-medical-alt:before {
  content: "\f478"; }

.fa-file-minus:before {
  content: "\f318"; }

.fa-file-pdf:before {
  content: "\f1c1"; }

.fa-file-plus:before {
  content: "\f319"; }

.fa-file-powerpoint:before {
  content: "\f1c4"; }

.fa-file-prescription:before {
  content: "\f572"; }

.fa-file-signature:before {
  content: "\f573"; }

.fa-file-times:before {
  content: "\f317"; }

.fa-file-upload:before {
  content: "\f574"; }

.fa-file-video:before {
  content: "\f1c8"; }

.fa-file-word:before {
  content: "\f1c2"; }

.fa-fill:before {
  content: "\f575"; }

.fa-fill-drip:before {
  content: "\f576"; }

.fa-film:before {
  content: "\f008"; }

.fa-film-alt:before {
  content: "\f3a0"; }

.fa-filter:before {
  content: "\f0b0"; }

.fa-fingerprint:before {
  content: "\f577"; }

.fa-fire:before {
  content: "\f06d"; }

.fa-fire-extinguisher:before {
  content: "\f134"; }

.fa-firefox:before {
  content: "\f269"; }

.fa-first-aid:before {
  content: "\f479"; }

.fa-first-order:before {
  content: "\f2b0"; }

.fa-first-order-alt:before {
  content: "\f50a"; }

.fa-firstdraft:before {
  content: "\f3a1"; }

.fa-fish:before {
  content: "\f578"; }

.fa-flag:before {
  content: "\f024"; }

.fa-flag-checkered:before {
  content: "\f11e"; }

.fa-flask:before {
  content: "\f0c3"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-flipboard:before {
  content: "\f44d"; }

.fa-flushed:before {
  content: "\f579"; }

.fa-fly:before {
  content: "\f417"; }

.fa-folder:before {
  content: "\f07b"; }

.fa-folder-open:before {
  content: "\f07c"; }

.fa-font:before {
  content: "\f031"; }

.fa-font-awesome:before {
  content: "\f2b4"; }

.fa-font-awesome-alt:before {
  content: "\f35c"; }

.fa-font-awesome-flag:before {
  content: "\f425"; }

.fa-font-awesome-logo-full:before {
  content: "\f4e6"; }

.fa-fonticons:before {
  content: "\f280"; }

.fa-fonticons-fi:before {
  content: "\f3a2"; }

.fa-football-ball:before {
  content: "\f44e"; }

.fa-football-helmet:before {
  content: "\f44f"; }

.fa-forklift:before {
  content: "\f47a"; }

.fa-fort-awesome:before {
  content: "\f286"; }

.fa-fort-awesome-alt:before {
  content: "\f3a3"; }

.fa-forumbee:before {
  content: "\f211"; }

.fa-forward:before {
  content: "\f04e"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-fragile:before {
  content: "\f4bb"; }

.fa-free-code-camp:before {
  content: "\f2c5"; }

.fa-freebsd:before {
  content: "\f3a4"; }

.fa-frog:before {
  content: "\f52e"; }

.fa-frown:before {
  content: "\f119"; }

.fa-frown-open:before {
  content: "\f57a"; }

.fa-fulcrum:before {
  content: "\f50b"; }

.fa-futbol:before {
  content: "\f1e3"; }

.fa-galactic-republic:before {
  content: "\f50c"; }

.fa-galactic-senate:before {
  content: "\f50d"; }

.fa-gamepad:before {
  content: "\f11b"; }

.fa-gas-pump:before {
  content: "\f52f"; }

.fa-gavel:before {
  content: "\f0e3"; }

.fa-gem:before {
  content: "\f3a5"; }

.fa-genderless:before {
  content: "\f22d"; }

.fa-get-pocket:before {
  content: "\f265"; }

.fa-gg:before {
  content: "\f260"; }

.fa-gg-circle:before {
  content: "\f261"; }

.fa-gift:before {
  content: "\f06b"; }

.fa-git:before {
  content: "\f1d3"; }

.fa-git-square:before {
  content: "\f1d2"; }

.fa-github:before {
  content: "\f09b"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-gitkraken:before {
  content: "\f3a6"; }

.fa-gitlab:before {
  content: "\f296"; }

.fa-gitter:before {
  content: "\f426"; }

.fa-glass-martini:before {
  content: "\f000"; }

.fa-glass-martini-alt:before {
  content: "\f57b"; }

.fa-glasses:before {
  content: "\f530"; }

.fa-glide:before {
  content: "\f2a5"; }

.fa-glide-g:before {
  content: "\f2a6"; }

.fa-globe:before {
  content: "\f0ac"; }

.fa-globe-africa:before {
  content: "\f57c"; }

.fa-globe-americas:before {
  content: "\f57d"; }

.fa-globe-asia:before {
  content: "\f57e"; }

.fa-gofore:before {
  content: "\f3a7"; }

.fa-golf-ball:before {
  content: "\f450"; }

.fa-golf-club:before {
  content: "\f451"; }

.fa-goodreads:before {
  content: "\f3a8"; }

.fa-goodreads-g:before {
  content: "\f3a9"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-google-drive:before {
  content: "\f3aa"; }

.fa-google-play:before {
  content: "\f3ab"; }

.fa-google-plus:before {
  content: "\f2b3"; }

.fa-google-plus-g:before {
  content: "\f0d5"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-wallet:before {
  content: "\f1ee"; }

.fa-graduation-cap:before {
  content: "\f19d"; }

.fa-gratipay:before {
  content: "\f184"; }

.fa-grav:before {
  content: "\f2d6"; }

.fa-greater-than:before {
  content: "\f531"; }

.fa-greater-than-equal:before {
  content: "\f532"; }

.fa-grimace:before {
  content: "\f57f"; }

.fa-grin:before {
  content: "\f580"; }

.fa-grin-alt:before {
  content: "\f581"; }

.fa-grin-beam:before {
  content: "\f582"; }

.fa-grin-beam-sweat:before {
  content: "\f583"; }

.fa-grin-hearts:before {
  content: "\f584"; }

.fa-grin-squint:before {
  content: "\f585"; }

.fa-grin-squint-tears:before {
  content: "\f586"; }

.fa-grin-stars:before {
  content: "\f587"; }

.fa-grin-tears:before {
  content: "\f588"; }

.fa-grin-tongue:before {
  content: "\f589"; }

.fa-grin-tongue-squint:before {
  content: "\f58a"; }

.fa-grin-tongue-wink:before {
  content: "\f58b"; }

.fa-grin-wink:before {
  content: "\f58c"; }

.fa-grip-horizontal:before {
  content: "\f58d"; }

.fa-grip-vertical:before {
  content: "\f58e"; }

.fa-gripfire:before {
  content: "\f3ac"; }

.fa-grunt:before {
  content: "\f3ad"; }

.fa-gulp:before {
  content: "\f3ae"; }

.fa-h-square:before {
  content: "\f0fd"; }

.fa-h1:before {
  content: "\f313"; }

.fa-h2:before {
  content: "\f314"; }

.fa-h3:before {
  content: "\f315"; }

.fa-hacker-news:before {
  content: "\f1d4"; }

.fa-hacker-news-square:before {
  content: "\f3af"; }

.fa-hand-heart:before {
  content: "\f4bc"; }

.fa-hand-holding:before {
  content: "\f4bd"; }

.fa-hand-holding-box:before {
  content: "\f47b"; }

.fa-hand-holding-heart:before {
  content: "\f4be"; }

.fa-hand-holding-seedling:before {
  content: "\f4bf"; }

.fa-hand-holding-usd:before {
  content: "\f4c0"; }

.fa-hand-holding-water:before {
  content: "\f4c1"; }

.fa-hand-lizard:before {
  content: "\f258"; }

.fa-hand-paper:before {
  content: "\f256"; }

.fa-hand-peace:before {
  content: "\f25b"; }

.fa-hand-point-down:before {
  content: "\f0a7"; }

.fa-hand-point-left:before {
  content: "\f0a5"; }

.fa-hand-point-right:before {
  content: "\f0a4"; }

.fa-hand-point-up:before {
  content: "\f0a6"; }

.fa-hand-pointer:before {
  content: "\f25a"; }

.fa-hand-receiving:before {
  content: "\f47c"; }

.fa-hand-rock:before {
  content: "\f255"; }

.fa-hand-scissors:before {
  content: "\f257"; }

.fa-hand-spock:before {
  content: "\f259"; }

.fa-hands:before {
  content: "\f4c2"; }

.fa-hands-heart:before {
  content: "\f4c3"; }

.fa-hands-helping:before {
  content: "\f4c4"; }

.fa-hands-usd:before {
  content: "\f4c5"; }

.fa-handshake:before {
  content: "\f2b5"; }

.fa-handshake-alt:before {
  content: "\f4c6"; }

.fa-hashtag:before {
  content: "\f292"; }

.fa-hdd:before {
  content: "\f0a0"; }

.fa-heading:before {
  content: "\f1dc"; }

.fa-headphones:before {
  content: "\f025"; }

.fa-headphones-alt:before {
  content: "\f58f"; }

.fa-headset:before {
  content: "\f590"; }

.fa-heart:before {
  content: "\f004"; }

.fa-heart-circle:before {
  content: "\f4c7"; }

.fa-heart-square:before {
  content: "\f4c8"; }

.fa-heartbeat:before {
  content: "\f21e"; }

.fa-helicopter:before {
  content: "\f533"; }

.fa-hexagon:before {
  content: "\f312"; }

.fa-highlighter:before {
  content: "\f591"; }

.fa-hips:before {
  content: "\f452"; }

.fa-hire-a-helper:before {
  content: "\f3b0"; }

.fa-history:before {
  content: "\f1da"; }

.fa-hockey-puck:before {
  content: "\f453"; }

.fa-hockey-sticks:before {
  content: "\f454"; }

.fa-home:before {
  content: "\f015"; }

.fa-home-heart:before {
  content: "\f4c9"; }

.fa-hooli:before {
  content: "\f427"; }

.fa-hornbill:before {
  content: "\f592"; }

.fa-hospital:before {
  content: "\f0f8"; }

.fa-hospital-alt:before {
  content: "\f47d"; }

.fa-hospital-symbol:before {
  content: "\f47e"; }

.fa-hot-tub:before {
  content: "\f593"; }

.fa-hotel:before {
  content: "\f594"; }

.fa-hotjar:before {
  content: "\f3b1"; }

.fa-hourglass:before {
  content: "\f254"; }

.fa-hourglass-end:before {
  content: "\f253"; }

.fa-hourglass-half:before {
  content: "\f252"; }

.fa-hourglass-start:before {
  content: "\f251"; }

.fa-houzz:before {
  content: "\f27c"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-hubspot:before {
  content: "\f3b2"; }

.fa-i-cursor:before {
  content: "\f246"; }

.fa-id-badge:before {
  content: "\f2c1"; }

.fa-id-card:before {
  content: "\f2c2"; }

.fa-id-card-alt:before {
  content: "\f47f"; }

.fa-image:before {
  content: "\f03e"; }

.fa-images:before {
  content: "\f302"; }

.fa-imdb:before {
  content: "\f2d8"; }

.fa-inbox:before {
  content: "\f01c"; }

.fa-inbox-in:before {
  content: "\f310"; }

.fa-inbox-out:before {
  content: "\f311"; }

.fa-indent:before {
  content: "\f03c"; }

.fa-industry:before {
  content: "\f275"; }

.fa-industry-alt:before {
  content: "\f3b3"; }

.fa-infinity:before {
  content: "\f534"; }

.fa-info:before {
  content: "\f129"; }

.fa-info-circle:before {
  content: "\f05a"; }

.fa-info-square:before {
  content: "\f30f"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-internet-explorer:before {
  content: "\f26b"; }

.fa-inventory:before {
  content: "\f480"; }

.fa-ioxhost:before {
  content: "\f208"; }

.fa-italic:before {
  content: "\f033"; }

.fa-itunes:before {
  content: "\f3b4"; }

.fa-itunes-note:before {
  content: "\f3b5"; }

.fa-jack-o-lantern:before {
  content: "\f30e"; }

.fa-java:before {
  content: "\f4e4"; }

.fa-jedi-order:before {
  content: "\f50e"; }

.fa-jenkins:before {
  content: "\f3b6"; }

.fa-joget:before {
  content: "\f3b7"; }

.fa-joint:before {
  content: "\f595"; }

.fa-joomla:before {
  content: "\f1aa"; }

.fa-js:before {
  content: "\f3b8"; }

.fa-js-square:before {
  content: "\f3b9"; }

.fa-jsfiddle:before {
  content: "\f1cc"; }

.fa-key:before {
  content: "\f084"; }

.fa-keybase:before {
  content: "\f4f5"; }

.fa-keyboard:before {
  content: "\f11c"; }

.fa-keycdn:before {
  content: "\f3ba"; }

.fa-kickstarter:before {
  content: "\f3bb"; }

.fa-kickstarter-k:before {
  content: "\f3bc"; }

.fa-kiss:before {
  content: "\f596"; }

.fa-kiss-beam:before {
  content: "\f597"; }

.fa-kiss-wink-heart:before {
  content: "\f598"; }

.fa-kiwi-bird:before {
  content: "\f535"; }

.fa-korvue:before {
  content: "\f42f"; }

.fa-lamp:before {
  content: "\f4ca"; }

.fa-language:before {
  content: "\f1ab"; }

.fa-laptop:before {
  content: "\f109"; }

.fa-laravel:before {
  content: "\f3bd"; }

.fa-lastfm:before {
  content: "\f202"; }

.fa-lastfm-square:before {
  content: "\f203"; }

.fa-laugh:before {
  content: "\f599"; }

.fa-laugh-beam:before {
  content: "\f59a"; }

.fa-laugh-squint:before {
  content: "\f59b"; }

.fa-laugh-wink:before {
  content: "\f59c"; }

.fa-leaf:before {
  content: "\f06c"; }

.fa-leaf-heart:before {
  content: "\f4cb"; }

.fa-leanpub:before {
  content: "\f212"; }

.fa-lemon:before {
  content: "\f094"; }

.fa-less:before {
  content: "\f41d"; }

.fa-less-than:before {
  content: "\f536"; }

.fa-less-than-equal:before {
  content: "\f537"; }

.fa-level-down:before {
  content: "\f149"; }

.fa-level-down-alt:before {
  content: "\f3be"; }

.fa-level-up:before {
  content: "\f148"; }

.fa-level-up-alt:before {
  content: "\f3bf"; }

.fa-life-ring:before {
  content: "\f1cd"; }

.fa-lightbulb:before {
  content: "\f0eb"; }

.fa-line:before {
  content: "\f3c0"; }

.fa-link:before {
  content: "\f0c1"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-linode:before {
  content: "\f2b8"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-lira-sign:before {
  content: "\f195"; }

.fa-list:before {
  content: "\f03a"; }

.fa-list-alt:before {
  content: "\f022"; }

.fa-list-ol:before {
  content: "\f0cb"; }

.fa-list-ul:before {
  content: "\f0ca"; }

.fa-location-arrow:before {
  content: "\f124"; }

.fa-lock:before {
  content: "\f023"; }

.fa-lock-alt:before {
  content: "\f30d"; }

.fa-lock-open:before {
  content: "\f3c1"; }

.fa-lock-open-alt:before {
  content: "\f3c2"; }

.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-long-arrow-down:before {
  content: "\f175"; }

.fa-long-arrow-left:before {
  content: "\f177"; }

.fa-long-arrow-right:before {
  content: "\f178"; }

.fa-long-arrow-up:before {
  content: "\f176"; }

.fa-loveseat:before {
  content: "\f4cc"; }

.fa-low-vision:before {
  content: "\f2a8"; }

.fa-luchador:before {
  content: "\f455"; }

.fa-luggage-cart:before {
  content: "\f59d"; }

.fa-lyft:before {
  content: "\f3c3"; }

.fa-magento:before {
  content: "\f3c4"; }

.fa-magic:before {
  content: "\f0d0"; }

.fa-magnet:before {
  content: "\f076"; }

.fa-mailchimp:before {
  content: "\f59e"; }

.fa-male:before {
  content: "\f183"; }

.fa-mandalorian:before {
  content: "\f50f"; }

.fa-map:before {
  content: "\f279"; }

.fa-map-marked:before {
  content: "\f59f"; }

.fa-map-marked-alt:before {
  content: "\f5a0"; }

.fa-map-marker:before {
  content: "\f041"; }

.fa-map-marker-alt:before {
  content: "\f3c5"; }

.fa-map-pin:before {
  content: "\f276"; }

.fa-map-signs:before {
  content: "\f277"; }

.fa-marker:before {
  content: "\f5a1"; }

.fa-mars:before {
  content: "\f222"; }

.fa-mars-double:before {
  content: "\f227"; }

.fa-mars-stroke:before {
  content: "\f229"; }

.fa-mars-stroke-h:before {
  content: "\f22b"; }

.fa-mars-stroke-v:before {
  content: "\f22a"; }

.fa-mastodon:before {
  content: "\f4f6"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-medal:before {
  content: "\f5a2"; }

.fa-medapps:before {
  content: "\f3c6"; }

.fa-medium:before {
  content: "\f23a"; }

.fa-medium-m:before {
  content: "\f3c7"; }

.fa-medkit:before {
  content: "\f0fa"; }

.fa-medrt:before {
  content: "\f3c8"; }

.fa-meetup:before {
  content: "\f2e0"; }

.fa-megaport:before {
  content: "\f5a3"; }

.fa-meh:before {
  content: "\f11a"; }

.fa-meh-blank:before {
  content: "\f5a4"; }

.fa-meh-rolling-eyes:before {
  content: "\f5a5"; }

.fa-memory:before {
  content: "\f538"; }

.fa-mercury:before {
  content: "\f223"; }

.fa-microchip:before {
  content: "\f2db"; }

.fa-microphone:before {
  content: "\f130"; }

.fa-microphone-alt:before {
  content: "\f3c9"; }

.fa-microphone-alt-slash:before {
  content: "\f539"; }

.fa-microphone-slash:before {
  content: "\f131"; }

.fa-microsoft:before {
  content: "\f3ca"; }

.fa-minus:before {
  content: "\f068"; }

.fa-minus-circle:before {
  content: "\f056"; }

.fa-minus-hexagon:before {
  content: "\f307"; }

.fa-minus-octagon:before {
  content: "\f308"; }

.fa-minus-square:before {
  content: "\f146"; }

.fa-mix:before {
  content: "\f3cb"; }

.fa-mixcloud:before {
  content: "\f289"; }

.fa-mizuni:before {
  content: "\f3cc"; }

.fa-mobile:before {
  content: "\f10b"; }

.fa-mobile-alt:before {
  content: "\f3cd"; }

.fa-mobile-android:before {
  content: "\f3ce"; }

.fa-mobile-android-alt:before {
  content: "\f3cf"; }

.fa-modx:before {
  content: "\f285"; }

.fa-monero:before {
  content: "\f3d0"; }

.fa-money-bill:before {
  content: "\f0d6"; }

.fa-money-bill-alt:before {
  content: "\f3d1"; }

.fa-money-bill-wave:before {
  content: "\f53a"; }

.fa-money-bill-wave-alt:before {
  content: "\f53b"; }

.fa-money-check:before {
  content: "\f53c"; }

.fa-money-check-alt:before {
  content: "\f53d"; }

.fa-monument:before {
  content: "\f5a6"; }

.fa-moon:before {
  content: "\f186"; }

.fa-mortar-pestle:before {
  content: "\f5a7"; }

.fa-motorcycle:before {
  content: "\f21c"; }

.fa-mouse-pointer:before {
  content: "\f245"; }

.fa-music:before {
  content: "\f001"; }

.fa-napster:before {
  content: "\f3d2"; }

.fa-neuter:before {
  content: "\f22c"; }

.fa-newspaper:before {
  content: "\f1ea"; }

.fa-nimblr:before {
  content: "\f5a8"; }

.fa-nintendo-switch:before {
  content: "\f418"; }

.fa-node:before {
  content: "\f419"; }

.fa-node-js:before {
  content: "\f3d3"; }

.fa-not-equal:before {
  content: "\f53e"; }

.fa-notes-medical:before {
  content: "\f481"; }

.fa-npm:before {
  content: "\f3d4"; }

.fa-ns8:before {
  content: "\f3d5"; }

.fa-nutritionix:before {
  content: "\f3d6"; }

.fa-object-group:before {
  content: "\f247"; }

.fa-object-ungroup:before {
  content: "\f248"; }

.fa-octagon:before {
  content: "\f306"; }

.fa-odnoklassniki:before {
  content: "\f263"; }

.fa-odnoklassniki-square:before {
  content: "\f264"; }

.fa-old-republic:before {
  content: "\f510"; }

.fa-opencart:before {
  content: "\f23d"; }

.fa-openid:before {
  content: "\f19b"; }

.fa-opera:before {
  content: "\f26a"; }

.fa-optin-monster:before {
  content: "\f23c"; }

.fa-osi:before {
  content: "\f41a"; }

.fa-outdent:before {
  content: "\f03b"; }

.fa-page4:before {
  content: "\f3d7"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-paint-brush:before {
  content: "\f1fc"; }

.fa-paint-brush-alt:before {
  content: "\f5a9"; }

.fa-paint-roller:before {
  content: "\f5aa"; }

.fa-palette:before {
  content: "\f53f"; }

.fa-palfed:before {
  content: "\f3d8"; }

.fa-pallet:before {
  content: "\f482"; }

.fa-pallet-alt:before {
  content: "\f483"; }

.fa-paper-plane:before {
  content: "\f1d8"; }

.fa-paperclip:before {
  content: "\f0c6"; }

.fa-parachute-box:before {
  content: "\f4cd"; }

.fa-paragraph:before {
  content: "\f1dd"; }

.fa-parking:before {
  content: "\f540"; }

.fa-passport:before {
  content: "\f5ab"; }

.fa-paste:before {
  content: "\f0ea"; }

.fa-patreon:before {
  content: "\f3d9"; }

.fa-pause:before {
  content: "\f04c"; }

.fa-pause-circle:before {
  content: "\f28b"; }

.fa-paw:before {
  content: "\f1b0"; }

.fa-paypal:before {
  content: "\f1ed"; }

.fa-pen:before {
  content: "\f304"; }

.fa-pen-alt:before {
  content: "\f305"; }

.fa-pen-fancy:before {
  content: "\f5ac"; }

.fa-pen-nib:before {
  content: "\f5ad"; }

.fa-pen-square:before {
  content: "\f14b"; }

.fa-pencil:before {
  content: "\f040"; }

.fa-pencil-alt:before {
  content: "\f303"; }

.fa-pencil-ruler:before {
  content: "\f5ae"; }

.fa-pennant:before {
  content: "\f456"; }

.fa-people-carry:before {
  content: "\f4ce"; }

.fa-percent:before {
  content: "\f295"; }

.fa-percentage:before {
  content: "\f541"; }

.fa-periscope:before {
  content: "\f3da"; }

.fa-person-carry:before {
  content: "\f4cf"; }

.fa-person-dolly:before {
  content: "\f4d0"; }

.fa-person-dolly-empty:before {
  content: "\f4d1"; }

.fa-phabricator:before {
  content: "\f3db"; }

.fa-phoenix-framework:before {
  content: "\f3dc"; }

.fa-phoenix-squadron:before {
  content: "\f511"; }

.fa-phone:before {
  content: "\f095"; }

.fa-phone-plus:before {
  content: "\f4d2"; }

.fa-phone-slash:before {
  content: "\f3dd"; }

.fa-phone-square:before {
  content: "\f098"; }

.fa-phone-volume:before {
  content: "\f2a0"; }

.fa-php:before {
  content: "\f457"; }

.fa-pied-piper:before {
  content: "\f2ae"; }

.fa-pied-piper-alt:before {
  content: "\f1a8"; }

.fa-pied-piper-hat:before {
  content: "\f4e5"; }

.fa-pied-piper-pp:before {
  content: "\f1a7"; }

.fa-piggy-bank:before {
  content: "\f4d3"; }

.fa-pills:before {
  content: "\f484"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-p:before {
  content: "\f231"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-plane:before {
  content: "\f072"; }

.fa-plane-alt:before {
  content: "\f3de"; }

.fa-plane-arrival:before {
  content: "\f5af"; }

.fa-plane-departure:before {
  content: "\f5b0"; }

.fa-play:before {
  content: "\f04b"; }

.fa-play-circle:before {
  content: "\f144"; }

.fa-playstation:before {
  content: "\f3df"; }

.fa-plug:before {
  content: "\f1e6"; }

.fa-plus:before {
  content: "\f067"; }

.fa-plus-circle:before {
  content: "\f055"; }

.fa-plus-hexagon:before {
  content: "\f300"; }

.fa-plus-octagon:before {
  content: "\f301"; }

.fa-plus-square:before {
  content: "\f0fe"; }

.fa-podcast:before {
  content: "\f2ce"; }

.fa-poo:before {
  content: "\f2fe"; }

.fa-portrait:before {
  content: "\f3e0"; }

.fa-pound-sign:before {
  content: "\f154"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-prescription:before {
  content: "\f5b1"; }

.fa-prescription-bottle:before {
  content: "\f485"; }

.fa-prescription-bottle-alt:before {
  content: "\f486"; }

.fa-print:before {
  content: "\f02f"; }

.fa-procedures:before {
  content: "\f487"; }

.fa-product-hunt:before {
  content: "\f288"; }

.fa-project-diagram:before {
  content: "\f542"; }

.fa-pushed:before {
  content: "\f3e1"; }

.fa-puzzle-piece:before {
  content: "\f12e"; }

.fa-python:before {
  content: "\f3e2"; }

.fa-qq:before {
  content: "\f1d6"; }

.fa-qrcode:before {
  content: "\f029"; }

.fa-question:before {
  content: "\f128"; }

.fa-question-circle:before {
  content: "\f059"; }

.fa-question-square:before {
  content: "\f2fd"; }

.fa-quidditch:before {
  content: "\f458"; }

.fa-quinscape:before {
  content: "\f459"; }

.fa-quora:before {
  content: "\f2c4"; }

.fa-quote-left:before {
  content: "\f10d"; }

.fa-quote-right:before {
  content: "\f10e"; }

.fa-r-project:before {
  content: "\f4f7"; }

.fa-racquet:before {
  content: "\f45a"; }

.fa-ramp-loading:before {
  content: "\f4d4"; }

.fa-random:before {
  content: "\f074"; }

.fa-ravelry:before {
  content: "\f2d9"; }

.fa-react:before {
  content: "\f41b"; }

.fa-readme:before {
  content: "\f4d5"; }

.fa-rebel:before {
  content: "\f1d0"; }

.fa-receipt:before {
  content: "\f543"; }

.fa-rectangle-landscape:before {
  content: "\f2fa"; }

.fa-rectangle-portrait:before {
  content: "\f2fb"; }

.fa-rectangle-wide:before {
  content: "\f2fc"; }

.fa-recycle:before {
  content: "\f1b8"; }

.fa-red-river:before {
  content: "\f3e3"; }

.fa-reddit:before {
  content: "\f1a1"; }

.fa-reddit-alien:before {
  content: "\f281"; }

.fa-reddit-square:before {
  content: "\f1a2"; }

.fa-redo:before {
  content: "\f01e"; }

.fa-redo-alt:before {
  content: "\f2f9"; }

.fa-registered:before {
  content: "\f25d"; }

.fa-rendact:before {
  content: "\f3e4"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-repeat:before {
  content: "\f363"; }

.fa-repeat-1:before {
  content: "\f365"; }

.fa-repeat-1-alt:before {
  content: "\f366"; }

.fa-repeat-alt:before {
  content: "\f364"; }

.fa-reply:before {
  content: "\f3e5"; }

.fa-reply-all:before {
  content: "\f122"; }

.fa-replyd:before {
  content: "\f3e6"; }

.fa-researchgate:before {
  content: "\f4f8"; }

.fa-resolving:before {
  content: "\f3e7"; }

.fa-retweet:before {
  content: "\f079"; }

.fa-retweet-alt:before {
  content: "\f361"; }

.fa-rev:before {
  content: "\f5b2"; }

.fa-ribbon:before {
  content: "\f4d6"; }

.fa-road:before {
  content: "\f018"; }

.fa-robot:before {
  content: "\f544"; }

.fa-rocket:before {
  content: "\f135"; }

.fa-rocketchat:before {
  content: "\f3e8"; }

.fa-rockrms:before {
  content: "\f3e9"; }

.fa-route:before {
  content: "\f4d7"; }

.fa-rss:before {
  content: "\f09e"; }

.fa-rss-square:before {
  content: "\f143"; }

.fa-ruble-sign:before {
  content: "\f158"; }

.fa-ruler:before {
  content: "\f545"; }

.fa-ruler-combined:before {
  content: "\f546"; }

.fa-ruler-horizontal:before {
  content: "\f547"; }

.fa-ruler-vertical:before {
  content: "\f548"; }

.fa-rupee-sign:before {
  content: "\f156"; }

.fa-sad-cry:before {
  content: "\f5b3"; }

.fa-sad-tear:before {
  content: "\f5b4"; }

.fa-safari:before {
  content: "\f267"; }

.fa-sass:before {
  content: "\f41e"; }

.fa-save:before {
  content: "\f0c7"; }

.fa-scanner:before {
  content: "\f488"; }

.fa-scanner-keyboard:before {
  content: "\f489"; }

.fa-scanner-touchscreen:before {
  content: "\f48a"; }

.fa-schlix:before {
  content: "\f3ea"; }

.fa-school:before {
  content: "\f549"; }

.fa-screwdriver:before {
  content: "\f54a"; }

.fa-scribd:before {
  content: "\f28a"; }

.fa-scrubber:before {
  content: "\f2f8"; }

.fa-search:before {
  content: "\f002"; }

.fa-search-minus:before {
  content: "\f010"; }

.fa-search-plus:before {
  content: "\f00e"; }

.fa-searchengin:before {
  content: "\f3eb"; }

.fa-seedling:before {
  content: "\f4d8"; }

.fa-sellcast:before {
  content: "\f2da"; }

.fa-sellsy:before {
  content: "\f213"; }

.fa-server:before {
  content: "\f233"; }

.fa-servicestack:before {
  content: "\f3ec"; }

.fa-share:before {
  content: "\f064"; }

.fa-share-all:before {
  content: "\f367"; }

.fa-share-alt:before {
  content: "\f1e0"; }

.fa-share-alt-square:before {
  content: "\f1e1"; }

.fa-share-square:before {
  content: "\f14d"; }

.fa-shekel-sign:before {
  content: "\f20b"; }

.fa-shield:before {
  content: "\f132"; }

.fa-shield-alt:before {
  content: "\f3ed"; }

.fa-shield-check:before {
  content: "\f2f7"; }

.fa-ship:before {
  content: "\f21a"; }

.fa-shipping-fast:before {
  content: "\f48b"; }

.fa-shipping-timed:before {
  content: "\f48c"; }

.fa-shirtsinbulk:before {
  content: "\f214"; }

.fa-shoe-prints:before {
  content: "\f54b"; }

.fa-shopping-bag:before {
  content: "\f290"; }

.fa-shopping-basket:before {
  content: "\f291"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-shopware:before {
  content: "\f5b5"; }

.fa-shower:before {
  content: "\f2cc"; }

.fa-shuttle-van:before {
  content: "\f5b6"; }

.fa-shuttlecock:before {
  content: "\f45b"; }

.fa-sign:before {
  content: "\f4d9"; }

.fa-sign-in:before {
  content: "\f090"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-sign-language:before {
  content: "\f2a7"; }

.fa-sign-out:before {
  content: "\f08b"; }

.fa-sign-out-alt:before {
  content: "\f2f5"; }

.fa-signal:before {
  content: "\f012"; }

.fa-signature:before {
  content: "\f5b7"; }

.fa-simplybuilt:before {
  content: "\f215"; }

.fa-sistrix:before {
  content: "\f3ee"; }

.fa-sitemap:before {
  content: "\f0e8"; }

.fa-sith:before {
  content: "\f512"; }

.fa-skull:before {
  content: "\f54c"; }

.fa-skyatlas:before {
  content: "\f216"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-slack:before {
  content: "\f198"; }

.fa-slack-hash:before {
  content: "\f3ef"; }

.fa-sliders-h:before {
  content: "\f1de"; }

.fa-sliders-h-square:before {
  content: "\f3f0"; }

.fa-sliders-v:before {
  content: "\f3f1"; }

.fa-sliders-v-square:before {
  content: "\f3f2"; }

.fa-slideshare:before {
  content: "\f1e7"; }

.fa-smile:before {
  content: "\f118"; }

.fa-smile-beam:before {
  content: "\f5b8"; }

.fa-smile-plus:before {
  content: "\f5b9"; }

.fa-smile-wink:before {
  content: "\f4da"; }

.fa-smoking:before {
  content: "\f48d"; }

.fa-smoking-ban:before {
  content: "\f54d"; }

.fa-snapchat:before {
  content: "\f2ab"; }

.fa-snapchat-ghost:before {
  content: "\f2ac"; }

.fa-snapchat-square:before {
  content: "\f2ad"; }

.fa-snowflake:before {
  content: "\f2dc"; }

.fa-solar-panel:before {
  content: "\f5ba"; }

.fa-sort:before {
  content: "\f0dc"; }

.fa-sort-alpha-down:before {
  content: "\f15d"; }

.fa-sort-alpha-up:before {
  content: "\f15e"; }

.fa-sort-amount-down:before {
  content: "\f160"; }

.fa-sort-amount-up:before {
  content: "\f161"; }

.fa-sort-down:before {
  content: "\f0dd"; }

.fa-sort-numeric-down:before {
  content: "\f162"; }

.fa-sort-numeric-up:before {
  content: "\f163"; }

.fa-sort-up:before {
  content: "\f0de"; }

.fa-soundcloud:before {
  content: "\f1be"; }

.fa-spa:before {
  content: "\f5bb"; }

.fa-space-shuttle:before {
  content: "\f197"; }

.fa-spade:before {
  content: "\f2f4"; }

.fa-speakap:before {
  content: "\f3f3"; }

.fa-spinner:before {
  content: "\f110"; }

.fa-spinner-third:before {
  content: "\f3f4"; }

.fa-splotch:before {
  content: "\f5bc"; }

.fa-spotify:before {
  content: "\f1bc"; }

.fa-spray-can:before {
  content: "\f5bd"; }

.fa-square:before {
  content: "\f0c8"; }

.fa-square-full:before {
  content: "\f45c"; }

.fa-squarespace:before {
  content: "\f5be"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-stamp:before {
  content: "\f5bf"; }

.fa-star:before {
  content: "\f005"; }

.fa-star-exclamation:before {
  content: "\f2f3"; }

.fa-star-half:before {
  content: "\f089"; }

.fa-star-half-alt:before {
  content: "\f5c0"; }

.fa-staylinked:before {
  content: "\f3f5"; }

.fa-steam:before {
  content: "\f1b6"; }

.fa-steam-square:before {
  content: "\f1b7"; }

.fa-steam-symbol:before {
  content: "\f3f6"; }

.fa-step-backward:before {
  content: "\f048"; }

.fa-step-forward:before {
  content: "\f051"; }

.fa-stethoscope:before {
  content: "\f0f1"; }

.fa-sticker-mule:before {
  content: "\f3f7"; }

.fa-sticky-note:before {
  content: "\f249"; }

.fa-stop:before {
  content: "\f04d"; }

.fa-stop-circle:before {
  content: "\f28d"; }

.fa-stopwatch:before {
  content: "\f2f2"; }

.fa-store:before {
  content: "\f54e"; }

.fa-store-alt:before {
  content: "\f54f"; }

.fa-strava:before {
  content: "\f428"; }

.fa-stream:before {
  content: "\f550"; }

.fa-street-view:before {
  content: "\f21d"; }

.fa-strikethrough:before {
  content: "\f0cc"; }

.fa-stripe:before {
  content: "\f429"; }

.fa-stripe-s:before {
  content: "\f42a"; }

.fa-stroopwafel:before {
  content: "\f551"; }

.fa-studiovinari:before {
  content: "\f3f8"; }

.fa-stumbleupon:before {
  content: "\f1a4"; }

.fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.fa-subscript:before {
  content: "\f12c"; }

.fa-subway:before {
  content: "\f239"; }

.fa-suitcase:before {
  content: "\f0f2"; }

.fa-suitcase-rolling:before {
  content: "\f5c1"; }

.fa-sun:before {
  content: "\f185"; }

.fa-superpowers:before {
  content: "\f2dd"; }

.fa-superscript:before {
  content: "\f12b"; }

.fa-supple:before {
  content: "\f3f9"; }

.fa-surprise:before {
  content: "\f5c2"; }

.fa-swatchbook:before {
  content: "\f5c3"; }

.fa-swimmer:before {
  content: "\f5c4"; }

.fa-swimming-pool:before {
  content: "\f5c5"; }

.fa-sync:before {
  content: "\f021"; }

.fa-sync-alt:before {
  content: "\f2f1"; }

.fa-syringe:before {
  content: "\f48e"; }

.fa-table:before {
  content: "\f0ce"; }

.fa-table-tennis:before {
  content: "\f45d"; }

.fa-tablet:before {
  content: "\f10a"; }

.fa-tablet-alt:before {
  content: "\f3fa"; }

.fa-tablet-android:before {
  content: "\f3fb"; }

.fa-tablet-android-alt:before {
  content: "\f3fc"; }

.fa-tablet-rugged:before {
  content: "\f48f"; }

.fa-tablets:before {
  content: "\f490"; }

.fa-tachometer:before {
  content: "\f0e4"; }

.fa-tachometer-alt:before {
  content: "\f3fd"; }

.fa-tag:before {
  content: "\f02b"; }

.fa-tags:before {
  content: "\f02c"; }

.fa-tape:before {
  content: "\f4db"; }

.fa-tasks:before {
  content: "\f0ae"; }

.fa-taxi:before {
  content: "\f1ba"; }

.fa-teamspeak:before {
  content: "\f4f9"; }

.fa-telegram:before {
  content: "\f2c6"; }

.fa-telegram-plane:before {
  content: "\f3fe"; }

.fa-tencent-weibo:before {
  content: "\f1d5"; }

.fa-tennis-ball:before {
  content: "\f45e"; }

.fa-terminal:before {
  content: "\f120"; }

.fa-text-height:before {
  content: "\f034"; }

.fa-text-width:before {
  content: "\f035"; }

.fa-th:before {
  content: "\f00a"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-th-list:before {
  content: "\f00b"; }

.fa-themeco:before {
  content: "\f5c6"; }

.fa-themeisle:before {
  content: "\f2b2"; }

.fa-thermometer:before {
  content: "\f491"; }

.fa-thermometer-empty:before {
  content: "\f2cb"; }

.fa-thermometer-full:before {
  content: "\f2c7"; }

.fa-thermometer-half:before {
  content: "\f2c9"; }

.fa-thermometer-quarter:before {
  content: "\f2ca"; }

.fa-thermometer-three-quarters:before {
  content: "\f2c8"; }

.fa-thumbs-down:before {
  content: "\f165"; }

.fa-thumbs-up:before {
  content: "\f164"; }

.fa-thumbtack:before {
  content: "\f08d"; }

.fa-ticket:before {
  content: "\f145"; }

.fa-ticket-alt:before {
  content: "\f3ff"; }

.fa-times:before {
  content: "\f00d"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-times-hexagon:before {
  content: "\f2ee"; }

.fa-times-octagon:before {
  content: "\f2f0"; }

.fa-times-square:before {
  content: "\f2d3"; }

.fa-tint:before {
  content: "\f043"; }

.fa-tint-slash:before {
  content: "\f5c7"; }

.fa-tired:before {
  content: "\f5c8"; }

.fa-toggle-off:before {
  content: "\f204"; }

.fa-toggle-on:before {
  content: "\f205"; }

.fa-toolbox:before {
  content: "\f552"; }

.fa-tooth:before {
  content: "\f5c9"; }

.fa-trade-federation:before {
  content: "\f513"; }

.fa-trademark:before {
  content: "\f25c"; }

.fa-train:before {
  content: "\f238"; }

.fa-transgender:before {
  content: "\f224"; }

.fa-transgender-alt:before {
  content: "\f225"; }

.fa-trash:before {
  content: "\f1f8"; }

.fa-trash-alt:before {
  content: "\f2ed"; }

.fa-tree:before {
  content: "\f1bb"; }

.fa-tree-alt:before {
  content: "\f400"; }

.fa-trello:before {
  content: "\f181"; }

.fa-triangle:before {
  content: "\f2ec"; }

.fa-tripadvisor:before {
  content: "\f262"; }

.fa-trophy:before {
  content: "\f091"; }

.fa-trophy-alt:before {
  content: "\f2eb"; }

.fa-truck:before {
  content: "\f0d1"; }

.fa-truck-container:before {
  content: "\f4dc"; }

.fa-truck-couch:before {
  content: "\f4dd"; }

.fa-truck-loading:before {
  content: "\f4de"; }

.fa-truck-moving:before {
  content: "\f4df"; }

.fa-truck-ramp:before {
  content: "\f4e0"; }

.fa-tshirt:before {
  content: "\f553"; }

.fa-tty:before {
  content: "\f1e4"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-tv:before {
  content: "\f26c"; }

.fa-tv-retro:before {
  content: "\f401"; }

.fa-twitch:before {
  content: "\f1e8"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-typo3:before {
  content: "\f42b"; }

.fa-uber:before {
  content: "\f402"; }

.fa-uikit:before {
  content: "\f403"; }

.fa-umbrella:before {
  content: "\f0e9"; }

.fa-umbrella-beach:before {
  content: "\f5ca"; }

.fa-underline:before {
  content: "\f0cd"; }

.fa-undo:before {
  content: "\f0e2"; }

.fa-undo-alt:before {
  content: "\f2ea"; }

.fa-uniregistry:before {
  content: "\f404"; }

.fa-universal-access:before {
  content: "\f29a"; }

.fa-university:before {
  content: "\f19c"; }

.fa-unlink:before {
  content: "\f127"; }

.fa-unlock:before {
  content: "\f09c"; }

.fa-unlock-alt:before {
  content: "\f13e"; }

.fa-untappd:before {
  content: "\f405"; }

.fa-upload:before {
  content: "\f093"; }

.fa-usb:before {
  content: "\f287"; }

.fa-usd-circle:before {
  content: "\f2e8"; }

.fa-usd-square:before {
  content: "\f2e9"; }

.fa-user:before {
  content: "\f007"; }

.fa-user-alt:before {
  content: "\f406"; }

.fa-user-alt-slash:before {
  content: "\f4fa"; }

.fa-user-astronaut:before {
  content: "\f4fb"; }

.fa-user-check:before {
  content: "\f4fc"; }

.fa-user-circle:before {
  content: "\f2bd"; }

.fa-user-clock:before {
  content: "\f4fd"; }

.fa-user-cog:before {
  content: "\f4fe"; }

.fa-user-edit:before {
  content: "\f4ff"; }

.fa-user-friends:before {
  content: "\f500"; }

.fa-user-graduate:before {
  content: "\f501"; }

.fa-user-lock:before {
  content: "\f502"; }

.fa-user-md:before {
  content: "\f0f0"; }

.fa-user-minus:before {
  content: "\f503"; }

.fa-user-ninja:before {
  content: "\f504"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-user-secret:before {
  content: "\f21b"; }

.fa-user-shield:before {
  content: "\f505"; }

.fa-user-slash:before {
  content: "\f506"; }

.fa-user-tag:before {
  content: "\f507"; }

.fa-user-tie:before {
  content: "\f508"; }

.fa-user-times:before {
  content: "\f235"; }

.fa-users:before {
  content: "\f0c0"; }

.fa-users-cog:before {
  content: "\f509"; }

.fa-ussunnah:before {
  content: "\f407"; }

.fa-utensil-fork:before {
  content: "\f2e3"; }

.fa-utensil-knife:before {
  content: "\f2e4"; }

.fa-utensil-spoon:before {
  content: "\f2e5"; }

.fa-utensils:before {
  content: "\f2e7"; }

.fa-utensils-alt:before {
  content: "\f2e6"; }

.fa-vaadin:before {
  content: "\f408"; }

.fa-vector-square:before {
  content: "\f5cb"; }

.fa-venus:before {
  content: "\f221"; }

.fa-venus-double:before {
  content: "\f226"; }

.fa-venus-mars:before {
  content: "\f228"; }

.fa-viacoin:before {
  content: "\f237"; }

.fa-viadeo:before {
  content: "\f2a9"; }

.fa-viadeo-square:before {
  content: "\f2aa"; }

.fa-vial:before {
  content: "\f492"; }

.fa-vials:before {
  content: "\f493"; }

.fa-viber:before {
  content: "\f409"; }

.fa-video:before {
  content: "\f03d"; }

.fa-video-plus:before {
  content: "\f4e1"; }

.fa-video-slash:before {
  content: "\f4e2"; }

.fa-vimeo:before {
  content: "\f40a"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-vimeo-v:before {
  content: "\f27d"; }

.fa-vine:before {
  content: "\f1ca"; }

.fa-vk:before {
  content: "\f189"; }

.fa-vnv:before {
  content: "\f40b"; }

.fa-volleyball-ball:before {
  content: "\f45f"; }

.fa-volume-down:before {
  content: "\f027"; }

.fa-volume-mute:before {
  content: "\f2e2"; }

.fa-volume-off:before {
  content: "\f026"; }

.fa-volume-up:before {
  content: "\f028"; }

.fa-vuejs:before {
  content: "\f41f"; }

.fa-walking:before {
  content: "\f554"; }

.fa-wallet:before {
  content: "\f555"; }

.fa-warehouse:before {
  content: "\f494"; }

.fa-warehouse-alt:before {
  content: "\f495"; }

.fa-watch:before {
  content: "\f2e1"; }

.fa-weebly:before {
  content: "\f5cc"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-weight:before {
  content: "\f496"; }

.fa-weight-hanging:before {
  content: "\f5cd"; }

.fa-weixin:before {
  content: "\f1d7"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-whatsapp-square:before {
  content: "\f40c"; }

.fa-wheelchair:before {
  content: "\f193"; }

.fa-whistle:before {
  content: "\f460"; }

.fa-whmcs:before {
  content: "\f40d"; }

.fa-wifi:before {
  content: "\f1eb"; }

.fa-wikipedia-w:before {
  content: "\f266"; }

.fa-window:before {
  content: "\f40e"; }

.fa-window-alt:before {
  content: "\f40f"; }

.fa-window-close:before {
  content: "\f410"; }

.fa-window-maximize:before {
  content: "\f2d0"; }

.fa-window-minimize:before {
  content: "\f2d1"; }

.fa-window-restore:before {
  content: "\f2d2"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-wine-glass:before {
  content: "\f4e3"; }

.fa-wine-glass-alt:before {
  content: "\f5ce"; }

.fa-wix:before {
  content: "\f5cf"; }

.fa-wolf-pack-battalion:before {
  content: "\f514"; }

.fa-won-sign:before {
  content: "\f159"; }

.fa-wordpress:before {
  content: "\f19a"; }

.fa-wordpress-simple:before {
  content: "\f411"; }

.fa-wpbeginner:before {
  content: "\f297"; }

.fa-wpexplorer:before {
  content: "\f2de"; }

.fa-wpforms:before {
  content: "\f298"; }

.fa-wrench:before {
  content: "\f0ad"; }

.fa-x-ray:before {
  content: "\f497"; }

.fa-xbox:before {
  content: "\f412"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-y-combinator:before {
  content: "\f23b"; }

.fa-yahoo:before {
  content: "\f19e"; }

.fa-yandex:before {
  content: "\f413"; }

.fa-yandex-international:before {
  content: "\f414"; }

.fa-yelp:before {
  content: "\f1e9"; }

.fa-yen-sign:before {
  content: "\f157"; }

.fa-yoast:before {
  content: "\f2b1"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-youtube-square:before {
  content: "\f431"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/*!
 * Font Awesome Pro 5.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url("../webfonts/fa-brands-400.eot");
  src: url("../webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-brands-400.woff2") format("woff2"), url("../webfonts/fa-brands-400.woff") format("woff"), url("../webfonts/fa-brands-400.ttf") format("truetype"), url("../webfonts/fa-brands-400.svg#fontawesome") format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }

/*!
 * Font Awesome Pro 5.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  src: url("../webfonts/fa-solid-900.eot");
  src: url("../webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.woff") format("woff"), url("../webfonts/fa-solid-900.ttf") format("truetype"), url("../webfonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

/*!
 * Font Awesome Pro 5.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url("../webfonts/fa-light-300.eot");
  src: url("../webfonts/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-light-300.woff2") format("woff2"), url("../webfonts/fa-light-300.woff") format("woff"), url("../webfonts/fa-light-300.ttf") format("truetype"), url("../webfonts/fa-light-300.svg#fontawesome") format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }

/*!
 * Font Awesome Pro 5.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  src: url("../webfonts/fa-regular-400.eot");
  src: url("../webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.woff") format("woff"), url("../webfonts/fa-regular-400.ttf") format("truetype"), url("../webfonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }

/************************************************

Stylesheet: Global Stylesheet

*************************************************/
/*********************
POSTS & CONTENT STYLES
*********************/
.page-title .vcard {
  border: 0px;
  padding: 0px; }

.byline {
  color: #999; }

.entry-content img {
  max-width: 100%;
  height: auto; }

.entry-content .alignleft, .entry-content img.alignleft {
  margin-right: 1.5em;
  display: inline;
  float: left; }

.entry-content .alignright, .entry-content img.alignright {
  margin-left: 1.5em;
  display: inline;
  float: right; }

.entry-content .aligncenter, .entry-content img.aligncenter {
  margin-right: auto;
  margin-left: auto;
  display: block;
  clear: both; }

.entry-content video, .entry-content object {
  max-width: 100%;
  height: auto; }

.entry-content pre {
  background: #eee;
  border: 1px solid #cecece;
  padding: 10px; }

.wp-caption {
  max-width: 100%;
  background: #eee;
  padding: 5px; }
  .wp-caption img {
    max-width: 100%;
    margin-bottom: 0;
    width: 100%; }
  .wp-caption p.wp-caption-text {
    font-size: 0.85em;
    margin: 4px 0 7px;
    text-align: center; }

.post-password-form input[type="submit"] {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #110c0c;
  color: #ffffff; }
  [data-whatinput='mouse'] .post-password-form input[type="submit"] {
    outline: 0; }
  .post-password-form input[type="submit"]:hover, .post-password-form input[type="submit"]:focus {
    background-color: #0e0a0a;
    color: #ffffff; }

/*********************
IMAGE GALLERY STYLES
*********************/
.gallery {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-right: -15px;
  margin-left: -15px; }

.gallery::before, .gallery::after {
  content: ' ';
  display: table; }

.gallery::after {
  clear: both; }

.gallery.gallery-columns-1 > dl {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .gallery.gallery-columns-1 > dl {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .gallery.gallery-columns-1 > dl:last-child:not(:first-child) {
    float: right; }

.gallery.gallery-columns-2 > dl {
  width: 50%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .gallery.gallery-columns-2 > dl {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .gallery.gallery-columns-2 > dl:last-child:not(:first-child) {
    float: right; }

.gallery.gallery-columns-3 > dl {
  width: 33.33333%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .gallery.gallery-columns-3 > dl {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .gallery.gallery-columns-3 > dl:last-child:not(:first-child) {
    float: right; }

.gallery.gallery-columns-4 > dl {
  width: 25%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .gallery.gallery-columns-4 > dl {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .gallery.gallery-columns-4 > dl:last-child:not(:first-child) {
    float: right; }

.gallery.gallery-columns-5 > dl {
  width: 20%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .gallery.gallery-columns-5 > dl {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .gallery.gallery-columns-5 > dl:last-child:not(:first-child) {
    float: right; }

.gallery.gallery-columns-6 > dl {
  width: 16.66667%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .gallery.gallery-columns-6 > dl {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .gallery.gallery-columns-6 > dl:last-child:not(:first-child) {
    float: right; }

.gallery.gallery-columns-7 > dl {
  width: 14.28571%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .gallery.gallery-columns-7 > dl {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .gallery.gallery-columns-7 > dl:last-child:not(:first-child) {
    float: right; }

.gallery.gallery-columns-8 > dl {
  width: 12.5%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .gallery.gallery-columns-8 > dl {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .gallery.gallery-columns-8 > dl:last-child:not(:first-child) {
    float: right; }

.gallery dl {
  width: 100%;
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem; }

.gallery dl:last-child:not(:first-child) {
  float: right; }

/*********************
PAGE NAVI STYLES
*********************/
.page-navigation {
  margin-top: 1rem; }

/*********************
COMMENT STYLES
*********************/
#comments .commentlist {
  margin-left: 0px; }

#respond ul {
  margin-left: 0px; }

.commentlist li {
  position: relative;
  clear: both;
  overflow: hidden;
  list-style-type: none;
  margin-bottom: 1.5em;
  padding: 0.7335em 10px; }
  .commentlist li:last-child {
    margin-bottom: 0; }
  .commentlist li ul.children {
    margin: 0; }

.commentlist li[class*=depth-] {
  margin-top: 1.1em; }

.commentlist li.depth-1 {
  margin-left: 0;
  margin-top: 0; }

.commentlist li:not(.depth-1) {
  margin-left: 10px;
  margin-top: 0;
  padding-bottom: 0; }

.commentlist .vcard {
  margin-left: 50px; }
  .commentlist .vcard cite.fn {
    font-weight: 700;
    font-style: normal; }
  .commentlist .vcard time {
    float: right; }
    .commentlist .vcard time a {
      color: #999;
      text-decoration: none; }
      .commentlist .vcard time a:hover {
        text-decoration: underline; }
  .commentlist .vcard img.avatar {
    position: absolute;
    left: 10px;
    padding: 2px;
    border: 1px solid #cecece;
    background: #fff; }

.commentlist .comment_content p {
  margin: 0.7335em 0 1.5em;
  font-size: 1em;
  line-height: 1.5em; }

.commentlist .comment-reply-link {
  float: right; }

/*********************
COMMENT FORM STYLES
*********************/
.respond-form {
  margin: 1.5em 10px;
  padding-bottom: 2.2em; }
  .respond-form form {
    margin: 0.75em 0; }
    .respond-form form li {
      list-style-type: none;
      clear: both;
      margin-bottom: 0.7335em; }
      .respond-form form li label,
      .respond-form form li small {
        display: none; }
    .respond-form form input[type=text],
    .respond-form form input[type=email],
    .respond-form form input[type=url],
    .respond-form form textarea {
      padding: 3px 6px;
      background: #efefef;
      border: 2px solid #cecece;
      line-height: 1.5em; }
      .respond-form form input[type=text]:focus,
      .respond-form form input[type=email]:focus,
      .respond-form form input[type=url]:focus,
      .respond-form form textarea:focus {
        background: #fff; }
      .respond-form form input[type=text]:invalid,
      .respond-form form input[type=email]:invalid,
      .respond-form form input[type=url]:invalid,
      .respond-form form textarea:invalid {
        outline: none;
        border-color: #fbc2c4;
        background-color: #f6e7eb;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        box-shadow: none; }
    .respond-form form input[type=text],
    .respond-form form input[type=email],
    .respond-form form input[type=url] {
      max-width: 400px;
      min-width: 250px; }
    .respond-form form textarea {
      resize: none;
      width: 97.3%;
      height: 150px; }

#comment-form-title {
  margin: 0 0 1.1em; }

#allowed_tags {
  margin: 1.5em 10px 0.7335em 0; }

.nocomments {
  margin: 0 20px 1.1em; }

/*********************
SIDEBARS & ASIDES
*********************/
.sidebar li {
  list-style: none; }

.widget ul {
  margin: 0; }

/*********************
FOOTER STYLES
*********************/
.footer {
  clear: both;
  margin-top: 1em; }

/*********************
VISUAL EDITOR STYLES
*********************/
body#tinymce {
  margin: 20px; }

/*********************
PLUGIN STYLES
*********************/
.gform_body ul {
  list-style: none outside none;
  margin: 0; }

.ubermenu.ubermenu-row {
  background: white; }

header.header {
  position: relative;
  z-index: 10; }

.top-bar .top-bar-left ul {
  background-color: transparent; }

.top-bar .top-bar-right {
  width: 100%; }
  .top-bar .top-bar-right .ubermenu .ubermenu-nav > li .ubermenu-item-header > a {
    font-weight: 500;
    font-size: 17px; }
  .top-bar .top-bar-right .ubermenu .ubermenu-nav > li .ubermenu-submenu > li.ubermenu-has-submenu-stack > a {
    font-weight: 500; }
  .top-bar .top-bar-right .ubermenu .ubermenu-nav > li.shopping-bag {
    background-color: #6f5252; }
  .top-bar .top-bar-right .ubermenu .ubermenu-nav > li > a {
    height: 70px;
    line-height: 40px;
    color: white; }
  .top-bar .top-bar-right .ubermenu .ubermenu-submenu {
    background-color: white; }
    .top-bar .top-bar-right .ubermenu .ubermenu-submenu a {
      color: #110c0c; }

.top-bar .ubermenu-submenu-type-stack li {
  padding-top: 0;
  padding-bottom: 0; }

.top-bar .menu li a.logo {
  padding: 0 1rem; }

.contact > a {
  position: relative; }
  .contact > a:after {
    content: "";
    top: 10px;
    bottom: 10px;
    border-left: 1px solid rgba(111, 82, 82, 0.5);
    position: absolute;
    right: 0; }

.featured-brand > a span {
  position: relative; }
  .featured-brand > a span:after {
    content: "";
    border-bottom: 1px solid white;
    width: 100%;
    position: absolute;
    bottom: 7px;
    left: 0; }

.searchbar {
  margin: 0;
  position: relative; }
  .searchbar ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #110c0c; }
  .searchbar ::-moz-placeholder {
    /* Firefox 19+ */
    color: #110c0c; }
  .searchbar :-ms-input-placeholder {
    /* IE 10+ */
    color: #110c0c; }
  .searchbar :-moz-placeholder {
    /* Firefox 18- */
    color: #110c0c; }

.input-group-button {
  float: right; }
  .input-group-button .search-button {
    height: 70px;
    width: 70px;
    text-align: center; }

.menu-search .input-group {
  margin-bottom: 0; }
  .menu-search .input-group .button {
    height: 70px;
    line-height: 40px; }
  .menu-search .input-group .search-field {
    width: 0;
    height: 70px;
    padding: 0;
    border: none;
    transition: all 0.5s ease;
    position: absolute;
    margin-bottom: 0;
    border: 0;
    box-shadow: none;
    color: black;
    right: 60.66px;
    margin-right: 0 !important;
    outline: none; }
    .menu-search .input-group .search-field.expand-search {
      width: 80%;
      max-width: calc(80% - 3rem);
      padding: .5rem;
      max-width: 250px;
      min-width: 200px;
      z-index: 100;
      padding: 0 1em; }
      .menu-search .input-group .search-field.expand-search:focus {
        border: 0;
        box-shadow: none; }

.position-right .ubermenu-responsive-default.ubermenu-responsive .ubermenu-nav .ubermenu-item .ubermenu-submenu.ubermenu-submenu-drop {
  background-color: white; }

.ubermenu .ubermenu-item-type-column > .ubermenu-submenu-type-stack > .ubermenu-item-normal:first-child {
  margin-top: 0; }

.ubermenu .ubermenu-submenu-type-stack > .ubermenu-item-normal > .ubermenu-target {
  font-size: 15px; }

.ubermenu .ubermenu-autocolumn, .ubermenu .ubermenu-submenu-type-stack {
  padding-top: 0; }

.ubermenu-row-id-25 {
  max-width: 80% !important;
  margin: 0 auto !important;
  padding-top: 2rem !important; }
  .ubermenu-row-id-25 > li:not(:first-of-type) {
    border-left: 1px solid #f3f3f3; }

.ubermenu-sub-indicators .ubermenu-has-submenu-drop > .ubermenu-target:after, .ubermenu .ubermenu-search .ubermenu-search-submit, .ubermenu .ubermenu-searchbar-drop .ubermenu-search-submit {
  font-family: "Font Awesome 5 Pro" !important; }

footer.footer {
  margin-top: 0; }
  footer.footer .upper-footer {
    background-color: #110c0c;
    padding-top: 3rem;
    padding-bottom: 3rem; }
    footer.footer .upper-footer h3 {
      color: #ffffff;
      border-bottom: 1px solid rgba(243, 243, 243, 0.5);
      display: inline-block;
      margin: 0 auto 4rem;
      padding-bottom: 1rem; }
    footer.footer .upper-footer h5, footer.footer .upper-footer a, footer.footer .upper-footer p {
      color: #f3f3f3; }
    footer.footer .upper-footer p, footer.footer .upper-footer a {
      font-weight: 300; }
    footer.footer .upper-footer a strong {
      text-transform: uppercase;
      margin-right: 0.25rem; }
    footer.footer .upper-footer .about-coffee-1652 p:first-of-type, footer.footer .upper-footer .contact-coffee-1652 p:first-of-type {
      padding-top: .4rem; }
    footer.footer .upper-footer .menu li.active a {
      background-color: transparent;
      font-weight: 500; }
    footer.footer .upper-footer .social-menu {
      padding-left: 0.5rem;
      padding-right: 0.5rem; }
      footer.footer .upper-footer .social-menu li a {
        color: #ffffff;
        padding: .7rem 0.5rem; }
  footer.footer .lower-footer {
    padding-top: .7rem; }
    footer.footer .lower-footer .menu li a {
      color: #8a8a8a;
      font-weight: 500;
      font-size: 15px; }
    footer.footer .lower-footer .menu li.active a {
      background-color: transparent;
      color: #8a8a8a;
      font-weight: bold; }
    footer.footer .lower-footer .copyright {
      padding-top: .4rem;
      color: #8a8a8a;
      font-weight: 500;
      font-size: 15px; }

.logo-slider {
  padding: 6rem 0 5rem; }
  .logo-slider .slides .slick-slide img {
    margin: 0 auto; }

.bg-cta {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../images/cta-bg.jpg); }
  .bg-cta .overlay {
    background-color: rgba(111, 82, 82, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .bg-cta h3, .bg-cta h4 {
    color: #ffffff; }

.cta-section {
  position: relative;
  background-color: #f3f3f3;
  padding-top: 5rem;
  padding-bottom: 4rem; }
  .cta-section .cta-content {
    z-index: 3;
    position: relative; }
    .cta-section .cta-content .button {
      margin-top: 1.5rem;
      border-width: 2px;
      text-transform: uppercase;
      font-weight: 700;
      padding: 1.25rem 1.75rem; }

.product-category-blocks > .row > .columns {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }
  .product-category-blocks > .row > .columns:first-of-type {
    padding-left: 0; }
  .product-category-blocks > .row > .columns:last-of-type {
    padding-right: 0; }

.product-category-blocks .row.square {
  margin-bottom: 1rem; }
  .product-category-blocks .row.square .block {
    padding-top: 75%; }
    .product-category-blocks .row.square .block .block-content-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }

.product-category-blocks .row.rectangle {
  height: calc(100% - 1rem);
  margin-bottom: 1rem; }
  .product-category-blocks .row.rectangle .block {
    height: 100%; }

.product-category-blocks .block {
  position: relative; }
  .product-category-blocks .block .block-content-container {
    position: relative;
    height: 100%; }
    .product-category-blocks .block .block-content-container a {
      color: white; }
      .product-category-blocks .block .block-content-container a:hover .block-content:before {
        opacity: 1; }
    .product-category-blocks .block .block-content-container .block-content {
      background-color: #f3f3f3;
      height: 100%;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center; }
      .product-category-blocks .block .block-content-container .block-content:before {
        opacity: 0;
        content: "";
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        right: 0;
        position: absolute;
        -webkit-transition: opacity 0.25s ease-in-out;
        -moz-transition: opacity 0.25s ease-in-out;
        -o-transition: opacity 0.25s ease-in-out;
        transition: opacity 0.25s ease-in-out; }
      .product-category-blocks .block .block-content-container .block-content .block-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 3rem;
        max-width: 50%; }
        .product-category-blocks .block .block-content-container .block-content .block-footer a {
          color: white; }

.product-category-blocks .square-blocks-container {
  padding-left: 0 !important;
  padding-right: 0 !important; }
  .product-category-blocks .square-blocks-container .square-blocks > .columns {
    padding-left: 0.5rem;
    padding-right: 0rem; }

#homepage-slider {
  margin-top: -70px;
  z-index: 0;
  position: relative;
  height: 100vh; }
  #homepage-slider .slick-list {
    min-height: 100vh;
    height: 100%; }
    #homepage-slider .slick-list .slick-track {
      min-height: 100vh;
      height: 100%; }
      #homepage-slider .slick-list .slick-track .slick-slide {
        min-height: 100vh;
        height: 100%; }
        #homepage-slider .slick-list .slick-track .slick-slide > div {
          min-height: 100vh;
          height: 100%; }
          #homepage-slider .slick-list .slick-track .slick-slide > div .slick-slide-content-container {
            min-height: 100vh;
            height: 100%;
            display: table !important;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center; }
            #homepage-slider .slick-list .slick-track .slick-slide > div .slick-slide-content-container .slick-slide-content {
              display: table-cell;
              vertical-align: middle; }
              #homepage-slider .slick-list .slick-track .slick-slide > div .slick-slide-content-container .slick-slide-content h1, #homepage-slider .slick-list .slick-track .slick-slide > div .slick-slide-content-container .slick-slide-content h3 {
                color: white; }
              #homepage-slider .slick-list .slick-track .slick-slide > div .slick-slide-content-container .slick-slide-content h1 {
                font-weight: 700; }
              #homepage-slider .slick-list .slick-track .slick-slide > div .slick-slide-content-container .slick-slide-content h3 {
                max-width: 420px; }
              #homepage-slider .slick-list .slick-track .slick-slide > div .slick-slide-content-container .slick-slide-content .button {
                text-transform: uppercase;
                font-weight: bold; }

.content {
  margin-top: -70px;
  z-index: 0;
  position: relative; }
  .content .article-header {
    background-color: #f3f3f3;
    padding-top: calc(6rem + 70px);
    padding-bottom: 6rem; }

.narrow-row {
  max-width: 87.5rem; }

.inner-page .article-header {
  background-image: url(../images/about/about-bg.png); }
  .inner-page .article-header h1 {
    color: white; }

.type-page .article-header {
  background-size: cover;
  background-repeat: no-repeat; }
  .type-page .article-header h1, .type-page .article-header a, .type-page .article-header .breadcrumbs {
    color: white; }

.content-section {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.wpcf7 form {
  background-color: rgba(243, 243, 243, 0.2);
  padding: 2rem;
  border-radius: 5px; }

.wpcf7 [type=color], .wpcf7 [type=date], .wpcf7 [type=datetime-local], .wpcf7 [type=datetime], .wpcf7 [type=email], .wpcf7 [type=month], .wpcf7 [type=number], .wpcf7 [type=password], .wpcf7 [type=search], .wpcf7 [type=tel], .wpcf7 [type=text], .wpcf7 [type=time], .wpcf7 [type=url], .wpcf7 [type=week], .wpcf7 textarea {
  border: 1px solid #f3f3f3;
  box-shadow: none;
  border-radius: 5px; }

.wpcf7 textarea {
  resize: none;
  height: 150px; }

.wpcf7 p {
  margin-bottom: 0; }

.wpcf7 .design-button {
  width: 100%;
  padding: 0.75rem 1.5rem; }

.wpcf7 .wpcf7-submit {
  margin-bottom: 0; }

.wpcf7 ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #110c0c; }

.wpcf7 ::-moz-placeholder {
  /* Firefox 19+ */
  color: #110c0c; }

.wpcf7 :-ms-input-placeholder {
  /* IE 10+ */
  color: #110c0c; }

.wpcf7 :-moz-placeholder {
  /* Firefox 18- */
  color: #110c0c; }

.showrooms .showroom iframe {
  height: 400px; }

.woocommerce-breadcrumb span {
  font-weight: 600; }

.woocommerce-breadcrumb a {
  color: #110c0c;
  font-weight: 300; }

.products-list {
  margin-bottom: 2rem; }
  .products-list .product {
    position: relative;
    margin-bottom: 2rem; }
    .products-list .product h2 {
      font-size: 1.25rem;
      line-height: 1.4;
      margin-top: 0;
      margin-bottom: .5rem; }
    .products-list .product .woocommerce-loop-product__link {
      width: 100%;
      display: block;
      background-color: #f3f3f3;
      border: none;
      padding: 2rem; }

.onsale {
  position: absolute;
  top: 1em;
  left: 2em;
  color: white;
  background-color: #110c0c;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center; }

.summary {
  position: relative; }
  .summary h1 {
    font-size: 24px;
    font-weight: 500; }
  .summary .price {
    font-weight: 500;
    font-size: 18px; }
    .summary .price del {
      font-weight: 300; }
  .summary .woocommerce-product-details__short-description {
    margin-bottom: 2rem; }
    .summary .woocommerce-product-details__short-description p {
      font-size: 14px;
      width: 85%;
      min-width: 300px; }
  .summary .onsale {
    position: relative;
    display: inline-block;
    margin-bottom: 1.5rem;
    left: 0; }

.screen-reader-text {
  display: none; }

.woocommerce .quantity, .woocommerce-page .quantity {
  border-width: 1px;
  border-style: solid;
  border-color: #dedede;
  padding-right: 0;
  float: left;
  margin-right: 1rem; }
  .woocommerce .quantity input[type=number]::-webkit-outer-spin-button,
  .woocommerce .quantity input[type=number]::-webkit-inner-spin-button, .woocommerce-page .quantity input[type=number]::-webkit-outer-spin-button,
  .woocommerce-page .quantity input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .woocommerce .quantity input[type=number], .woocommerce-page .quantity input[type=number] {
    -moz-appearance: textfield; }
  .woocommerce .quantity .minus, .woocommerce-page .quantity .minus {
    float: left; }
  .woocommerce .quantity .plus, .woocommerce-page .quantity .plus {
    float: right; }
  .woocommerce .quantity input.qty, .woocommerce-page .quantity input.qty {
    width: 3.5rem;
    box-shadow: none;
    font-weight: normal; }
  .woocommerce .quantity .minus, .woocommerce .quantity .plus, .woocommerce-page .quantity .minus, .woocommerce-page .quantity .plus {
    font-weight: 300; }
    .woocommerce .quantity .minus:hover, .woocommerce .quantity .plus:hover, .woocommerce-page .quantity .minus:hover, .woocommerce-page .quantity .plus:hover {
      color: white;
      background-color: black; }
  .woocommerce .quantity input.qty, .woocommerce .quantity .minus, .woocommerce .quantity .plus, .woocommerce-page .quantity input.qty, .woocommerce-page .quantity .minus, .woocommerce-page .quantity .plus {
    position: relative;
    display: inline-block;
    height: 3rem;
    background-color: white;
    border-radius: 0px !important;
    outline: none !important;
    border: 0px !important; }

form.cart {
  display: block;
  margin-bottom: 1rem; }
  form.cart .single_add_to_cart_button {
    margin-bottom: 0;
    padding: 1.05rem 1.5rem;
    text-transform: uppercase;
    font-weight: 600; }

.product_meta {
  display: none; }

.wc-tabs li a {
  font-size: 14px;
  text-transform: capitalize; }

.wc-tabs li.is-active a {
  font-weight: 500; }

.tabs-panel p {
  font-size: 14px; }
  .tabs-panel p:last-of-type {
    margin-bottom: 0; }

.tabs-panel table tbody tr th, .tabs-panel table tbody tr td {
  font-size: 14px; }

.tabs-panel ul li {
  font-size: 14px;
  margin-bottom: 0.5rem; }

.shop_attributes {
  margin-bottom: 0;
  text-align: left; }

.product-image-container {
  display: table;
  width: 100%; }
  .product-image-container .product-image {
    display: table-cell;
    vertical-align: middle; }
    .product-image-container .product-image img {
      display: inline-block; }

.woocommerce-variation-price {
  margin-bottom: 1rem; }

.delivery-returns {
  padding-top: 1em;
  padding-bottom: 1em; }

.variations tbody {
  border: none; }
  .variations tbody tr td.value select {
    display: inline-block;
    float: left;
    width: auto;
    margin-bottom: 0; }
  .variations tbody tr td.value .reset_variations {
    margin-bottom: 0;
    height: 2.4375rem;
    margin-left: 0.5rem;
    padding: 0.8rem 1.5rem; }

.yith_magnifier_loading {
  display: none !important; }

.woocommerce-cart-form__cart-item .product-remove {
  width: 62px; }
  .woocommerce-cart-form__cart-item .product-remove .button {
    margin-bottom: 0;
    border-radius: 50px;
    padding-left: 0;
    padding-right: 0;
    height: 40px;
    width: 40px; }

.actions .button {
  margin-bottom: 0; }

.actions .input-group {
  margin-bottom: 0; }

.woocommerce .callout {
  font-weight: normal; }
  .woocommerce .callout a {
    color: white; }

.yith_magnifier_zoom_magnifier {
  background-color: white;
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
  right: 0; }

.hidden {
  visibility: hidden !important; }

.yith_magnifier_zoom img {
  margin: 0 auto; }

.thumbnails.slider {
  height: auto !important; }
  .thumbnails.slider .yith_magnifier_gallery li {
    text-align: center; }
    .thumbnails.slider .yith_magnifier_gallery li a {
      margin-bottom: 10px; }

.results-sorting {
  margin-bottom: 1rem; }
  .results-sorting .woocommerce-result-count {
    padding: 0.5rem;
    float: left;
    display: inline-block;
    color: #8a8a8a; }
  .results-sorting .woocommerce-ordering {
    float: right;
    display: inline-block; }
    .results-sorting .woocommerce-ordering .orderby {
      border: none;
      box-shadow: none;
      color: #8a8a8a;
      text-align: right; }

.woocommerce-message.light-gray {
  background-color: #f3f3f3; }

* {
  outline: none !important; }

.woocommerce-pagination {
  text-align: center; }
  .woocommerce-pagination .page-numbers {
    margin: 0 auto 1rem; }

body #add_payment_method #payment ul.payment_methods li input,
body.woocommerce-cart #payment ul.payment_methods li input,
body.woocommerce-checkout #payment ul.payment_methods li input {
  width: auto;
  margin: -2px .5em 0 0; }

body .woocommerce form .form-row .input-checkbox {
  width: auto;
  margin: -2px 5px 0 0; }

body.woocommerce-checkout .cart-collaterals .cart_totals tr td,
body.woocommerce-checkout .cart-collaterals .cart_totals tr th,
body .woocommerce table.shop_table th {
  border-top: none;
  text-align: left;
  padding: 10px 20px; }

body .woocommerce table.shop_table td {
  padding: 15px; }

body .woocommerce-checkout table.shop_table td {
  width: 50%;
  text-align: left;
  border-top: 0; }

body .woocommerce #payment #place_order, .woocommerce-page #payment #place_order {
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 0;
  font-size: 1.25em;
  padding: 1em;
  margin-top: .5em; }

body .woocommerce form .form-row-first,
body .woocommerce form .form-row-last,
body .woocommerce-page form .form-row-first,
body .woocommerce-page form .form-row-last {
  width: 49%; }

.woocommerce-checkout .woocommerce .col2-set {
  width: 47%;
  float: left;
  margin-right: 4%; }

.woocommerce-checkout .woocommerce .col2-set .col-1,
.woocommerce-checkout .woocommerce .col2-set .col-2 {
  margin-bottom: 2em;
  width: 100%;
  float: none; }

.woocommerce-checkout #order_review_heading,
.woocommerce-checkout #order_review {
  background: #fff;
  width: 47%;
  float: right;
  margin-right: 0; }

@media only screen and (max-width: 650px) {
  .woocommerce-checkout .woocommerce .col2-set {
    width: 100%;
    float: none;
    margin-right: 0; }
  .woocommerce-checkout #order_review_heading,
  .woocommerce-checkout #order_review {
    width: 100%;
    float: none; } }

#billing_first_name_field, #shipping_first_name_field {
  float: left;
  margin-bottom: 0; }

#billing_last_name_field, #shipping_last_name_field {
  float: right;
  margin-bottom: 0; }

.woocommerce-billing-fields__field-wrapper, .woocommerce-shipping-fields__field-wrapper, .woocommerce-additional-fields__field-wrapper {
  padding: 1rem;
  background-color: #f8f8f8;
  margin-bottom: 1.5rem; }

.woocommerce-shipping-fields {
  display: inline-block;
  width: 100%; }

.woocommerce-cart table.cart .product-thumbnail,
.woocommerce-checkout table.cart .product-thumbnail,
#add_payment_method table.cart .product-thumbnail {
  min-width: 32px; }

.woocommerce-cart table.cart img,
.woocommerce-checkout table.cart img,
#add_payment_method table.cart img {
  width: 32px;
  box-shadow: none; }

.woocommerce-cart table.cart th,
.woocommerce-cart table.cart td,
.woocommerce-checkout table.cart th,
.woocommerce-checkout table.cart td,
#add_payment_method table.cart th,
#add_payment_method table.cart td {
  vertical-align: middle; }

.woocommerce-cart table.cart td.actions .coupon .input-text,
.woocommerce-checkout table.cart td.actions .coupon .input-text,
#add_payment_method table.cart td.actions .coupon .input-text {
  float: left;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  padding: 6px 6px 5px;
  margin: 0 4px 0 0;
  outline: 0; }

.woocommerce-cart table.cart input,
.woocommerce-checkout table.cart input,
#add_payment_method table.cart input {
  margin: 0;
  vertical-align: middle; }

.woocommerce-cart .wc-proceed-to-checkout,
.woocommerce-checkout .wc-proceed-to-checkout,
#add_payment_method .wc-proceed-to-checkout {
  padding: 1em 0; }
  .woocommerce-cart .wc-proceed-to-checkout::before, .woocommerce-cart .wc-proceed-to-checkout::after,
  .woocommerce-checkout .wc-proceed-to-checkout::before,
  .woocommerce-checkout .wc-proceed-to-checkout::after,
  #add_payment_method .wc-proceed-to-checkout::before,
  #add_payment_method .wc-proceed-to-checkout::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .woocommerce-cart .wc-proceed-to-checkout::after,
  .woocommerce-checkout .wc-proceed-to-checkout::after,
  #add_payment_method .wc-proceed-to-checkout::after {
    clear: both; }
  .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-checkout .wc-proceed-to-checkout a.checkout-button,
  #add_payment_method .wc-proceed-to-checkout a.checkout-button {
    display: block;
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.25em;
    padding: 1em; }

.woocommerce-cart .cart-collaterals .shipping-calculator-button,
.woocommerce-checkout .cart-collaterals .shipping-calculator-button,
#add_payment_method .cart-collaterals .shipping-calculator-button {
  float: none;
  margin-top: .5em;
  display: inline-block; }

.woocommerce-cart .cart-collaterals .shipping-calculator-form,
.woocommerce-checkout .cart-collaterals .shipping-calculator-form,
#add_payment_method .cart-collaterals .shipping-calculator-form {
  margin: 1em 0 0 0; }

.woocommerce-cart .cart-collaterals .cart_totals p small,
.woocommerce-checkout .cart-collaterals .cart_totals p small,
#add_payment_method .cart-collaterals .cart_totals p small {
  font-size: 0.83em; }

.woocommerce-cart .cart-collaterals .cart_totals table,
.woocommerce-checkout .cart-collaterals .cart_totals table,
#add_payment_method .cart-collaterals .cart_totals table {
  border-collapse: separate;
  margin: 0 0 6px;
  padding: 0; }
  .woocommerce-cart .cart-collaterals .cart_totals table tr:first-child th,
  .woocommerce-cart .cart-collaterals .cart_totals table tr:first-child td,
  .woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child th,
  .woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child td,
  #add_payment_method .cart-collaterals .cart_totals table tr:first-child th,
  #add_payment_method .cart-collaterals .cart_totals table tr:first-child td {
    border-top: 0; }
  .woocommerce-cart .cart-collaterals .cart_totals table th,
  .woocommerce-checkout .cart-collaterals .cart_totals table th,
  #add_payment_method .cart-collaterals .cart_totals table th {
    width: 35%; }
  .woocommerce-cart .cart-collaterals .cart_totals table td,
  .woocommerce-cart .cart-collaterals .cart_totals table th,
  .woocommerce-checkout .cart-collaterals .cart_totals table td,
  .woocommerce-checkout .cart-collaterals .cart_totals table th,
  #add_payment_method .cart-collaterals .cart_totals table td,
  #add_payment_method .cart-collaterals .cart_totals table th {
    vertical-align: top;
    border-left: 0;
    border-right: 0;
    line-height: 1.5em; }
  .woocommerce-cart .cart-collaterals .cart_totals table select,
  .woocommerce-checkout .cart-collaterals .cart_totals table select,
  #add_payment_method .cart-collaterals .cart_totals table select {
    width: 100%; }

.woocommerce-cart .cart-collaterals .cart_totals tr td,
.woocommerce-cart .cart-collaterals .cart_totals tr th,
.woocommerce-checkout .cart-collaterals .cart_totals tr td,
.woocommerce-checkout .cart-collaterals .cart_totals tr th,
#add_payment_method .cart-collaterals .cart_totals tr td,
#add_payment_method .cart-collaterals .cart_totals tr th {
  border-top: 1px solid #efefef; }

.woocommerce-cart .cart-collaterals .cart_totals .woocommerce-shipping-destination,
.woocommerce-checkout .cart-collaterals .cart_totals .woocommerce-shipping-destination,
#add_payment_method .cart-collaterals .cart_totals .woocommerce-shipping-destination {
  margin-bottom: 0; }

.woocommerce-cart .cart-collaterals .cross-sells ul.products li.product,
.woocommerce-checkout .cart-collaterals .cross-sells ul.products li.product,
#add_payment_method .cart-collaterals .cross-sells ul.products li.product {
  margin-top: 0; }

.woocommerce-cart .checkout .col-2 h3#ship-to-different-address,
.woocommerce-checkout .checkout .col-2 h3#ship-to-different-address,
#add_payment_method .checkout .col-2 h3#ship-to-different-address {
  float: left;
  clear: none; }

.woocommerce-cart .checkout .col-2 .notes,
.woocommerce-checkout .checkout .col-2 .notes,
#add_payment_method .checkout .col-2 .notes {
  clear: left; }

.woocommerce-cart .checkout .col-2 .form-row-first,
.woocommerce-checkout .checkout .col-2 .form-row-first,
#add_payment_method .checkout .col-2 .form-row-first {
  clear: left; }

.woocommerce-cart .checkout .create-account small,
.woocommerce-checkout .checkout .create-account small,
#add_payment_method .checkout .create-account small {
  font-size: 11px;
  font-weight: normal; }

.woocommerce-cart .checkout div.shipping-address,
.woocommerce-checkout .checkout div.shipping-address,
#add_payment_method .checkout div.shipping-address {
  padding: 0;
  clear: left;
  width: 100%; }

.woocommerce-cart .checkout .shipping_address,
.woocommerce-checkout .checkout .shipping_address,
#add_payment_method .checkout .shipping_address {
  clear: both; }

.woocommerce-cart #payment,
.woocommerce-checkout #payment,
#add_payment_method #payment {
  background: #efefef;
  border-radius: 5px; }
  .woocommerce-cart #payment ul.payment_methods,
  .woocommerce-checkout #payment ul.payment_methods,
  #add_payment_method #payment ul.payment_methods {
    text-align: left;
    padding: 1em;
    border-bottom: 1px solid #d6d6d6;
    margin: 0;
    list-style: none outside; }
    .woocommerce-cart #payment ul.payment_methods::before, .woocommerce-cart #payment ul.payment_methods::after,
    .woocommerce-checkout #payment ul.payment_methods::before,
    .woocommerce-checkout #payment ul.payment_methods::after,
    #add_payment_method #payment ul.payment_methods::before,
    #add_payment_method #payment ul.payment_methods::after {
      display: table;
      content: ' ';
      flex-basis: 0;
      order: 1; }
    .woocommerce-cart #payment ul.payment_methods::after,
    .woocommerce-checkout #payment ul.payment_methods::after,
    #add_payment_method #payment ul.payment_methods::after {
      clear: both; }
    .woocommerce-cart #payment ul.payment_methods li,
    .woocommerce-checkout #payment ul.payment_methods li,
    #add_payment_method #payment ul.payment_methods li {
      line-height: 2;
      text-align: left;
      margin: 0;
      font-weight: normal; }
      .woocommerce-cart #payment ul.payment_methods li input,
      .woocommerce-checkout #payment ul.payment_methods li input,
      #add_payment_method #payment ul.payment_methods li input {
        margin: 0 1em 0 0; }
      .woocommerce-cart #payment ul.payment_methods li img,
      .woocommerce-checkout #payment ul.payment_methods li img,
      #add_payment_method #payment ul.payment_methods li img {
        vertical-align: middle;
        margin: -2px 0 0 0.5em;
        padding: 0;
        position: relative;
        box-shadow: none; }
      .woocommerce-cart #payment ul.payment_methods li img + img,
      .woocommerce-checkout #payment ul.payment_methods li img + img,
      #add_payment_method #payment ul.payment_methods li img + img {
        margin-left: 2px; }
    .woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::before, .woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::after,
    .woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::before,
    .woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::after,
    #add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::before,
    #add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::after {
      display: table;
      content: ' ';
      flex-basis: 0;
      order: 1; }
    .woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::after,
    .woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::after,
    #add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::after {
      clear: both; }
  .woocommerce-cart #payment div.form-row,
  .woocommerce-checkout #payment div.form-row,
  #add_payment_method #payment div.form-row {
    padding: 1em; }
  .woocommerce-cart #payment div.payment_box,
  .woocommerce-checkout #payment div.payment_box,
  #add_payment_method #payment div.payment_box {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 1em;
    margin: 1em 0;
    font-size: 0.92em;
    border-radius: 2px;
    line-height: 1.5;
    background-color: #e2e2e2; }
    .woocommerce-cart #payment div.payment_box input.input-text, .woocommerce-cart #payment div.payment_box textarea,
    .woocommerce-checkout #payment div.payment_box input.input-text,
    .woocommerce-checkout #payment div.payment_box textarea,
    #add_payment_method #payment div.payment_box input.input-text,
    #add_payment_method #payment div.payment_box textarea {
      border-color: #c9c9c9;
      border-top-color: #bcbcbc; }
    .woocommerce-cart #payment div.payment_box ::-webkit-input-placeholder,
    .woocommerce-checkout #payment div.payment_box ::-webkit-input-placeholder,
    #add_payment_method #payment div.payment_box ::-webkit-input-placeholder {
      color: #bcbcbc; }
    .woocommerce-cart #payment div.payment_box :-moz-placeholder,
    .woocommerce-checkout #payment div.payment_box :-moz-placeholder,
    #add_payment_method #payment div.payment_box :-moz-placeholder {
      color: #bcbcbc; }
    .woocommerce-cart #payment div.payment_box :-ms-input-placeholder,
    .woocommerce-checkout #payment div.payment_box :-ms-input-placeholder,
    #add_payment_method #payment div.payment_box :-ms-input-placeholder {
      color: #bcbcbc; }
    .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods,
    .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods,
    #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods {
      list-style: none outside;
      margin: 0; }
      .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
      .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new,
      .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
      .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new,
      #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
      #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new {
        margin: 0 0 0.5em; }
        .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
        .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label,
        .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
        .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label,
        #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
        #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label {
          cursor: pointer; }
      .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput,
      .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput,
      #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput {
        vertical-align: middle;
        margin: -3px 1em 0 0;
        position: relative; }
    .woocommerce-cart #payment div.payment_box .wc-credit-card-form,
    .woocommerce-checkout #payment div.payment_box .wc-credit-card-form,
    #add_payment_method #payment div.payment_box .wc-credit-card-form {
      border: 0;
      padding: 0;
      margin: 1em 0 0; }
    .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number,
    .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry,
    .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc,
    .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number,
    .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry,
    .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc,
    #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number,
    #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry,
    #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc {
      font-size: 1.5em;
      padding: 8px;
      background-repeat: no-repeat;
      background-position: right 0.618em center;
      background-size: 32px 20px; }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.visa,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.visa,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.visa,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.visa {
        background-image: url("../images/icons/credit-cards/visa.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard {
        background-image: url("../images/icons/credit-cards/mastercard.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.laser,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.laser,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.laser,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.laser {
        background-image: url("../images/icons/credit-cards/laser.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub {
        background-image: url("../images/icons/credit-cards/diners.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.maestro,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.maestro,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.maestro,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.maestro {
        background-image: url("../images/icons/credit-cards/maestro.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.jcb,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.jcb,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.jcb,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.jcb {
        background-image: url("../images/icons/credit-cards/jcb.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.amex,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.amex,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.amex,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.amex {
        background-image: url("../images/icons/credit-cards/amex.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.discover,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.discover,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.discover,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.discover {
        background-image: url("../images/icons/credit-cards/discover.svg"); }
    .woocommerce-cart #payment div.payment_box span.help,
    .woocommerce-checkout #payment div.payment_box span.help,
    #add_payment_method #payment div.payment_box span.help {
      font-size: 0.857em;
      font-weight: normal; }
    .woocommerce-cart #payment div.payment_box .form-row,
    .woocommerce-checkout #payment div.payment_box .form-row,
    #add_payment_method #payment div.payment_box .form-row {
      margin: 0 0 1em; }
    .woocommerce-cart #payment div.payment_box p:last-child,
    .woocommerce-checkout #payment div.payment_box p:last-child,
    #add_payment_method #payment div.payment_box p:last-child {
      margin-bottom: 0; }
    .woocommerce-cart #payment div.payment_box::before,
    .woocommerce-checkout #payment div.payment_box::before,
    #add_payment_method #payment div.payment_box::before {
      content: '';
      display: block;
      border: 1em solid #e2e2e2;
      /* arrow size / color */
      border-right-color: transparent;
      border-left-color: transparent;
      border-top-color: transparent;
      position: absolute;
      top: -0.75em;
      left: 0;
      margin: -1em 0 0 2em; }
  .woocommerce-cart #payment .payment_method_paypal .about_paypal,
  .woocommerce-checkout #payment .payment_method_paypal .about_paypal,
  #add_payment_method #payment .payment_method_paypal .about_paypal {
    float: right;
    line-height: 52px;
    font-size: 0.83em; }
  .woocommerce-cart #payment .payment_method_paypal img,
  .woocommerce-checkout #payment .payment_method_paypal img,
  #add_payment_method #payment .payment_method_paypal img {
    max-height: 52px;
    vertical-align: middle; }

/****************CHECKOUT***************/
.woocommerce form .form-row {
  width: 100% !important; }

.woocommerce-checkout #payment div.payment_box input.input-text, .woocommerce-checkout #payment div.payment_box textarea {
  width: 100% !important;
  padding: 8px; }

.woocommerce #payment .form-row select, .woocommerce-page #payment .form-row select {
  width: 100%;
  height: 30px; }

.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1, .woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2 {
  float: left;
  width: 100%; }

.custom-checkout h3 {
  background-color: #165f1c;
  /****CHANGE TO COLOR YOU WANT TO USE FOR TITLE BACKGROUND ****/
  width: 45%;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  margin-top: 50px;
  color: #FFF;
  float: right; }

.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
  padding: .6180469716em;
  background-color: #f2f2f2;
  color: #43454b;
  outline: 0;
  border: 0;
  -webkit-appearance: none;
  border-radius: 2px;
  box-sizing: border-box;
  font-weight: 400;
  border: solid 2px #e4e4e4; }

#wc_checkout_add_ons {
  width: 45%;
  float: right;
  text-align: center; }

@media screen and (min-width: 980px) {
  .woocommerce-shipping-fields h3, .woocommerce-billing-fields h3 {
    width: 100%; }
  .woocommerce .col2-set, .woocommerce-page .col2-set {
    width: 45%;
    float: left; }
  .woocommerce-checkout-review-order {
    width: 45%;
    float: right; } }

@media screen and (max-width: 979px) {
  .custom-checkout h3 {
    width: 100%; } }

.woocommerce-page .type-page > .article-header h1, .woocommerce-page .type-page > .article-header a, .woocommerce-page .type-page > .article-header .breadcrumbs {
  color: black !important; }

#shipping_method {
  list-style: none;
  margin-left: 0; }

.tinynav {
  display: none; }

@media screen and (max-width: 600px) {
  .tinynav {
    display: block; }
  #nav {
    display: none; } }

.breadcrumbs {
  font-weight: 300; }
  .breadcrumbs > span a {
    color: #110c0c; }
  .breadcrumbs > span:last-of-type {
    font-weight: 600; }

.bg-banner .breadcrumbs {
  color: white; }
  .bg-banner .breadcrumbs > span a {
    color: white; }

.usps {
  background-color: #6f5252;
  color: white;
  margin-bottom: 1rem; }
  .usps .columns {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
    .usps .columns i {
      margin-right: 0.25rem; }
    .usps .columns span {
      font-weight: 300; }
      .usps .columns span b {
        font-weight: 500; }

.products-section {
  padding-top: 3rem;
  padding-bottom: 4rem; }

.reveal {
  border: none;
  padding: 0; }
  .reveal .close-button span {
    color: white; }
  .reveal .reveal-header {
    padding: 1rem 2rem;
    background-color: #6f5252; }
    .reveal .reveal-header h5 {
      color: white;
      margin-bottom: 0; }
  .reveal .reveal-content {
    padding: 1rem 2rem; }
    .reveal .reveal-content ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #8a8a8a; }
    .reveal .reveal-content ::-moz-placeholder {
      /* Firefox 19+ */
      color: #8a8a8a; }
    .reveal .reveal-content :-ms-input-placeholder {
      /* IE 10+ */
      color: #8a8a8a; }
    .reveal .reveal-content :-moz-placeholder {
      /* Firefox 18- */
      color: #8a8a8a; }
    .reveal .reveal-content input, .reveal .reveal-content textarea {
      box-shadow: none;
      border: 1px solid #7e5d5d;
      color: #8a8a8a; }
      .reveal .reveal-content input:focus, .reveal .reveal-content textarea:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid #6f5252; }
    .reveal .reveal-content .button {
      margin-bottom: 0; }
  .reveal .reveal-footer {
    padding: 1rem 2rem;
    border-top: 1px solid #f3f3f3; }
    .reveal .reveal-footer .button-group {
      margin-bottom: 0; }

.background-content {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .background-content p {
    color: white;
    margin-bottom: 1.5rem;
    line-height: 1.75; }
    .background-content p:last-of-type {
      margin-bottom: 0; }

.search-list {
  padding-top: 3rem;
  margin-bottom: 2rem; }
  .search-list .search-item {
    position: relative;
    margin-bottom: 2rem; }
    .search-list .search-item h2 {
      font-size: 1.25rem;
      line-height: 1.4;
      margin-top: 0;
      margin-bottom: .5rem; }
    .search-list .search-item .image-link {
      width: 100%;
      display: block;
      background-color: #f3f3f3;
      border: none;
      padding: 2rem; }
  .search-list .search-item-container {
    display: table;
    width: 100%; }
    .search-list .search-item-container .search-image {
      display: table-cell;
      vertical-align: middle; }
      .search-list .search-item-container .search-image img {
        display: inline-block; }

@media screen and (max-width: 39.9375em) {
  #homepage-slider {
    max-height: 400px; }
    #homepage-slider .slick-list {
      max-height: 400px; }
    #homepage-slider .slick-list {
      min-height: 400px;
      height: 100%; }
      #homepage-slider .slick-list .slick-track {
        min-height: 400px;
        height: 100%; }
        #homepage-slider .slick-list .slick-track .slick-slide {
          min-height: 400px;
          height: 100%; }
          #homepage-slider .slick-list .slick-track .slick-slide > div {
            min-height: 400px;
            height: 100%; }
            #homepage-slider .slick-list .slick-track .slick-slide > div .slick-slide-content-container {
              min-height: 400px;
              height: 100%; }
  .ubermenu.ubermenu-responsive .ubermenu-column, .ubermenu.ubermenu-responsive .ubermenu-column-auto {
    width: 100%; }
  .featured-brand > a span:after {
    display: none; }
  .ubermenu .ubermenu-search .ubermenu-searchform {
    margin: 0;
    width: 100%; }
  .ubermenu-responsive .ubermenu-nav .ubermenu-item .ubermenu-submenu.ubermenu-submenu-drop {
    background-color: white; }
    .ubermenu-responsive .ubermenu-nav .ubermenu-item .ubermenu-submenu.ubermenu-submenu-drop li a {
      font-size: 14px; }
  .ubermenu .ubermenu-autocolumn, .ubermenu .ubermenu-submenu-type-stack {
    margin-top: 0;
    margin-bottom: 0; }
  .ubermenu .ubermenu-item-type-column > .ubermenu-submenu-type-stack > .ubermenu-item-normal:first-child {
    margin-top: 0; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .ubermenu.ubermenu-responsive .ubermenu-column, .ubermenu.ubermenu-responsive .ubermenu-column-auto {
    width: 100%; }
  footer.footer .upper-footer .about-coffee-1652 {
    margin-bottom: 2.5rem; }
  .featured-brand > a span:after {
    display: none; }
  .ubermenu .ubermenu-search .ubermenu-searchform {
    margin: 0;
    width: 100%; }
  .ubermenu-responsive .ubermenu-nav .ubermenu-item .ubermenu-submenu.ubermenu-submenu-drop {
    background-color: white; }
    .ubermenu-responsive .ubermenu-nav .ubermenu-item .ubermenu-submenu.ubermenu-submenu-drop li a {
      font-size: 14px; }
  .ubermenu .ubermenu-autocolumn, .ubermenu .ubermenu-submenu-type-stack {
    margin-top: 0;
    margin-bottom: 0; }
  .ubermenu .ubermenu-item-type-column > .ubermenu-submenu-type-stack > .ubermenu-item-normal:first-child {
    margin-top: 0; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  footer.footer .upper-footer .about-coffee-1652 {
    margin-bottom: 2.5rem; } }
