@include breakpoint(medium only) {
	.ubermenu.ubermenu-responsive {
		.ubermenu-column, .ubermenu-column-auto {
			width: 100%;
		}
	}

	footer.footer {
		.upper-footer {
			.about-coffee-1652 {
				margin-bottom: 2.5rem;
			}
		}
	}

	.featured-brand>a span:after {
		display: none;
	}

	.ubermenu {
		.ubermenu-search {
			.ubermenu-searchform {
				margin: 0;
				width: 100%;
			}
		}
	}

	.ubermenu-responsive {
		.ubermenu-nav {
			.ubermenu-item {
				.ubermenu-submenu.ubermenu-submenu-drop {
					background-color: white;

					li {
						a {
							font-size: 14px;
						}
					}
				}
			}
		}
	}

	.ubermenu .ubermenu-autocolumn, .ubermenu .ubermenu-submenu-type-stack {
		margin-top: 0;
		margin-bottom: 0;
	}

	.ubermenu .ubermenu-item-type-column>.ubermenu-submenu-type-stack>.ubermenu-item-normal:first-child {
		margin-top: 0;
	}
}