.tinynav { 
	display: none;
}

@media screen and (max-width: 600px) {
    .tinynav {
    	display: block;
    }
    
    #nav {
    	display: none;
    }
}

.breadcrumbs {
	font-weight: 300;

	> span {
		a {
			color: $black;
		}

		&:last-of-type {
			font-weight: 600;
		}
	}
}

.bg-banner {
	.breadcrumbs {
		color: white;

		> span {
			a {
				color: white;
			}
		}
	}
}

.usps {
	background-color: $brown;
	color: white;
	margin-bottom: 1rem;

	.columns {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;

		i {
			margin-right: 0.25rem;
		}

		span {
			font-weight: 300;

			b {
				font-weight: 500;
			}
		}
	}
}

.products-section {
	padding-top: 3rem;
	padding-bottom: 4rem;
}

.reveal {
	border: none;
	padding: 0;

	.close-button {
		span {
			color: white;
		}
	}

	.reveal-header {
		padding: 1rem 2rem;	
		background-color: $brown;
		
		h5 {
			color: white;
			margin-bottom: 0;
		}
	}

	.reveal-content {
		padding: 1rem 2rem;

		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: $dark-gray;
		}
		::-moz-placeholder { /* Firefox 19+ */
		  color: $dark-gray;
		}
		:-ms-input-placeholder { /* IE 10+ */
		  color: $dark-gray;
		}
		:-moz-placeholder { /* Firefox 18- */
		  color: $dark-gray;
		}

		input, textarea {
			box-shadow: none;
			border: 1px solid lighten($brown, 5);
			color: $dark-gray;

			&:focus {
				outline: none;
				box-shadow: none;
				border: 1px solid $brown;
			}
		}

		.button {
			margin-bottom: 0;
		}
	}

	.reveal-footer {
		padding: 1rem 2rem;
		border-top: 1px solid $light-gray;

		.button-group {
			margin-bottom: 0;
		}
	}
}

.cta-section {
}

.background-content {
	padding-top: 6rem;
	padding-bottom: 6rem;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	p {
		color: white;
		margin-bottom: 1.5rem;
		line-height: 1.75;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}