.logo-slider {
	padding: 6rem 0 5rem;

	.slides {
		.slick-slide {
			img {
				margin: 0 auto;
			}
		}
	}
}

.bg-cta {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(../images/cta-bg.jpg);

	.overlay {
	    background-color: rgba($brown, 0.7);
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    z-index: 2;
	}

	h3, h4 {
		color: $white;
	}
}

.cta-section {
	position: relative;
	background-color: $light-gray;
	padding-top: 5rem;
	padding-bottom: 4rem;

	.cta-content {
		z-index: 3;
		position: relative;
		
		.button {
			margin-top: 1.5rem;
			border-width: 2px;
			text-transform: uppercase;
			font-weight: 700;
			padding: 1.25rem 1.75rem;
		}
	}
}

.product-category-blocks {
	> .row {
		> .columns {
			padding-left: 0.5rem;
			padding-right: 0.5rem;

			&:first-of-type {
				padding-left: 0;
			}

			&:last-of-type {
				padding-right: 0;
			}
		}
	}

	.row {
		&.square {
			margin-bottom: 1rem;

			.block {
				padding-top: 75%;

				.block-content-container {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
				}
			}
		}

		&.rectangle {
			height: calc(100% - 1rem);
			margin-bottom: 1rem;

			.block {
				height: 100%;
			}
		}
	}

	.block {
		position: relative;

		.block-content-container {
			position: relative;
			height: 100%;
			
			a {
				color: white;

				&:hover {
					.block-content {
						&:before {
							opacity: 1;
						}
					}
				}
			}

			.block-content {
				background-color: $light-gray;
				height: 100%;
				position: relative;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;

				&:before {
					opacity: 0;
					content: "";
					height: 100%;
					width: 100%;
					background-color: rgba(0,0,0,0.5);
					top: 0;
					right: 0;
					position: absolute;
					-webkit-transition: opacity 0.25s ease-in-out;
			        -moz-transition: opacity 0.25s ease-in-out;
			        -o-transition: opacity 0.25s ease-in-out;
			        transition: opacity 0.25s ease-in-out;
				}

				.block-footer {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					padding: 3rem;
					max-width: 50%;

					a {
						color: white;
					}
				}
			}
		}
	}

	.square-blocks-container {
		padding-left: 0 !important;
		padding-right: 0 !important;

		.square-blocks {
			> .columns {
				padding-left: 0.5rem;
				padding-right: 0rem;

				// &:first-of-type {
				// 	padding-left: 0;
				// }

				// &:last-of-type {
				// 	padding-right: 0;
				// }
			}

			.square {
				&:last-of-type {
					// margin-bottom: 0;
				}
			}
		}
	}
}

#homepage-slider{
	margin-top: -70px;
	z-index: 0;
	position: relative;
	height: 100vh;

	.slick-list {
		min-height: 100vh;
		height: 100%;
		.slick-track {
			min-height: 100vh;
			height: 100%;
			.slick-slide {
				min-height: 100vh;
				height: 100%;

				> div {
					min-height: 100vh;
					height: 100%;
					
					.slick-slide-content-container {
						min-height: 100vh;
						height: 100%;
					    display: table !important;
					    background-repeat: no-repeat;
					    background-size: cover;
					    background-position: center center;
						
						.slick-slide-content {
							display: table-cell;
							vertical-align: middle;

							h1, h3 {
								color: white;
							}

							h1 {
								font-weight: 700;
							}

							h3 {
								max-width: 420px;
							}

							.button {
								text-transform: uppercase;
								font-weight: bold;
							}
						}
					}
				}
			}
		}
	}
}