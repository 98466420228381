.woocommerce-breadcrumb {
	span {
		font-weight: 600;
	}

	a {
		color: $black;
		font-weight: 300;
	}
}

.products-list {
	margin-bottom: 2rem;

	.product {
		position: relative;
		margin-bottom: 2rem;

		h2 {
		    font-size: 1.25rem;
		    line-height: 1.4;
		    margin-top: 0;
		    margin-bottom: .5rem;
		}

		.woocommerce-loop-product__link {
			width: 100%;
			display: block;
			background-color: $light-gray;
			border: none;
			padding: 2rem;
		}
	}
}

.product.type-product {
	// padding: 5rem 0;
}

.onsale {
	position: absolute;
	top: 1em;
	left: 2em;
	color: white;
	background-color: $black;
	border-radius: 50px;
	height: 50px;
	width: 50px;
	line-height: 50px;
	text-align: center;
}

.summary {
	position: relative;

	h1 {
		font-size: 24px;
		font-weight: 500;
	}

	.price {
		font-weight: 500;
		font-size: 18px;

		del {
			font-weight: 300;
		}
	}

	.woocommerce-product-details__short-description {
		margin-bottom: 2rem;

		p {
			font-size: 14px;
			width: 85%;
			min-width: 300px;
		}
	}

	.onsale {
		position: relative;
		display: inline-block;
		margin-bottom: 1.5rem;
		left: 0;
	}
}

.screen-reader-text {
	display: none;
}

.woocommerce, .woocommerce-page {
	.quantity {
		border-width: 1px;
		border-style: solid;
		border-color: $medium-gray;
		padding-right: 0;
		float: left;
		margin-right: 1rem;

		input[type=number]::-webkit-outer-spin-button,
		input[type=number]::-webkit-inner-spin-button {
		    -webkit-appearance: none;
		    margin: 0;
		}

		input[type=number] {
		    -moz-appearance:textfield;
		}

		.minus {
			float: left;
		}

		.plus {
			float: right;
		}

		input.qty {
			width: 3.5rem;
			box-shadow: none;
			font-weight: normal;
		}

		.minus, .plus {
			font-weight: 300;

			&:hover {
				color: white;
				background-color: black;
			}
		}

		input.qty, .minus, .plus {
			position: relative;
			display: inline-block;
			height: 3rem;
			background-color: white;
			border-radius: 0px !important;
			outline: none !important;
			border: 0px !important;
		}
	}
}

form.cart {
	display: block;
	margin-bottom: 1rem;

	.single_add_to_cart_button {
		margin-bottom: 0;
		padding: 1.05rem 1.5rem;
		text-transform: uppercase;
		font-weight: 600;
	}
}

.product_meta {
	display: none;
}

.wc-tabs {
	li {
		a {
			font-size: 14px;
			text-transform: capitalize;
		}

		&.is-active {
			a {
				font-weight: 500;
			}
		}
	}
}

.tabs-panel {
	p {
		font-size: 14px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	table {

		tbody {
			tr {
				th, td {
					font-size: 14px;
				}
			}
		}
	}

	ul {
		li {
			font-size: 14px;
			margin-bottom: 0.5rem;
		}
	}
}

.shop_attributes {
	margin-bottom: 0;
	text-align: left;
}

.product-image-container {
	display: table;
	width: 100%;

	.product-image {
		display: table-cell;
		vertical-align: middle;

		img {
			display: inline-block;
		}
	}
}

.woocommerce-variation-price {
	margin-bottom: 1rem;
}

.delivery-returns {
	padding-top: 1em;
	padding-bottom: 1em;
}

.variations {
	tbody {
		border: none;

		tr {
			td {
				&.value {
					select {
						display: inline-block;
						float: left;
						width: auto;
						margin-bottom: 0;
					}

					.reset_variations {
						margin-bottom: 0;
						height: 2.4375rem;
						margin-left: 0.5rem;
						padding: 0.8rem 1.5rem;
					}
				}
			}
		}
	}
}

.yith_magnifier_loading {
	display: none !important;
}

.woocommerce-cart-form__cart-item {
	.product-remove {
		width: 62px;

		.button {
			margin-bottom: 0;
			border-radius: 50px;
			padding-left: 0;
			padding-right: 0;
			height: 40px;
			width: 40px;
		}
	}
}

.actions {
	.button {
		margin-bottom: 0;
	}

	.input-group {
		margin-bottom: 0;
	}
}

.woocommerce {
	.callout {
		font-weight: normal;

		a {
			color: white;
		}
	}
}

.yith_magnifier_zoom_magnifier {
	background-color: white;
	margin: 0 auto;
	width: 100% !important;
	height: 100% !important;
	right: 0;
}

.hidden {
    visibility: hidden !important;
}

.yith_magnifier_zoom img {
    margin: 0 auto;
}

.thumbnails.slider {
	height: auto !important;

	.yith_magnifier_gallery {
		li {
			text-align: center;

			a {
				margin-bottom: 10px;
			}
		}
	}
}

.results-sorting {
	margin-bottom: 1rem;

	.woocommerce-result-count {
		padding: 0.5rem;
		float: left;
		display: inline-block;
		color: $dark-gray;
	}

	.woocommerce-ordering {
		float: right;
		display: inline-block;

		.orderby {
			border: none;
			box-shadow: none;
			color: $dark-gray;
			text-align: right;
		}
	}
}

.woocommerce-message {
	&.light-gray {
		background-color: $light-gray;
	}
}

* {
	outline: none !important;
}

.woocommerce-pagination {
	text-align: center;

	.page-numbers {
		margin: 0 auto 1rem;
	}
}

body #add_payment_method #payment ul.payment_methods li input,
body.woocommerce-cart #payment ul.payment_methods li input,
body.woocommerce-checkout #payment ul.payment_methods li input { width: auto; margin: -2px .5em 0 0; }
body .woocommerce form .form-row .input-checkbox { width: auto; margin: -2px 5px 0 0; }

body.woocommerce-checkout .cart-collaterals .cart_totals tr td,
body.woocommerce-checkout .cart-collaterals .cart_totals tr th,
body .woocommerce table.shop_table th {
border-top: none;
// border-bottom: 1px solid #e6e6e6;
// border-right: 1px solid #e6e6e6;
text-align: left;
padding: 10px 20px;
}
body .woocommerce table.shop_table td { padding: 15px; }
body .woocommerce-checkout table.shop_table td { width:50%;
text-align: left;
// border-right: 1px solid #e6e6e6;
border-top: 0;
}

body .woocommerce #payment #place_order, .woocommerce-page #payment #place_order {
width: 100%;
display: block;
text-align: center;
margin-bottom: 0;
font-size: 1.25em;
padding: 1em;
margin-top: .5em;
}

body .woocommerce form .form-row-first,
body .woocommerce form .form-row-last,
body .woocommerce-page form .form-row-first,
body .woocommerce-page form .form-row-last { width: 49%; }

.woocommerce-checkout .woocommerce .col2-set { width: 47%; float: left; margin-right: 4%; }
.woocommerce-checkout .woocommerce .col2-set .col-1,
.woocommerce-checkout .woocommerce .col2-set .col-2 { margin-bottom: 2em; width: 100%; float: none; }
.woocommerce-checkout #order_review_heading,
.woocommerce-checkout #order_review { background: #fff; width: 47%; float: right; margin-right: 0; }

@media only screen and (max-width: 650px) {
.woocommerce-checkout .woocommerce .col2-set { width: 100%; float: none; margin-right: 0; }
.woocommerce-checkout #order_review_heading,
.woocommerce-checkout #order_review { width: 100%; float: none; }
}

#billing_first_name_field, #shipping_first_name_field {
	float: left;
	margin-bottom: 0;
}

#billing_last_name_field, #shipping_last_name_field {
	float: right;
	margin-bottom: 0;
}

.woocommerce-billing-fields__field-wrapper, .woocommerce-shipping-fields__field-wrapper, .woocommerce-additional-fields__field-wrapper {
	padding: 1rem;
	background-color: #f8f8f8;
	margin-bottom: 1.5rem;
}

.woocommerce-shipping-fields {
	display: inline-block;
	width: 100%;
}

.woocommerce-cart,
.woocommerce-checkout,
#add_payment_method {
	table.cart {
		.product-thumbnail {
			min-width: 32px;
		}

		img {
			width: 32px;
			box-shadow: none;
		}

		th,
		td {
			vertical-align: middle;
		}

		td.actions .coupon .input-text {
			float: left;
			box-sizing: border-box;
			border: 1px solid darken( #efefef, 10% );
			padding: 6px 6px 5px;
			margin: 0 4px 0 0;
			outline: 0;
		}

		input {
			margin: 0;
			vertical-align: middle;
		}
	}

	.wc-proceed-to-checkout {
		@include clearfix;
		padding: 1em 0;

		a.checkout-button {
			display: block;
			text-align: center;
			margin-bottom: 1em;
			font-size: 1.25em;
			padding: 1em;
		}
	}

	.cart-collaterals {
		.shipping-calculator-button {
			float: none;
			margin-top: .5em;
			display: inline-block;
		}

		.shipping-calculator-form {
			margin: 1em 0 0 0;
		}

		.cart_totals {
			p small {
				font-size: 0.83em;
			}

			table {
				border-collapse: separate;
				margin: 0 0 6px;
				padding: 0;

				tr:first-child {
					th,
					td {
						border-top: 0;
					}
				}

				th {
					width: 35%;
				}

				td,
				th {
					vertical-align: top;
					border-left: 0;
					border-right: 0;
					line-height: 1.5em;
				}

				small {
				}

				select {
					width: 100%;
				}
			}

			.discount td {
			}

			tr td,
			tr th {
				border-top: 1px solid #efefef;
			}
			.woocommerce-shipping-destination {
				margin-bottom: 0;
			}
		}

		.cross-sells ul.products li.product {
			margin-top: 0;
		}
	}
	.checkout {
		.col-2 {
			h3#ship-to-different-address {
				float: left;
				clear: none;
			}

			.notes {
				clear: left;
			}

			.form-row-first {
				clear: left;
			}
		}

		.create-account small {
			font-size: 11px;
			font-weight: normal;
		}

		div.shipping-address {
			padding: 0;
			clear: left;
			width: 100%;
		}

		.shipping_address {
			clear: both;
		}
	}

	#payment {
		background: #efefef;
		border-radius: 5px;

		ul.payment_methods {
			@include clearfix();
			text-align: left;
			padding: 1em;
			border-bottom: 1px solid darken( #efefef, 10% );
			margin: 0;
			list-style: none outside;

			li {
				line-height: 2;
				text-align: left;
				margin: 0;
				font-weight: normal;

				input {
					margin: 0 1em 0 0;
				}

				img {
					vertical-align: middle;
					margin: -2px 0 0 0.5em;
					padding: 0;
					position: relative;
					box-shadow: none;
				}

				img + img {
					margin-left: 2px;
				}
			}

			li:not(.woocommerce-notice) {
				@include clearfix;
			}
		}

		div.form-row {
			padding: 1em;
		}

		div.payment_box {
			position: relative;
			box-sizing: border-box;
			width: 100%;
			padding: 1em;
			margin: 1em 0;
			font-size: 0.92em;
			border-radius: 2px;
			line-height: 1.5;
			background-color: darken( #efefef, 5% );

			input.input-text, textarea {
				border-color: darken( #efefef, 15% );
				border-top-color: darken( #efefef, 20% );
			}

			::-webkit-input-placeholder {
				color: darken( #efefef, 20% );
			}

			:-moz-placeholder {
				color: darken( #efefef, 20% );
			}

			:-ms-input-placeholder {
				color: darken( #efefef, 20% );
			}

			.woocommerce-SavedPaymentMethods {
				list-style: none outside;
				margin: 0;
				.woocommerce-SavedPaymentMethods-token,
				.woocommerce-SavedPaymentMethods-new {
					margin: 0 0 0.5em;
					label {
						cursor: pointer;
					}
				}
				.woocommerce-SavedPaymentMethods-tokenInput {
					vertical-align: middle;
					margin: -3px 1em 0 0;
					position: relative;
				}
			}

			.wc-credit-card-form {
				border: 0;
				padding: 0;
				margin: 1em 0 0;
			}
			.wc-credit-card-form-card-number,
			.wc-credit-card-form-card-expiry,
			.wc-credit-card-form-card-cvc {
				font-size: 1.5em;
				padding: 8px;
				background-repeat: no-repeat;
				background-position: right 0.618em center;
				background-size: 32px 20px;

				&.visa {
					background-image: url('../images/icons/credit-cards/visa.svg');
				}

				&.mastercard {
					background-image: url('../images/icons/credit-cards/mastercard.svg');
				}

				&.laser {
					background-image: url('../images/icons/credit-cards/laser.svg');
				}

				&.dinersclub {
					background-image: url('../images/icons/credit-cards/diners.svg');
				}

				&.maestro {
					background-image: url('../images/icons/credit-cards/maestro.svg');
				}

				&.jcb {
					background-image: url('../images/icons/credit-cards/jcb.svg');
				}

				&.amex {
					background-image: url('../images/icons/credit-cards/amex.svg');
				}
				&.discover {
					background-image: url('../images/icons/credit-cards/discover.svg');
				}
			}

			span.help {
				font-size: 0.857em;
				font-weight: normal;
			}

			.form-row {
				margin: 0 0 1em;
			}

			p:last-child {
				margin-bottom: 0;
			}

			&::before {
				content: '';
				display: block;
				border: 1em solid darken( #efefef, 5% ); /* arrow size / color */
				border-right-color: transparent;
				border-left-color: transparent;
				border-top-color: transparent;
				position: absolute;
				top: -0.75em;
				left: 0;
				margin: -1em 0 0 2em;
			}
		}

		.payment_method_paypal {
			.about_paypal {
				float: right;
				line-height: 52px;
				font-size: 0.83em;
			}

			img {
				max-height: 52px;
				vertical-align: middle;
			}
		}
	}
}

/****************CHECKOUT***************/
.woocommerce form .form-row {
    width: 100% !important;
}
.woocommerce-checkout #payment div.payment_box input.input-text, .woocommerce-checkout #payment div.payment_box textarea {
    width: 100% !important;
    padding: 8px;
}
.woocommerce #payment .form-row select, .woocommerce-page #payment .form-row select {
    width: 100%;
    height: 30px;
}
.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1,.woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2 {
    float: left;
    width: 100%;
}
.custom-checkout h3 {
    background-color: #165f1c;  /****CHANGE TO COLOR YOU WANT TO USE FOR TITLE BACKGROUND ****/
    width: 45%;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    margin-top: 50px;
    color: #FFF;
    float: right;
}
.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
    padding: .6180469716em;
    background-color: #f2f2f2;
    color: #43454b;
    outline: 0;
    border: 0;
    -webkit-appearance: none;
    border-radius: 2px;
    box-sizing: border-box;
    font-weight: 400;
   border:solid 2px #e4e4e4;
}

#wc_checkout_add_ons {
    width: 45%;
    float: right;
    text-align: center;
}

@media screen and (min-width: 980px) {
.woocommerce-shipping-fields h3, .woocommerce-billing-fields h3 {width:100%;}
.woocommerce .col2-set, .woocommerce-page .col2-set {
 width: 45%;
 float: left;
}
.woocommerce-checkout-review-order{
 width: 45%;
 float: right;
}
}
@media screen and (max-width: 979px) {
.custom-checkout h3 {
 width: 100%;
}
}

// .article-header.woocommerce-products-header {
// 	background-size: cover;
// 	background-repeat: no-repeat;
// 	background-position: center center;
// 	background-image: url(../images/shop-banner.png);
// }

.woocommerce-page {
	.type-page {
		> .article-header {
			// background-size: cover;
			// background-repeat: no-repeat;
			// background-position: center center;
			// background-image: url(../images/shop-banner.png) !important;

			h1, a, .breadcrumbs {
				color: black !important;
			}
		}
	}
}

#shipping_method {
	list-style: none;
	margin-left: 0;
}